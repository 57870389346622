import React,{ useContext } from "react";
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from "react-device-detect";

import Slider from "react-slick";
import Fab from '@material-ui/core/Fab';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Button from '@material-ui/core/Button';
import LangLink from "components/lang/LangLink";

import { CmsContext } from "context/cms/CmsContext";

import "slick-carousel/slick/slick.scss"; 
import "slick-carousel/slick/slick-theme.scss";
import DOMPurify from "isomorphic-dompurify";

const SlickHeroHome = (props) => {

  const { t } = useTranslation();
  const { cms } = CmsContext ? useContext(CmsContext) : null;
  const isMobile = isMobileOnly;

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <Fab
      size="medium"
      color="secondary"
      {...props}
      className={
        "slick-prev slick-arrow" + (currentSlide === 0 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
      <span className="svg-wr">
        <ChevronLeftIcon />
      </span>
    </Fab>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <Fab
      size="medium"
      color="secondary"
      {...props}
      className={
        "slick-next slick-arrow" +
        (currentSlide === slideCount - 1 ? " slick-disabled" : "")
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
      <span className="svg-wr">
        <ChevronRightIcon />
      </span>
    </Fab>
  );

  const settings = {
    autoplay: true,
    dots: true,
    adaptiveHeight: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 3000,
    pauseOnHover:false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    initialSlide: cms?.direction === 'rtl' ? props.data.rows.length-1 : 0,
    rtl: cms?.direction === 'rtl' ? true : false,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
        }
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          pauseOnHover: true,
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
        }
      }
    ],
    appendDots: dots => (
      <div className="dots-wr">
        <div className="container">
          <div className="row">
            <div className="col-12">
            <ul className="dots-list"> {dots} </ul>
            </div>
          </div>
        </div>
      </div>
    ),
    customPaging: i => (
      <div className="dot" text={'0'+(i + 1)}>
        <span className="text">0{i + 1}</span>
      </div>
    )
  };
  return (
    <div className="slick-slider-wr slick-hero-home">
      <Slider {...settings}>
        { props.data.rows.map((item, pos) => {
          return (
            <div key={pos} className="slick-inner-wr">
              {/* <div className="bg" style={{ backgroundImage:'url('+ `${(isMobile) ? item?.field_mobile_image['0']?.url : item?.field_image['0']?.url }`+')'}}></div> */}
              {/* {  !isMobileOnly &&  */}
                {/* <div className="container">
                  <div className="row">
                    <div className="col-12 col-sm-5 col-text">                    
                      {(item?.field_sub_title?.length > 0) ? (
                        <h1 className="title" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item?.field_sub_title[0]?.value) }}></h1>
                        ) : ''}
                      <div className="row">
                        <div className="col-8 col-sm-10">
                        {(item?.body?.length > 0) ?  (
                        <p className="desc" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item?.body[0]?.value) }}></p>
                        )
                        : ''} 
                        {(item?.field_url?.length > 0) ?  (
                        <a href={ item?.field_url[0]?.value } className="slider-btn">
                          <Button variant="contained" color="primary" size="medium" >
                          {t("Find More")}
                        </Button>
                        </a>
                        )
                        : ''} 
                        
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              {/* } */}
              <div className="fl-banner-image-block">
              <a href={ item?.field_url[0]?.value }><img src={(isMobile) ? item?.field_mobile_image['0']?.url : item?.field_image['0']?.url } alt="Banner" /></a>
              {(item?.field_url?.length > 0) &&
              <a href={ item?.field_url[0]?.value } className="banner-btn">{t("Learn More")}</a>
              } 
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
}

export default SlickHeroHome;