const config = {
  api: {

      url: 'https://app.bidaya.com.sa/',

domain: 'https://www.bidaya.com.sa'

  },

  appReq: {

      authorize: '/request/authorize/redirect',

      authorize_coapplicant: '/request/authorize/coredirect'

  },

  ga: 'GTM-PSWLRRH3',
  fingerprintAPI: 'mnm1C9CBtbm7q8Ld3nCD',
  site: {

    url: 'https://www.bidaya.com.sa/',

    careersUrl: 'http://careers.bidaya.com.sa/'

  }

};



export default config;

