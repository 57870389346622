import React from "react";
import { useTranslation } from "react-i18next";
import LogoWhite from "assets/images/LogoWhite";
import LangLink from "components/lang/LangLink";
import Box from '@material-ui/core/Box';

require("styles/common/navbar.scss");
require("styles/components/404/index.scss");

const NotFound = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <nav className="Navbar">
        <div className="container">
          <LangLink to="/" className="brand-logo">
            <LogoWhite />
          </LangLink>
        </div>
      </nav>
      <article className="not-found-wr">
        <div className="container ">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-7">
              <h2 className="mb-2">
                { t('404_MESSAGE') }
              </h2>
              <h5 className="mb-3">  
                { t('404_MESSAGE_2') }
              </h5>
            </div>
            
              <div className="col-12 col-sm-7 col-link">
                <ul className="list-unstyled">
                  <li >
                    <LangLink to='/'>{t("HOME_FINANCING")}</LangLink>
                  </li>
                  <li >
                    <LangLink to='/contact-us/contact-us'>{t("contact_us")}</LangLink>
                  </li>
                  <li >
                    <LangLink to='/contact-us/faq'>{t("faq")}</LangLink>
                  </li>
                </ul>
              </div>
            </div>
        </div>
      </article>
    </>
  );
}

export default NotFound;
