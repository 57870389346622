import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useTranslation } from 'react-i18next'

const LangLink = React.forwardRef((props,ref) => {
    const { i18n } = useTranslation();
    const [ path, setPath ] = useState(props.to);

    const { children } = props;

    useEffect(() => {
        const to = props.to.replace('cms/web/', '');
        const p = '/'+ i18n.language + to;
        setPath(p);
    }, [i18n.language, path]);
    
    return (
        <Link {...props} ref={ref} to={path}>{children}</Link>
    );
})

export default LangLink;