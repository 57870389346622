
const feRoutes =  [
  {
    path: '/login',
    match: 'startsWith'
  },
  {
    path: '/dashboard',
    match: 'startsWith'
  },
  {
    path: '/takaful',
    match: 'startsWith'
  },
  {
    path: '/redf',
    match: 'startsWith'
  },
  {
    path: '/application',
    match: 'startsWith'
  },
  {
    path: '/campaign',
    match: 'startsWith'
  },
  {
    path: '/contractreview',
    match: 'startsWith'
  },
  {
    path: '/404',
    match: 'exact'
  },
  {
    path: '/under-maintenance',
    match: 'exact'
  },
  {
    path: '/reset-password',
    match: 'startsWith'
  },
  {
    path: '/change-password',
    match: 'startsWith'
  },
  {
    path:"/appraisal",
    match: "startsWith"
  },
  {
    path: '/html-awards',
    match: 'exact'
  }
]

export default feRoutes