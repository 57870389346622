import React, { createContext, useEffect, useState } from "react";

import { accountApi, getUser, login, loginVerify, logout, loginOTP, logoutCampaign, logoutRedf, logoutTakaful, logoutAppraisal } from "services/auth";
import storage from "services/storage";
import { useTranslation } from "react-i18next";
import { setApplicationList, setFinanceSummary, setLoading, userLogout } from "./AuthenticationReducer";
import Loader from "dashboard/components/loader";

export const AuthenticationContext = createContext();
const AuthenticationContextProvider = ({ children, initialState, reducer }) => {
  const [accountState, dispatch] = React.useReducer(reducer, initialState);
  const [user, setUser] = useState(null);
  const [access, setAccess] = useState(null);
  const [uuid, setUUID] = useState();
  const [fetched, setFetched] = useState(false);
  const { t } = useTranslation();

  const userlogin = (email, nationalID, password, loginType, otpfield, passwordType) => {
    if (passwordType === "password") {
      const loginMethod = loginType === 'nationalId' ? loginVerify(nationalID, password, loginType) : loginVerify(email, password, loginType);
      return loginMethod
        .then((data) => {
          //setUser(data?.data?.messages);
        });
    }
  };

  const otplogin = (username, otp, id_type, pwd) => {
    const loginOTPMethod = loginOTP(username, otp, id_type, pwd);
    return loginOTPMethod
      .then((data) => {
        console.debug("user login response", data);
        storage.set('user_id', data?.data?.messages?.user_id);
        storage.set('user_role', data?.data?.messages?.role);
        storage.set('user_login_type', '');
        setUser(data?.data?.messages);
      });
  };

  const normallogin = (email, nationalID, password, loginType, otpfield, passwordType) => {
    if (passwordType === "password") {
      const loginMethod = loginType === 'nationalId' ? loginNationalID(nationalID, password) : login(email, password);
      return loginMethod
        .then((data) => {
          console.debug("user login response", data);
          storage.set('user_id', data?.data?.messages?.user_id);
          storage.set('user_role', data?.data?.messages?.role);
          storage.set('user_login_type', '');
          setUser(data?.data?.messages);
          //setUser(data?.data?.messages);
        });
    }
    else {
      const OTPMethod = loginType === 'nationalId' ? loginOTP(nationalID, otpfield, loginType) : loginOTP(email, otpfield, loginType);
      return OTPMethod
        .then((data) => {
          console.debug("user login response", data);
          storage.set('user_id', data?.data?.messages?.user_id);
          storage.set('user_role', data?.data?.messages?.role);
          storage.set('user_login_type', 'otp');
          setUser(data?.data?.messages);
        });
    }
  };

  const userlogout = async () => {
    setUser(null);

    storage.remove('user_id');
    storage.remove('user_role');
    storage.remove('user_login_type');
    dispatch(userLogout());
    await logout();
  };

  const takafulLogout = async () => {
    setUser(null);
    storage.remove('user_id');
    storage.remove('user_role');
    storage.remove('user_login_type');
    dispatch(userLogout());
    await logoutTakaful();
  }

  const appraisalLogout = async () => {
    setUser(null);
    storage.remove('user_id');
    storage.remove('user_role');
    storage.remove('user_login_type');
    dispatch(userLogout());
    await logoutAppraisal();
  }

  const redfLogout = async () => {
    setUser(null);
    storage.remove('user_id');
    storage.remove('user_role');
    storage.remove('user_login_type');
    dispatch(userLogout());
    await logoutRedf();
  }

  const campaignLogout = async () => {
    setUser(null);
    storage.remove('user_id');
    storage.remove('user_role');
    storage.remove('user_login_type');
    dispatch(userLogout());
    await logoutCampaign();
  }

  const getUserDet = async () => {
    const user = await getUser();
    setUser(user ? user?.data?.messages : null);
  };

  useEffect(() => {
    getUserDet();
  }, []);

  const finanseData = (Fdata) => {
    console.debug("at finance data");
    dispatch(setLoading(true));
    accountApi(Fdata)
      .then((data) => {
        if (data?.data?.result) {
          dispatch(setLoading(false));
          dispatch(setFinanceSummary(data?.data?.result));
        }
        console.info("finance-summary", data.data);
      })
      .catch((error) => {
        dispatch(setLoading(false));
        console.log("finance error", error);
      });
  }

  const applicationData = (Adata) => {
    console.debug("at application data");
    dispatch(setLoading(true));
    accountApi(Adata)
      .then((data) => {
        if (data?.data?.result) {
          dispatch(setLoading(false));
          dispatch(setApplicationList(data?.data?.result));
        }
        console.info("application-list", data.data);
      })
      .catch((error) => {
        dispatch(setLoading(false));
        console.log("appli error", error);
      });
  }

  useEffect(() => {
    let Adata = {
      __misc: {
        step: "application_list",
      },
    };
    let Fdata = {
      __misc: {
        step: "finance_summary",
      },
    };
    if (user !== null) {
      if (Object.keys(accountState?.application_list)?.length === 0) {
        applicationData(Adata);
      }
    }
  }, [user, accountState?.application_list]);

  return (
    <AuthenticationContext.Provider
      value={{
        user,
        access,
        setAccess,
        uuid,
        setUUID,
        userlogin,
        normallogin,
        otplogin,
        userlogout,
        getUserDet,
        setUser,
        accountState,
        dispatch,
        takafulLogout,
        appraisalLogout,
        redfLogout,
        campaignLogout,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};
export { AuthenticationContextProvider };
