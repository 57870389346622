export const APPLICATION_LIST = "APPLICATION_LIST";
export const FINANCE_SUMMARY = "FINANCE_SUMMARY";
export const LOGOUT = "LOGOUT";
export const SET_LOADING = "SET_LOADING"; 
export const TAKAFUL_APPLICATION = "TAKAFUL_APPLICATION";
export const APPRAISAL_APPLICATION = "APPRAISAL_APPLICATION";

export const initialState = {
  application_list: {},
  finance_summary: {},
  loading: false,
  takaful_application: {},
};

export const setApplicationList = (value) => ({
  type: APPLICATION_LIST,
  payload: value,
});

export const setFinanceSummary = (value) => ({
  type: FINANCE_SUMMARY,
  payload: value,
});

export const userLogout = () => ({
  type: LOGOUT,
});

export const setLoading = (value) => ({
  type: SET_LOADING,
  payload: value,
});

export const setTakafulApplication = (value) => ({
  type: TAKAFUL_APPLICATION,
  payload: value
});

export const setAppraisalApplication = (value) => ({
  type: APPRAISAL_APPLICATION,
  payload: value
});

export const authenticationReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPLICATION_LIST: {
      state = { ...state };
      state["application_list"] = action.payload;
      // updateRequestData(
      //   state["application_list"],
      //   action.payload
      // );
      return state;
    }
    case FINANCE_SUMMARY: {
      state = { ...state };
      state["finance_summary"] = action.payload;
      // updateRequestData(
      //   state["finance_summary"],
      //   action.payload
      // );
      return state;
    }
    case SET_LOADING: {
      state = {...state};
      state['loading'] = action.payload;
      return state;
    }
    case LOGOUT: {
      state = { ...state };
      state = initialState;
      return state;
    }
    case TAKAFUL_APPLICATION: {
      state = {...state};
      state['takaful_application'] = action.payload
      return state;
    }
    case APPRAISAL_APPLICATION: {
      state = {...state};
      state['appraisal_application'] = action.payload
      return state;
    }
    default:
      return state;
  }
};

/**
 * Update Request Data property
 */
export const updateRequestDataProp = (requestData, parent, key, val) => {
  if (requestData?.hasOwnProperty(parent)) {
    if (key !== null) {
      requestData[parent][key] = val;
    } else {
      requestData[parent] = val;
    }
  } else {
    requestData[parent] = Math.floor(key) === key ? [] : {};
    if (key !== null) {
      requestData[parent][key] = val;
    } else {
      requestData[parent] = val;
    }
  }
  return requestData;
};

/**
 * Update Request Data
 */
export const updateRequestData = (requestData, obj) => {
  for (const pkey in obj) {
    if (typeof obj[pkey] === "object") {
      for (const key of Object.keys(obj[pkey])) {
        requestData = updateRequestDataProp(
          requestData,
          pkey,
          key,
          obj[pkey][key]
        );
      }
    } else {
      requestData = updateRequestDataProp(requestData, pkey, null, obj[pkey]);
    }
  }
  // this.quote = Object.assign(this.quote, obj);
  return requestData;
};
