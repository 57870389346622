import { setLocalStorage } from "application/utility";

import analytics from "services/analytics";

export const STAGE_CHANGE = "STAGE_CHANGE";
export const RESET_STAGE = "RESET_STAGE";
export const MASTER_DATA = "MASTER_DATA";
export const SET_FROM_LOCAL = "SET_FROM_LOCAL";
export const SET_LOADING = "SET_LOADING";
export const SET_REQUEST_DATA = "SET_REQUEST_DATA";
export const SET_STEPS = "SET_STEPS";
export const REVERT_STAGE_CHANGE = "REVERT_STAGE_CHANGE";
export const SET_LOADER = "SET_LOADER";
export const SET_REQUEST_DATA_WITH_STATUS = "SET_REQUEST_DATA_WITH_STATUS";

export const initialState = {
  currentStep: 1,
  maxStep: 13,
  completedStep: 1,
  isLoading: false,
  requestData: {},
  masterData: [],
  isLoader: false,
};

const steps = [
  {stage: 'initial_approval', step: 'basic_information'},
  {stage: 'initial_approval', step: 'create_account'},
  {stage: 'initial_approval', step: 'complete_your_application'},
  {stage: 'initial_approval', step: 'property_information'},
  {stage: 'initial_approval', step: 's1'},
  {stage: 'approval_in_pricipal', step: 'applicant_information'},
  {stage: 'approval_in_pricipal', step: 'employment_information'},
  {stage: 'approval_in_pricipal', step: 'monthly_earnings'},
  {stage: 'approval_in_pricipal', step: 'monthly_obligations'},
  {stage: 'approval_in_pricipal', step: 'residence_address'},
  {stage: 'approval_in_pricipal', step: 'takaful_details'},
  {stage: 'approval_in_pricipal', step: 'upload_documents'},
  {stage: 'final_approval', step: 's2'},
];

export const changeStage = (stage, request) => ({
  type: STAGE_CHANGE,
  payload: stage,
  request: request,
});
export const resetStage = () => ({
  type: RESET_STAGE,
});

export const addMasterData = (data) => ({
  type: MASTER_DATA,
  payload: data,
});

export const setFromLocal = (data) => ({
  type: SET_FROM_LOCAL,
  payload: data,
});
export const setLoading = (value) => ({
  type: SET_LOADING,
  payload: value,
});

export const setRequestData = (value,step)=> ({
  type: SET_REQUEST_DATA,
  payload: value,
  step: step,
});

export const setSteps = (value) => ({
  type: SET_STEPS,
  payload: value,
});

export const revertStageChange = (value) => ({
  type: REVERT_STAGE_CHANGE,
  payload: value,
});

export const setLoader = (value) => ({
  type: SET_LOADER,
  payload: value,
});
export const setRequestDataStatus = (stage, request) => ({
  type: SET_REQUEST_DATA_WITH_STATUS,
  payload: stage,
  request: request,
});

export const applicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case STAGE_CHANGE: {
      state = { ...state };

      // Analytics application step track
      if (state['currentStep'] != action.payload) {
        const step = steps[state['currentStep'] - 1];
        analytics.trackEvent('applicationProgress', {
          applicationStage: step.stage,
          applicationStep: step.step,
          applicationID: state?.requestData?.request?.app_id ? state?.requestData?.request?.app_id : null
        });
      }

      state["currentStep"] = action.payload;
      state["completedStep"] = action.payload - 1;
      state["requestData"] = updateRequestData(
        state["requestData"],
        action.request,
      );
      console.log("state",state);
      if(action.payload !== 13){
        setLocalStorage("appData", state);
      }

      return state;
    }
    case RESET_STAGE: {
      state = { ...state };
      state["currentStep"] = 1;
      state["completedStep"] = 1;
      state["requestData"] = {};
      return state;
    }
    case MASTER_DATA: {
      state = { ...state };
      let newState = Object.assign({}, state.masterData);
      newState[Object.keys(action.payload)[0]] = Object.values(
        action.payload,
      )[0];

      state["masterData"] = newState;
      return state;
    }
    case SET_FROM_LOCAL: {
      state = { ...state };
      state = action.payload;
      return state;
    }

    case SET_LOADING: {
      state = { ...state };
      state["isLoading"] = action.payload;
      return state;
    }
    
    case SET_REQUEST_DATA: {
      state = {...state};
      state["currentStep"] = action.step;
      state["completedStep"] = action.step - 1;
      state["requestData"] = action.payload;
      if(action.step !== 13){
        setLocalStorage("appData", state);
        }
      return state;
    }
    case SET_REQUEST_DATA_WITH_STATUS: {
      // Analytics application step track
      if (state['currentStep'] != action.payload) {
        const step = steps[state['currentStep'] - 1];
        analytics.trackEvent('applicationProgress', {
          applicationStage: step.stage,
          applicationStep: step.step,
          applicationID: state?.requestData?.request?.app_id ? state?.requestData?.request?.app_id : null
        });
      }

      state["currentStep"] = action.payload;
      state["completedStep"] = action.payload - 1;
      state["requestData"] = updateRequestData(
        state["requestData"],
        action.request,
      );
      state["isLoading"] = false;
      state["isLoader"] = false;
      if(action.payload !== 13){
        setLocalStorage("appData", state);
      }

      return state;
    }
    case SET_STEPS: {
      state = {...state};
      state['steps'] = action.payload;
      return state;
    }
    case REVERT_STAGE_CHANGE: {
      state = {...state};
      state["currentStep"] = action.payload;
      state["completedStep"] = action.payload - 1;
      state["isLoading"] = false;
      if(action.payload !== 12) {
        setLocalStorage("appData", state);
      }
      return state;
    }

    case SET_LOADER: {
      state = { ...state };
      state["isLoader"] = action.payload;
      return state;
    }

    default:
      return state;
  }
};

/**
 * Update Request Data property
 */
export const updateRequestDataProp = (requestData, parent, key, val) => {
  if (requestData?.hasOwnProperty(parent)) {
    if (key !== null) {
      requestData[parent][key] = val;
    } else {
      requestData[parent] = val;
    }
  } else {
    requestData[parent] = Math.floor(key) === key ? [] : {};
    if (key !== null) {
      requestData[parent][key] = val;
    } else {
      requestData[parent] = val;
    }
  }
  return requestData;
};

/**
 * Update Request Data
 */
export const updateRequestData = (requestData, obj) => {
  for (const pkey in obj) {
    if (typeof obj[pkey] === "object") {
      for (const key of Object.keys(obj[pkey])) {
        requestData = updateRequestDataProp(
          requestData,
          pkey,
          key,
          obj[pkey][key],
        );
      }
    } else {
      requestData = updateRequestDataProp(requestData, pkey, null, obj[pkey]);
    }
  }
  return requestData;
};

/**
 * Get Request Data property
 */
export const getRequestDataProp = (requestData, parent, key) => {

  if (requestData && requestData.hasOwnProperty(parent)) {
    if (key !== "null" && requestData[parent].hasOwnProperty(key)) {
      return requestData[parent][key];
    }
    if (key === "null") {
      return requestData[parent];
    }
  }

  return "";
};
