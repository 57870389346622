import { createContext, useState } from "react";
import useLocalStorage from "hooks/useLocalStorage";

export const CmsContext =  createContext("");

export const CmsProvider = ({ children }) => {
  const [cms, setCms] = useLocalStorage("CMS",{
    theme: "theme-light",
    direction: "rtl",
    fontSize: "medium",
    language: "ar",
    pagetitle: "",
    pagesubtitle: ""
  });
  console.log("pagetitle ="+cms.pagetitle)
  return(
    <CmsContext.Provider value={{ cms, setCms }}>
      {children}
    </CmsContext.Provider>
  )
};