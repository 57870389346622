import React, { lazy } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
//import logo from "assets/images/bidaya-logo.svg";
import LogoDark from "assets/images/LogoDark";
import LangLink from "components/lang/LangLink";
import HeaderNav from "./header";
import { AppraisalProvider } from "context/appraisal/AppraisalContext";
import { appraisalReducer, initialStateAppraisal } from "context/appraisal/AppraisalReducer";
const AppraisalProperty = lazy(() => import("appraisal/property"));
const ApplicationNeighbourhood = lazy(() => import("appraisal/neighbourhood"));
const ApplicationAmenities = lazy(() => import("appraisal/amenities"));
const AppraisalDashboard = lazy(() => import("appraisal/dashboard"));
const ApplicationApproach = lazy(() => import("appraisal/approach"));
const PropertyDoc = lazy(() => import("appraisal/PropertyDoc"));
const Return = lazy(() => import("appraisal/Return"));

require("styles/common/navbar.scss");
require("styles/components/appraisal/appraisal.scss");

const AppraisalIndex = (props) => {
  const { path, url } = useRouteMatch();

  return (
    <AppraisalProvider initialState={initialStateAppraisal} reducer={appraisalReducer}>
    <div className="appraisal-wr">
      {/* <Redirect to={`${url}/login`}/> */}
      <nav className="Navbar">
        <div className="container justify-content-center">
        <LangLink to="/" className="brand-logo">
          <LogoDark />
        </LangLink>
        </div>
        {/* <LinearIndeterminate></LinearIndeterminate> */}
      </nav>
      <HeaderNav />
      <Switch>
        <Route path={`${path}/`} exact component={AppraisalDashboard} />
        <Route path={`${path}/dashboard`} component={AppraisalDashboard} />
        <Route path={`${path}/property/:id`} component={PropertyDoc} />
        <Route path={`${path}/update/:id`} component={AppraisalProperty} />
        <Route path={`${path}/neighbourhood/:id`} component={ApplicationNeighbourhood} />
        <Route path={`${path}/amenities/:id`} component={ApplicationAmenities} />
        <Route path={`${path}/approach/:id`} component={ApplicationApproach} />
        <Route path={`${path}/return/:id`} component={Return} />
      </Switch>
    </div>
    </AppraisalProvider>
  );
};

export default AppraisalIndex;
