import React,{useState,useEffect,useContext} from 'react';
import axios from "axios";

import { Link, Route, useParams, useRouteMatch } from "react-router-dom";

import {Helmet} from "react-helmet";
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from "react-device-detect";
import { langUrl } from 'services/lang';

import Button from '@material-ui/core/Button';
import config from 'config';
import cookies from 'js-cookie'
import PageTitle from 'cms/components/common/page-title';
import PageSideMenu from 'cms/components/common/page-side-menu';
import Footer from 'cms/components/Footer';
import DOMPurify from 'isomorphic-dompurify';

import SlickAwardsSlider from 'cms/blocks/carousel/SlickAwardsSlider';

require("styles/components/common/single-page.scss");

const WhoWeAre = () => {
  const { t, i18n } = useTranslation();
  const currentLanguageCode = cookies.get('i18next') || 'en';
  const { path, url } = useRouteMatch();

  const [data, setData] = useState([]);

  useEffect(() => {
    axios .get('/assets/locales/content.json').then((res) => setData(res.data));
  }, []);

  const content = data.find(({ page_alias }) => page_alias === "who_we_are");
  console.log(content)

  if (content != undefined) {
    var meta_title_lang = "meta_title_" + currentLanguageCode;
    var title_lang = "title_" + currentLanguageCode;
    var sub_title_lang = "sub_title_" + currentLanguageCode;
    var content_lang = "content_" + currentLanguageCode;
    var meta_title = content[meta_title_lang];
    var title = content[title_lang];
    var sub_title = content[sub_title_lang];
    var page_content = content[content_lang];
    var conflict_interest = content["conflict_interest_" + currentLanguageCode];
  }
  const isMobile = isMobileOnly;


  return (
    <>
     <Helmet>
        <meta charSet="utf-8" />
        <title>{ meta_title }</title>
        <link rel="canonical" href={config.site.url} />
    </Helmet>
      <div className="single-page">

        <PageTitle title={t("who_we_are")} subtitle={t("about_bidaya")}/>

        <div className="container">
          <div className="row">

            <div className="col-12 col-sm-3 col-page-side-menu">
              <PageSideMenu current={t("who_we_are")}/>
            </div>

            <div className="col-12 col-sm-9 col-content">
              <SlickAwardsSlider/>
            </div>
          
          </div>
        </div>
      </div>
     <Footer/>
    </>
  );
}

export default WhoWeAre;
