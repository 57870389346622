import React, { lazy, useContext, useEffect, useState } from 'react';
import { Route, Redirect, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { isMobileOnly } from "react-device-detect";
import cookies from 'js-cookie'

import analytics from "services/analytics";
import storage from "services/storage";

import "styles/loader.scss";
import { DialogBoxServiceProvider } from 'context/DialogBoxService';
import { CmsContext } from 'context/cms/CmsContext';
import { PrivateRoute } from 'routing/PrivateRoute';

import { AuthenticationContextProvider } from 'context/Authentication';
import { authenticationReducer, initialState } from 'context/AuthenticationReducer';

import DashboardDialogProvider from 'context/DashboardDialogProvider';
import { TakafulRoute } from 'routing/TakafulRoute';
import TakafulLogin from 'takaful/login';
import RedfLogin from 'redf/login';
import { RedfRoute } from 'routing/RedfRoute';
import loadable from "@loadable/component";
import { Main, checkFeRoute, getLayout, getPathWoLang } from 'cms/main';
import NotFound from '404';
import UnderMaintenance from 'UnderMaintenance';
import HtmlOffplanProjects from 'cms/pages/offplan-projects';
import HtmlFinancialReports from 'cms/pages/about/financial-reports';
import HtmlAwards from 'cms/pages/about/awards';


import { Suspense } from 'react';
import { CampaignRoute } from 'routing/CampaignRoute';
import AppraisalLogin from 'appraisal/login';
import { AppraisalRoute } from 'routing/AppraisalRoute';
import AppraisalIndex from 'appraisal/Index';
import { ht } from 'date-fns/locale';

const Application = loadable(() => import("application/Index"));
const DashboardLogin = loadable(() => import("dashboard/login"));
const Dashboard = loadable(() => import("dashboard/Index"));
const Takaful = loadable(() => import("takaful/Index"));
const Redf = loadable(() => import("redf/Index"));
const ResetPassword = loadable(() => import("dashboard/ResetPassword"))
const ChangePassword = loadable(() => import("dashboard/ChangePassword"))

const Campaign = loadable(() => import('cms/pages/campaigns/index'));
const ContractReview = loadable(() => import('cms/pages/contract/index'));
const CampaignLogin = loadable(() => import('campaign/login'));
const CampaignIndex = loadable(() => import('campaign/Index'));

// Lazyloading application form components, but loading css here for calculator in homepage.
require("styles/components/application-form/application-forms.scss");

const languages = [
  {
    code: 'en',
    name: 'EN',
    dir: 'ltr',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
  },
]
function findCommon(arr1, arr2) {
  return arr1.some(item => arr2.includes(item))
}
const Routes = () => {
  const location = useLocation();
  const { path } = useRouteMatch();

  const isApp = location.pathname.includes("application");
  const islogin = location.pathname.includes("login");
  const isMobile = isMobileOnly;
  const [fixedNav, setFixedNav] = useState(false);

  const campaignArray = ['campaign', '404', '91-national-day-campaign', 'SMS', 'Aramco', 'EmployerC', 'EmployerE', 'EmployerW', 'DeveloperC', 'DeveloperE', 'DeveloperW'];
  const camapign = findCommon(location.pathname.split('/'), campaignArray);
  const { cms, setCms } = useContext(CmsContext);
  let pathArr = [];
  if (typeof window !== 'undefined') {
    pathArr = window.location.pathname.split('/');
  } else {
    pathArr[1] = 'ar';
  }
  const currentLanguageCode = pathArr[1] === 'en' ? 'en' : 'ar';
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)

  useEffect(() => {
    if (!isApp) {
      if (!islogin) {
        if (!isMobile) {
          if (!camapign) {
            setFixedNav(true);
          } else {
            setFixedNav(false);
          }
        } else {
          setFixedNav(false);
        }
      } else {
        setFixedNav(false);
      }
    } else {
      setFixedNav(false);
    }

    // Page level GA
    const baseUri = location.pathname.split('/').slice(-1).pop();
    analytics.trackEvent('pageView', {
      'language': currentLanguageCode,
      'user-id': storage.get('user_id'),
      pageType: ['en', 'ar'].includes(baseUri) ? 'home' : baseUri
    });
  }, [location.pathname, isApp, isMobile, islogin, camapign]);

  useEffect(() => {
    // Set language attributes
    document.getElementsByTagName("HTML")[0].lang = currentLanguageCode;
    document.getElementsByTagName("HTML")[0].dir = currentLanguage.dir || 'rtl';
    document.getElementsByTagName("body")[0].dir = currentLanguage.dir || 'rtl';
    // Set in localstorage
    setCms(prevState => ({
      ...prevState,
      direction: currentLanguageCode === "ar" ? "rtl" : "ltr",
      language: currentLanguageCode ? currentLanguageCode : "ar",
    }));
  }, [currentLanguage, currentLanguageCode]);
  return (
    <main className={`${fixedNav ? "fixed-nav" : ""}`} style={{ visibility: !process?.browser ? 'hidden' : 'visible' }} >
      {/* Add the Front end only routes to shared/feRoutes.js */}
      <Switch>
        {/* REDIRECT - COMMENT LATER */}
        {/* <Route exact path={`${path}/login`}>
              <Redirect to={`${path}/under-maintenance`} />
            </Route>
            <Route path={`${path}/application`}>
              <Redirect to={`${path}/under-maintenance`} />
            </Route> */}
        {/* REDIRECT END */}

        <Route path={`${path}/html-awards`} component={HtmlAwards} exact />

        <DialogBoxServiceProvider>
          <AuthenticationContextProvider initialState={initialState} reducer={authenticationReducer} >
            <DashboardDialogProvider>
              <Route path={`${path}/login`} component={DashboardLogin} />
              <PrivateRoute path={`${path}/dashboard`} component={Dashboard} />
              <Route path={`${path}/takaful/login`} component={TakafulLogin} />
              <TakafulRoute path={`${path}/takaful`} component={Takaful} />
              <Route path={`${path}/redf/login`} component={RedfLogin} />
              <RedfRoute path={`${path}/redf`} component={Redf} />
              <Route path={`${path}/reset-password/:email_id/:temp_pass`} component={ResetPassword} />
              <Route path={`${path}/change-password`} component={ChangePassword} />
              <Route path={`${path}/campaign-report/login`} component={CampaignLogin} />
              <CampaignRoute path={`${path}/campaign-report`} component={CampaignIndex} />
              <Route path={`${path}/appraisal/login`} component={AppraisalLogin} />
              <AppraisalRoute path={`${path}/appraisal`} component={AppraisalIndex} />
            </DashboardDialogProvider>
            <Route path={`${path}/campaign`} component={Campaign} />
            <Route path={`${path}/contractreview`} component={ContractReview} />

            <Route path={`${path}/404`} component={NotFound} exact />
            <Route path={`${path}/under-maintenance`} component={UnderMaintenance} exact />

            <Route path={`${path}**`} render={({ staticContext, ...props }) => {
              // Check if path is part of shared/feRoutes.js, if not then only
              // call backend api.
              if (checkFeRoute(getPathWoLang(props?.location?.pathname))) {
                return <></>;
              }
              return <Main {...staticContext} />
            }
            } />
          </AuthenticationContextProvider>
          <DashboardDialogProvider>
            <Route path={`${path}/application`} component={Application} />
          </DashboardDialogProvider>
        </DialogBoxServiceProvider>
      </Switch>
    </main>
  );
}

export default Routes;
