import React, { useContext,useEffect,useState } from 'react';
import { useLocation } from "react-router-dom";
import { ThemeProvider,StylesProvider, jssPreset } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";
import { create } from 'jss';
import rtl from 'jss-rtl';

import { CmsContext } from '../context/cms/CmsContext';

import themeLight from 'theme/themeLight';
import themeLightRTL from 'theme/themeLight-rtl';
import themeDark from 'theme/themeDark';
import themeDarkRTL from 'theme/themeDark-rtl';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const Theme = (props) => {
  const { cms } = useContext(CmsContext);
  const { children } = props;

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const native = query.get("native");
  const [nativeClass, setNativeClass] = useState("");
  const [data, setData] = useState([]);

  const location = useLocation();
  const [pageClass, setPageClass] = useState("");
  const pagename = location.pathname.split(/[/]+/).pop();
  useEffect(() => {
    let checkpage = location.pathname.split(/[/]+/).length;
    if(checkpage > 2) {
      setPageClass(location.pathname.split(/[/]+/)[2]);
    } else {
      setPageClass(pagename);
    }    
  }, [pagename]);

  const [defaultTheme, setDefaultTheme] = useState(cms?.theme==="theme-dark" ? themeDark : themeLight);
  useEffect(() => {
    console.log(cms?.direction, 'direction');
    if(cms?.theme==="theme-light" && cms?.direction==="ltr") {
      setDefaultTheme(themeLight)
    }else if(cms?.theme==="theme-light" && cms?.direction==="rtl") {
      setDefaultTheme(themeLightRTL)
    }else if(cms?.theme==="theme-dark" && cms?.direction==="ltr") {
      setDefaultTheme(themeDark)
    }else if(cms?.theme==="theme-dark" && cms?.direction==="rtl") {
      setDefaultTheme(themeDarkRTL)
    }
    if((native === "true")) {
      localStorage.setItem('device', "nativeapp");
      setNativeClass("nativeapp");
    }
    if((native === "false")) {
      localStorage.removeItem('device');
      setNativeClass("");
    }
    if(localStorage.getItem('device') == "nativeapp")
    {
      setNativeClass("nativeapp");
    }
  }, [cms?.direction,cms?.theme]); 

  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={defaultTheme}>
        <div dir={cms?.direction} className={`main-div ${cms?.theme==="theme-dark" ? 'theme-dark' : 'theme-light'} ${pageClass} ${nativeClass}`}>
          <CssBaseline />
          {children}
        </div>
      </ThemeProvider>
    </StylesProvider>
  );
};

export const withTheme = (Component) => {
  return () => {
    return (
      <Theme >
        <Component />
      </Theme>
    );
  };
};