import * as React from "react";
import DialogBox from "../application/Components/FormsUI/DialogBox";

const DialogBoxServiceContext = React.createContext();

export const useDialogBox = () =>  React.useContext(DialogBoxServiceContext);

export const DialogBoxServiceProvider = ({ children }) => {
  const [ DialogBoxState, setDialogBoxState ] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const awaitingPromiseRef = React.useRef();

  const openDialogBox = (options) => {
    setDialogBoxState(options);
    setOpen(true);
    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (DialogBoxState.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }
    setOpen(false);
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }

    setOpen(false);
  };

  const handleClosed = () => {
    setDialogBoxState(null);
  };

  return (
    <>
      <DialogBoxServiceContext.Provider
        value={openDialogBox}
        children={children}
      />

      <DialogBox
        open={open}
        onSubmit={handleSubmit}
        onClose={handleClose}
        onClosed={handleClosed}
        {...DialogBoxState}
      />
    </>
  );
};

export const formatMessage = (msg) => {
  let msgStr = '';

  if (msg instanceof Array) {
    msg.forEach((msgEle) => {
      if (msgEle instanceof Object) {
        msgStr += msgEle['Message'] + ' ';
      } else {
        msgStr += msgEle + ' ';
      }
    });
  } else {
    msgStr = msg;
  }
  return msgStr;
}