import React from 'react';
import { isMobileOnly } from "react-device-detect";
import Button from '@material-ui/core/Button';
import LangLink from "components/lang/LangLink";
import DOMPurify from 'isomorphic-dompurify';

require("styles/components/common/full-width-image.scss");

const FullWidthImg = (props) => {
  const isMobile = isMobileOnly;
  const data = props.block.data;
  console.log(data);
  return (
    <section className="section-full-width-img">
        <div className="container">
            <div className="full-width-img">
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-12">
                        <figure className="img-wr">
                            <img src={`${isMobile ? data?.field_mobile?.[0]?.url : data.field_image?.[0]?.url }`} alt="" className="img-fluid" />
                            <figcaption className="content-wr">
                                <div className="row align-items-center">
                                    <div className="col-9 col-sm-5 col-content">
                                        <span className="title" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data?.body?.[0]?.value) }}></span>
                                        { data.field_link.length && (
                                            <Button variant="contained" color="secondary" size="medium" component={LangLink} to={data?.field_link?.[0]?.uri}>
                                             { data?.field_link?.[0]?.title }
                                            </Button>
                                        ) }
                                    </div>
                                </div> 
                            </figcaption>    
                        </figure>
                    </div>
                </div>
            </div>
        </div>
      
    </section>
  );
}

export default FullWidthImg;