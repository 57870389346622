import React,{ useContext, useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from "react-device-detect";

import Slider from "react-slick";
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';

import { CmsContext } from "context/cms/CmsContext";

import "slick-carousel/slick/slick.scss"; 
import "slick-carousel/slick/slick-theme.scss";

import LinearProgress from '@material-ui/core/LinearProgress';

import slideup from 'assets/images/awards/slide-up.svg';
import slidedown from 'assets/images/awards/slide-down.svg';

import slide01 from 'assets/images/awards/slide-01.webp';
import slide02 from 'assets/images/awards/slide-02.webp';
import slide03 from 'assets/images/awards/slide-03.webp';
import slide04 from 'assets/images/awards/slide-04.webp';
import slide05 from 'assets/images/awards/slide-05.webp';
import slide01Mobile from 'assets/images/awards/slide-01-mobile.webp';
import slide02Mobile from 'assets/images/awards/slide-02-mobile.webp';
import slide03Mobile from 'assets/images/awards/slide-03-mobile.webp';
import slide04Mobile from 'assets/images/awards/slide-04-mobile.webp';
import slide05Mobile from 'assets/images/awards/slide-05-mobile.webp';

require("styles/components/sliders/slick-awards-slider.scss");

const SlickAwardsSlider = (props) => {

  const { t } = useTranslation();
  const { cms } = CmsContext ? useContext(CmsContext) : null;
  const isMobile = isMobileOnly;

  const slider = useRef(null);

  const [currentSlide, setcurrentSlide] = useState(0);
  const [nextSlide, setnextSlide] = useState(0);
  const [countSlide, setcountSlide] = useState(0);

  const [progressVal, setprogressVal] = useState();

  useEffect(() => {
    setprogressVal((100/countSlide)*(nextSlide+1))
  }, [nextSlide,currentSlide,countSlide, slider.current,slider.next]);

  const settings = {
    dots: true,
    customPaging: function (i) {
      setcountSlide(i+1)
      return <div className="dots-wr">{i+1}</div>;
    },
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows:false,
    vertical: true,
    swipeToSlide: false,
    beforeChange: (current, next) => setnextSlide(next),
    afterChange: (current, next) => setcurrentSlide(current),
  };

  return (
    <Box className="slick-slider-wr slick-awards-slider">
      <Slider ref={slider} {...settings}>
        <div className="slick-inner-wr"><img src={isMobile?slide01Mobile:slide01} alt="" className="img-fluid img-slide"/></div>
        <div className="slick-inner-wr"><img src={isMobile?slide02Mobile:slide02} alt="" className="img-fluid img-slide"/></div>
        <div className="slick-inner-wr"><img src={isMobile?slide03Mobile:slide03} alt="" className="img-fluid img-slide"/></div>
        <div className="slick-inner-wr"><img src={isMobile?slide04Mobile:slide04} alt="" className="img-fluid img-slide"/></div>
        <div className="slick-inner-wr"><img src={isMobile?slide05Mobile:slide05} alt="" className="img-fluid img-slide"/></div>
      </Slider>
      <div className="slider-nav-wr">
        <IconButton
          className={
            "slick-back slick-arrow" + (nextSlide == 0 ? " slick-disabled" : "")
          }
          aria-hidden="true"
          aria-disabled={nextSlide === 0 ? true : false}
          type="button"
          onClick={() => slider?.current?.slickPrev()}
        >
          <span className="svg-wr"><img src={slideup} /></span>
        </IconButton>
        <div className="progress-wr" >
          <div className="progress-inner-wr">
            <LinearProgress variant="determinate" value={progressVal} />
          </div>
        </div>
        <IconButton
          size="medium"
          className={
            "slick-forward slick-arrow" +
            (currentSlide === countSlide - 1 ? " slick-disabled" : "")
          }
          aria-hidden="true"
          aria-disabled={currentSlide === countSlide - 1 ? true : false}
          type="button"
          onClick={() => slider?.current?.slickNext()}
        > 
          <span className="svg-wr"><img src={slidedown} /></span>
        </IconButton>
      </div>
    </Box>
  );
}

export default SlickAwardsSlider;