import LoaderIcon from "assets/images/LoaderIcon";
const LoaderBlank = () => {
    return (
        <>
    <div className="main-loader">
			<div className="loader-logo">
      </div>
    </div>
    </>
    );
}
export default LoaderBlank;