import React, { useEffect } from "react";
import { TextField, MenuItem } from "@material-ui/core";
import { useField, useFormikContext } from "formik";

const Select = ({ name, options = [], setSelectValue, ...otherProps }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  useEffect(() => {
    setFieldValue(name, field?.value);
    if (setSelectValue !== undefined) {
      setSelectValue(field?.value);
    }
  }, [field?.value])
  const handleChange = (evt) => {
    const { value } = evt.target;
    setFieldValue(name, value);
    if (setSelectValue !== undefined) {
      setSelectValue(value);
    }
  };

  const configSelect = {
    ...field,
    ...otherProps,
    select: true,
    fullWidth: true,
    onChange: handleChange,
  };

  if (meta && meta.touched && meta.error) {
    configSelect.error = true;
    configSelect.helperText = meta.error;
  }

  return (
    <TextField {...configSelect}>
      {options?.length > 0 && Array.isArray(options) ?
        options?.map((item, pos) => (
            <MenuItem key={pos} value={item.field_generic_id}>
              {item.name}
            </MenuItem>
          )) : (<MenuItem value="" />)}
    </TextField>
  );
};

export default Select;
