import React, { lazy } from "react";
import loadable from "@loadable/component";

import Navbar from "cms/blocks/common/NavBar";
import HomeHeader from "cms/blocks/banner/BannerHome";
import FeaturedIcons from "cms/blocks/featured/FeaturedIcons";
import RelatedItems from "cms/blocks/featured/RelatedItems";
import Products from "cms/blocks/featured/Products";
import Projects from "cms/blocks/featured/Projects";
import Logos from "cms/blocks/featured/Logos";
import BannerIntroContent from "cms/blocks/banner/BannerIntroContent";
import FullWidthImg from "cms/blocks/common/FullWidthImg";
import Calculators from "cms/blocks/calculators";
import { Pages } from "@material-ui/icons";

const Complaint = loadable(() => import("cms/blocks/contact/Complaint"));
const ContactUs = loadable(() => import("cms/blocks/contact/ContactUs"));
const CustomerCare = loadable(() => import("cms/blocks/contact/CustomerCare"));
const FAQs = loadable(() => import("cms/blocks/contact/Faqs"));
const HomeAboutBidaya = loadable(() => import("./featured/HomeAboutBidaya"));
const ProductLandingBidaya = loadable(() => import("./featured/ProductLandingBidaya"));
const FeaturedProducts = loadable(() => import("./featured/FeaturedProducts"));
const CalculatorList = loadable(() => import("./common/CalculatorList"));
const Members = loadable(() => import("./pages/Members"));
const Developers = loadable(() => import("./pages/Developers"));
const JoinTheTeam = loadable(() => import("cms/blocks/contact/JoinTheTeam"));
const WhoWeAre = loadable(() => import("cms/blocks/pages/WhoWeAre"));
const Video = loadable(() => import("./common/Video"));
const ShariaMembers = loadable(() => import("./pages/ShariaMembers"));
const Documents = loadable(() => import("./pages/Documents"));
const CmsStaticPage = loadable(() => import("cms/blocks/common/CmsStaticPage"));
const CorporateRegister = loadable(() => import("cms/blocks/pages/CorporateRegister"));
const CmsAboutPage = loadable(() => import("cms/blocks/common/CmsAboutPage"));
const VideoItem = loadable(() => import("cms/blocks/media/VideoItem"));
const FinancialReports = loadable(() =>
  import("cms/blocks/pages/FinancialReports")
);
const SocialResponsibility = loadable(()=>
  import("cms/blocks/pages/SocialResponsibility")
)
const Awards = loadable(()=>
  import("cms/blocks/pages/Awards")
)
const OurProjects = loadable(() => import("cms/blocks/pages/OurProjects"));
const CalculatorsLanding = loadable(() =>
  import("cms/blocks/calculators/CalculatorsLanding")
);
const ProductDisclaimer = loadable(() =>
  import("cms/blocks/featured/ProductDisclaimer")
);

const PressRelease = loadable(() => import("cms/blocks/media/PressRelreaseItem"));
const SocialResponsibilityItem = loadable(() => import("cms/blocks/pages/SocialResponsibilityItem"));
const MediaTabs = loadable(() => import("cms/blocks/media/MediaTabs"));
const ComponentsMapping = {
  bidaya_main_menu: Navbar,
  views_block__banner_slider: HomeHeader,
  featured_icons: FeaturedIcons,
  homeaboutbidaya: HomeAboutBidaya,
  retailproductpage: ProductLandingBidaya,
  corporateproductpage: ProductLandingBidaya,
  views_block__featured_block_type1: FeaturedProducts,
  views_block__featured_corporate_block_1: Products,
  views_block__featured_block_type2: Projects,
  views_block__featured_block_type3: Products,
  views_block__logos: Logos,
  intro_banner_page: BannerIntroContent,
  full_width_img_applynow: FullWidthImg,
  bidaya_calculators: Calculators,
  calculators_list: CalculatorList,
  views_block__bidaya_members: Members,
  developers: Developers,
  complaint: Complaint,
  contactus: ContactUs,
  customercare: CustomerCare,
  contactusfaq: FAQs,
  careers: JoinTheTeam,
  whoweare: WhoWeAre,
  video: Video,
  sharia_members: ShariaMembers,
  documents: Documents,
  cms_staticpage: CmsStaticPage,
  aboutpage: CmsAboutPage,
  views_block__press_release: MediaTabs,
  about_us_media_press_release_content: PressRelease,
  about_us_media_video_content: VideoItem,
  views_block__financial_reports: FinancialReports,
  views_block__social_responsibility_block_1: SocialResponsibility,
  socialresponsibility:SocialResponsibilityItem,
  mohprojects: OurProjects,
  bidayacalculators: CalculatorsLanding,
  productdisclaimer_cms_block1: ProductDisclaimer,
  views_block__banner_slider_block_2: Awards,
  corporateregister: CorporateRegister
  // views_block__contact_us_headquarter_maps_block_block_1: MapTabs
};

const Component = ({ blockName, id, block, full_layout, ...props }) => {
  let ComponentName = ComponentsMapping?.[blockName];
  if (!ComponentName) {
    return <></>
  }
  return (
    <ComponentName key={id} block={block} full_layout={full_layout} {...props} />
  ); 
};

export default Component;
