import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from "react-device-detect";
import { CmsContext } from "context/cms/CmsContext";


import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

//forms elements start
import { Formik, Form } from "formik";
import * as Yup from "yup";

import SliderInput from "application/Components/FormsUI/SliderInput";
import SwitchWrapper from "application/Components/FormsUI/Switch";
import SelectWr from "application/Components/FormsUI/Select/Select";
import ButtonSubmit from "application/Components/FormsUI/Button";
import TextField from "application/Components/FormsUI/Textfield";


import analytics from "services/analytics";
import calculator from "services/calculator";
import { getMaster, getSectorSubsectorFromCompany } from "services/masterData";
import { useDialogBox, formatMessage } from "context/DialogBoxService";

import { getNumberFormat, getPercentAmount } from "application/utility";
import NumberFormat from "react-number-format";
import LangLink from "components/lang/LangLink";
import AutoSelect from "application/Components/FormsUI/Select/AutoSelect";
import RadioButton from "application/Components/FormsUI/ToggleButtons/RadioButton";
import DOMPurify from "isomorphic-dompurify";
import NumberOnly from "application/Components/FormsUI/Textfield/NumberOnly";


require("styles/components/home/calculators.scss");
require("styles/components/home/home-calculators.scss");

const INITIAL_FORM_STATE = {
  obligation: '',
  repayment_terms: '',
  salary: '',
  age: '',
  interest_type: '',
  approval: false,
  region: '',
  sector: '',
  company: '',
  product: 'NORMAL',
  retired_income: '',
  retirement_age: '',
  obligation_tenure: '',
  firsthomebuyer: false,
};

const FORM_VALIDATION = (t) => {
  return Yup.object().shape({
    salary: Yup.number()
      .integer()
      .required(t('validation:FIELD_REQUIRED_CUST', {field_label: t('i_earn_per_month')}))
      .min(4000, t("validation:INCOME_MIN", {amount: 4000}))
      .max(10000000, t("validation:INCOME_MAX", {amount: 10000000})),
    obligation: Yup.number()
      .integer()
      .required(t('validation:FIELD_REQUIRED_CUST', {field_label: t('my_obligations_per_month')}))
      .min(0, t('validation:MIN_VALUE',{value: 0}))
      .max(getPercentAmount(  getNumberFormat(10000000), 60,), t("validation:obligation_error")),
    repayment_terms: Yup.number()
      .integer()
      .required(t('validation:FIELD_REQUIRED_CUST', {field_label: t('repayment_term_years')})),
    age: Yup.number()
      .integer()
      .required(t('validation:FIELD_REQUIRED_CUST', {field_label: t('my_age')}))
      .min(20, t("validation:AGE_MIN_MAX", {min: 20, max: 65}))
      .max(65, t("validation:AGE_MIN_MAX", {min: 20, max: 65})),
    interest_type: Yup.string().required(t('validation:FIELD_REQUIRED_CUST', {field_label: t('interest_type')})),
    region: Yup.string().required(t('validation:FIELD_REQUIRED_CUST', {field_label: t('Region')})),
    sector: Yup.string().required(t('validation:FIELD_REQUIRED_CUST', {field_label: t('your_sector')})),
    product: Yup.string().required(t('validation:FIELD_REQUIRED_CUST', {field_label: t('appForm:I_WOULD_LIKE')})),
    retired_income: Yup.number().when("product", {
      is: "STEPDN",
      then: Yup.number()
    .integer()
    .required(t('validation:FIELD_REQUIRED_CUST', {field_label: t('expected_retirement_income')}))
    .min(0, t("validation:INCOME_MIN", {amount: 0}))
    .max(10000000, t("validation:INCOME_MAX", {amount: 10000000})) }),
    retirement_age: Yup.number().when("product", {
      is: "STEPDN",
      then: Yup.number()
    .integer()
    .required(t('validation:FIELD_REQUIRED_CUST', {field_label: t('retirement_age')}))
    .min(1, t("validation:MIN_VALUE", {value: 1}))
    .max(80, t("validation:MAX_VALUE", {value: 80}))}),
    obligation_tenure:Yup.number().when("product", {
      is: "STEPUP",
      then: Yup.number()
    .integer()
    .required(t('validation:FIELD_REQUIRED_CUST', {field_label: t('financial_obligation_remaining_tenure')}))
    .min(1, t("validation:MIN_VALUE", {value: 1}))
    .max(5, t("validation:MAX_VALUE", {value: 1}))}),
    company: Yup.string().required(t('validation:FIELD_REQUIRED_CUST', {field_label: t('appForm:COMPANY_NAME')})),
  })
};

const Calculators = ({disclaimer, ...props}) => {
  const { t,i18n } = useTranslation(["translation", "common", "validation","appForm"]);
  const { cms } = useContext(CmsContext);
  const showDialogBox = useDialogBox();
  const isMobile = isMobileOnly;
  const switchStat = (value) => {
    console.log("switchStat")
  };
  

  
  //radio
  const [value, setValue] = React.useState('NORMAL');
  const [maxFinanceRes, setMaxFinanceRes] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  
  const handleSubmit = (values) => {
    if(value === "STEPDN" && (parseInt(values?.age) > parseInt(values?.retirement_age))) {
      showDialogBox({
        variant: "info",
        catchOnCancel: true,
        title: "",
        description: "Retirement age should not be less than your age",
      })
        .then(() => console.log("Done"))
        .catch(() => console.log("error"));
      return false;
    }
    if(value === "STEPDN" && ((parseInt(values?.age) + parseInt(values?.repayment_terms)) < parseInt(values?.retirement_age))) {
      showDialogBox({
        variant: "info",
        catchOnCancel: true,
        title: "",
        description: "Loan maturity gets completed before your retirement",
      })
        .then(() => console.log("Done"))
        .catch(() => console.log("error"));
      return false;
    }
    if(value === "STEPUP" && (parseInt(values?.obligation_tenure) > parseInt(values?.repayment_terms))) {
      showDialogBox({
        variant: "info",
        catchOnCancel: true,
        title: "",
        description: "Obligation tenure should not be greater than the payment term",
      })
        .then(() => console.log("Done"))
        .catch(() => console.log("error"));
      return false;
    }
    if((value === "STEPUP" || value === "STEPDN") && ((parseInt(values?.obligation) / parseInt(values?.salary)) * 100 > 45)) {
      showDialogBox({
        variant: "info",
        catchOnCancel: true,
        title: "",
        description: "Obligation should be less than 45% of your income.",
      })
        .then(() => console.log("Done"))
        .catch(() => console.log("error"));
      return false;
    }
    const rData = {
        __misc: {
          cal_type: 'max_finance'
        },
        max_finance: {
          "salary": values?.salary,
          "obligation": values?.obligation,
          "age": values?.age,
          "property_price": "0",
          "down_payment": "0",
          "region": values?.region,
          "repayment_terms": values?.repayment_terms,
          "firsthomebuyer": values?.firsthomebuyer === true ? 1 : 0,
          "approval": values?.approval === true ? 1 : 0,
          "sector": values?.sector,
          "company": values?.company !== "" ? values?.company : "0",
          "product": values?.product,
          "interest_type": values?.interest_type,
          "retiredIncome": values?.retired_income,
          "retirmentage": values?.retirement_age,
          "obligation_tenure": values?.obligation_tenure,
      }
    };  
    setIsLoading(true);
    calculator(rData).then(res => {
      setIsLoading(false);
      console.log(res.data);
      console.log('check point 22')
      if(res.data.status) {
        setMaxFinanceRes(res.data.result);
        console.log('check point 1')
      }
      else {
        showDialogBox({
          variant: "info",
          catchOnCancel: true,
          title: "",
          description: formatMessage(res.data.messages),
        })
          .then(() => console.log("Done"))
          .catch(() => console.log("error"));
        setMaxFinanceRes({});
        
      }
    });

    analytics.trackEvent('calculatorUsage', {
      calculatorType: 'Maximum finance'
    });
  };

  const [regions, setRegions] = React.useState({});
  const [sectors, setSectors] = React.useState({});
  const [companies, setCompany] = React.useState({});
  const [maxObligation, setMaxObligation] = useState(
    getPercentAmount(
      getNumberFormat(50000),
      60,
    ),
  );
  const [months,setMonths] = useState(0);
  const handleObligationTenure = (years) => {
    setMonths(years*12);
  }
  const [employmentSubsector, setEmploymentSubsector] = useState("");

  useEffect(() => {
    getMaster('regions').then(data => {
      setRegions(data);
    });
    getMaster('sectors').then(data => {
      setSectors(data);
    });
    getMaster('companies').then(data => {
      setCompany(data);
    })
  },[]);

  const stepUpData = [
    { name: t('input_stepup_feature'), field_value: "STEPUP", field_generic_id: "STEPUP" },
    { name: t('input_stepdown_feature'), field_value: "STEPDN", field_generic_id: "STEPDN" },
    { name: t('input_either'), field_value: "NORMAL", field_generic_id: "NORMAL" },
  ];
  const paymentOptions = [
    { name: `5 ${t('translation:p_years')}`, field_generic_id: '5' },
    { name: `6 ${t('translation:p_years')}`, field_generic_id: '6' },
    { name: `7 ${t('translation:p_years')}`, field_generic_id: '7' },
    { name: `8 ${t('translation:p_years')}`, field_generic_id: '8' },
    { name: `9 ${t('translation:p_years')}`, field_generic_id: '9' },
    { name: `10 ${t('translation:p_years')}`, field_generic_id: '10' },
    { name: `11 ${t('translation:p_years')}`, field_generic_id: '11' },
    { name: `12 ${t('translation:p_years')}`, field_generic_id: '12' },
    { name: `13 ${t('translation:p_years')}`, field_generic_id: '13' },
    { name: `14 ${t('translation:p_years')}`, field_generic_id: '14' },
    { name: `15 ${t('translation:p_years')}`, field_generic_id: '15' },
    { name: `16 ${t('translation:p_years')}`, field_generic_id: '16' },
    { name: `17 ${t('translation:p_years')}`, field_generic_id: '17' },
    { name: `18 ${t('translation:p_years')}`, field_generic_id: '18' },
    { name: `19 ${t('translation:p_years')}`, field_generic_id: '19' },
    { name: `20 ${t('translation:p_years')}`, field_generic_id: '20' },
    { name: `21 ${t('translation:p_years')}`, field_generic_id: '21' },
    { name: `22 ${t('translation:p_years')}`, field_generic_id: '22' },
    { name: `23 ${t('translation:p_years')}`, field_generic_id: '23' },
    { name: `24 ${t('translation:p_years')}`, field_generic_id: '24' },
    { name: `25 ${t('translation:p_years')}`, field_generic_id: '25' },
    { name: `26 ${t('translation:p_years')}`, field_generic_id: '26' },
    { name: `27 ${t('translation:p_years')}`, field_generic_id: '27' },
    { name: `28 ${t('translation:p_years')}`, field_generic_id: '28' },
    { name: `29 ${t('translation:p_years')}`, field_generic_id: '29' },
    { name: `30 ${t('translation:p_years')}`, field_generic_id: '30' },
];

  return (
    <section className="section-calculators home-calculator-section">
      <div className="container">
        <Formik
          initialValues={{
            ...INITIAL_FORM_STATE,
          }}
          validationSchema={FORM_VALIDATION(t)}
          onSubmit={handleSubmit}>
         {({ setFieldValue }) => (
          <Form>
            <div className="form-wrapper">
              <div className="row">
                <div className="col-12 col-title">
                  <h1 className="title text-left">
                    {t('Try_Out_Finance_Calcilator')}
                  </h1>
                </div>
                <div className="col-12 col-sm-12 col-cal clac-form-wr">
                    <h2 className="sub-title">
                    {t('cal_max_finance')}
                    </h2>
                    <div className="step-wr cal-wr pb-0">
                      <div className="row">
                        <div className="col-12 col-sm-3 home-calc-input-wrap">
                          <Paper className="field-wr input-field" elevation={0} square>
                            <div className="field-data currency-label">
                              <span>{t('common:SAR')}</span>
                            </div>
                            <div className="field-data lbl-left home-form-with-num">
                              <NumberOnly name="salary" placeholder={t('i_earn_per_month')} currency={t('common:SAR')}
                              min="4000"
                              max="100000"
                              />
                            </div>
                          </Paper>
                        </div>
                        <div className="col-12 col-sm-3 home-calc-input-wrap">
                          <Paper elevation={0} square className="field-wr input-field">
                              <div className="field-data currency-label">
                                <span>{t('common:SAR')}</span>
                              </div>
                              <div className="field-data lbl-left home-form-with-num">
                                <NumberOnly name="obligation" placeholder={t('my_obligations_per_month')} currency={t('common:SAR')} />
                              </div>
                            </Paper>
                        </div>
                        <div className="col-12 col-sm-3 home-calc-select-wrapper">
                          <SelectWr name="repayment_terms" label={t('repayment_term_years')}  options={paymentOptions} />
                        </div>
                        <div className="col-12 col-sm-3 home-calc-input-wrap">
                          <Paper elevation={0} square className="field-wr input-field">
                            <div className="field-data lbl-right home-form-with-num">
                              <NumberOnly name="age" placeholder={t('my_age')} currency="" min="20"
                              max="65" />
                            </div>
                            <div className="field-data currency-label">
                              <span>{t('common:YEARS')}</span>
                            </div>
                          </Paper>
                        </div>
                        {  isMobileOnly &&
                        <div className="col-12 col-sm-6 clac-first-time-finance">
                          <div className="field-wr radio-field mb-0">
                            <RadioButton
                              label={t('are_you_first_time_home_buyer')}
                              name="firsthomebuyer"
                              values="yes,no"
                              labels={t("common:SWITCH_LABLE")}
                              className="radio-align"
                              color="primary"
                            />
                          </div>
                        </div>
                        }
                        {  isMobileOnly &&
                        <div className="col-12 col-sm-6 profit-rate-calc">
                          <div className="field-wr radio-field">
                            <RadioButton
                              label={t('interest_type')}
                              name="interest_type"
                              id="interest_type"
                              values="floating,fixed"
                              labels={`${t('Floating')},${t('Fixed')}`}
                              className="radio-align"
                              color="primary"
                            />
                          </div>
                        </div>
                        }
                        <div className="col-12 col-sm-3 home-calc-select-wrapper">
                          <SelectWr
                            name="region"
                            label={t('Region')}
                            options={regions}
                          />
                        </div>
                        <div className="col-12 col-sm-3 home-calc-select-wrapper">
                            
                            <AutoSelect
                                name="company"
                                label={t("appForm:COMPANY_NAME")}
                                options={companies}
                                setSelectValue={(value) => {
                                  getSectorSubsectorFromCompany(value).then(
                                    (response) => {
                                      if (response.status) {
                                        setEmploymentSubsector(
                                          response?.result?.sectors?.sub_sector
                                        );
                                        setFieldValue(
                                          "sector",
                                          response?.result?.sectors?.sector
                                        );
                                      }
                                    }
                                  );
                                }}
                              /> 
                          
                          {/* <SelectWr
                            name="company"
                            label={t("appForm:COMPANY_NAME")}
                            options={companies}
                            setSelectValue={(value) => {
                              getSectorSubsectorFromCompany(value).then(
                                (response) => {
                                  if (response.status) {
                                    setEmploymentSubsector(
                                      response?.result?.sectors?.sub_sector
                                    );
                                    setFieldValue(
                                      "sector",
                                      response?.result?.sectors?.sector
                                    );
                                  }
                                }
                              );
                            }}
                          /> */}

                        </div>
                        <div className="col-12 col-sm-3 home-calc-select-wrapper">
                          <SelectWr
                            name="sector"
                            label={t('your_sector')}
                            options={sectors}
                            inputprops={{ readOnly: true }}
                            //inputprops={{ className: "readonly" }}
                            disabled
                          />
                        </div>
                        <div className="col-12 col-sm-3 home-calc-select-wrapper">
                            {/* <Paper elevation={0} square className="field-wr input-field dropdown-field">
                            <AutoSelect
                              name="product"
                              options={stepUpData}
                              setSelectValue={setValue}
                            />
                            </Paper> */}
                            <SelectWr
                              name="product"
                              options={stepUpData}
                              setSelectValue={setValue}
                            />
                        </div>
                        
                        {value === "STEPUP" && 
                          <div className="col-12 col-sm-12 tenure-condition-wrap-par">
                            <div className="col-12 col-sm-6 field-wr slider-input tenure-wr tenure-condition-wrap">
                              <SliderInput
                                name="obligation_tenure"
                                label={t('financial_obligation_remaining_tenure')}
                                currency={t('appForm:YEARS')}
                                color="secondary"
                                step={1}
                                min="1"
                                max="5"
                                inputSize="3"
                                setSliderVal={handleObligationTenure}
                                onRight
                              />
                              {months !== "" && months !== undefined ? <span className="month_dispaly en"> {months} {t('months')}</span> : null}
                            </div>
                          </div>
                          }
                          {value === "STEPDN" && 
                          <>
                          <div className="col-12 col-sm-12 tenure-condition-wrap-par">
                          <div className="col-12 col-sm-6 field-wr slider-input tenure-condition-wrap">
                            <SliderInput
                              name="retirement_age"
                              label={t('retirement_age')}
                              currency={t('common:YEARS')}
                              color="secondary"
                              step={1}
                              min="25"
                              max="80"
                              inputSize="2"
                            />
                          </div>
                          </div>
                          <div className="col-12 col-sm-12 tenure-condition-wrap-par">
                          <div className="col-12 col-sm-6 field-wr slider-input tenure-condition-wrap">
                            <SliderInput
                              name="retired_income"
                              label={t('expected_retirement_income')}
                              currency={t('common:SAR')}
                              color="secondary"
                              step={1}
                              min="1"
                              max="100000"
                              inputSize="4"
                            />
                          </div>
                          </div>
                          </>
                        }
                        
                        {  !isMobileOnly &&
                        <div className="col-12 col-sm-6 clac-first-time-finance clac-first-time-finance-desk">
                          <div className="field-wr radio-field mb-0">
                            <RadioButton
                              label={t('are_you_first_time_home_buyer')}
                              name="firsthomebuyer"
                              values="yes,no"
                              labels={t("common:SWITCH_LABLE")}
                              className="radio-align"
                              color="primary"
                            />
                          </div>
                        </div>
                        }
                        <div className="col-12 col-sm-6 calc-redf calc-redf-desk">
                          <div className="field-wr radio-field mb-0 ">
                            <RadioButton
                              name="approval"
                              label={t('i_have_approved_from')}
                              values="yes,no"
                              labels={t("common:SWITCH_LABLE")}
                              className="radio-align"
                              color="primary"
                            />
                          </div>
                        </div>
                        {  !isMobileOnly &&
                        <div className="col-12 col-sm-6 profit-rate-calc profit-rate-calc-desk">
                          <div className="field-wr radio-field">
                            <RadioButton
                              label={t('interest_type')}
                              name="interest_type"
                              id="interest_type"
                              values="floating,fixed"
                              labels={`${t('Floating')},${t('Fixed')}`}
                              className="radio-align"
                              color="primary"
                            />
                          </div>
                        </div>
                        }
                      </div>
                        <div className="btn-wr d-flex justify-content-center home-calc-submit">
                          <ButtonSubmit variant="contained" color="secondary"  size="medium" className="d-none d-sm-flex" disabled={isLoading}>{t("calculate")}</ButtonSubmit>
                          <ButtonSubmit variant="contained" color="secondary" size="medium" className="d-flex d-sm-none btn-min" disabled={isLoading}>{t("calculate")}</ButtonSubmit>
                        </div>
                    </div>
                    

                </div>
                <div className="col-12 col-sm-12 col-result white-text-wr">

                <div className="data-group-wr">
                    
                    { maxFinanceRes?.amountFinance &&
                        <>
                          <div className="datas-wr">
                            <dl className="datas datas-main">
                              <dt>{t('your_eligible_receive')}</dt>
                              <dd>{t('common:SAR')}{" "}
                              <NumberFormat
                                thousandsGroupStyle="thousand"
                                value={maxFinanceRes?.amountFinance}
                                decimalSeparator="."
                                displayType="text"
                                type="text"
                                thousandSeparator={true}
                                allowNegative={true} className="en" />
                                </dd>
                            </dl>
                          </div>
                          <div className="datas-wr">
                          {(value === "STEPUP" || value === "STEPDN") && (
                          <>
                          <dl className="datas datas-sub">
                            <dt>{t("monthly_payment")} 1: </dt>
                            <dd>
                              &nbsp; {t("common:SAR")}{" "}
                              <NumberFormat
                                thousandsGroupStyle="thousand"
                                value={maxFinanceRes?.firstEmi}
                                decimalSeparator="."
                                displayType="text"
                                type="text"
                                thousandSeparator={true}
                                allowNegative={true}
                                className="en"
                              />
                            </dd>
                          </dl>
                          <dl className="datas datas-sub">
                            <dt>{t("monthly_payment")} 2: </dt>
                            <dd>
                              &nbsp; {t("common:SAR")}{" "}
                              <NumberFormat
                                thousandsGroupStyle="thousand"
                                value={maxFinanceRes?.secondEmi}
                                decimalSeparator="."
                                displayType="text"
                                type="text"
                                thousandSeparator={true}
                                allowNegative={true}
                                className="en"
                              />
                            </dd>
                          </dl>
                          </>
                          )}
                          {(value === "NORMAL") && (
                          <dl className="datas datas-sub">
                            <dt>{t("monthly_payment")}: </dt>
                            <dd>
                              &nbsp; {t("common:SAR")}{" "}
                              <NumberFormat
                                thousandsGroupStyle="thousand"
                                value={maxFinanceRes?.firstEmi}
                                decimalSeparator="."
                                displayType="text"
                                type="text"
                                thousandSeparator={true}
                                allowNegative={true}
                                className="en"
                              />
                            </dd>
                          </dl>
                          )}
                            <dl className="datas datas-sub">
                              <dt>{t('payment_term')}: </dt>
                              <dd> &nbsp; <span className="en">{maxFinanceRes?.payment_term}</span> {t('appForm:MONTHS')}</dd>
                            </dl>
                          </div>
                          <div className="datas-wr">
                            <dl className="datas datas-sub">
                              <dt>{t('profit_rate')} :</dt>
                              <dd> &nbsp; <span className="en">{maxFinanceRes?.profitRate}</span>%</dd>
                            </dl>
                            <dl className="datas datas-sub">
                              <dt>{t('equivalent_rate')}: </dt>
                              <dd> &nbsp; <span className="en">{maxFinanceRes?.irr}</span>%</dd>
                            </dl>
                          </div>
                        
                          <div className="btn-wr d-flex justify-content-center">
                            <LangLink to='/application/stage-01/basic-information#1' className="link-appli">
                              <Button className="nav-btn" variant="contained" color="secondary" size="small">
                                {t('Apply_Online')}
                              </Button>
                            </LangLink>
                          </div>
                        </>
                      }
                  </div>

                </div>
                
                
              </div>
              <div className="row ">
                <div className="col-12 col-sm-2 col-dummy d-none d-sm-block"></div>
                <div className="col-12 col-sm-7 col-terms">
                  <p className="desc" dangerouslySetInnerHTML={{__html: disclaimer ? DOMPurify.sanitize(disclaimer) : ''}}></p>
                </div>
              </div>
            </div>
          </Form>
          )}
        </Formik>
      </div>

      
    </section>
  );
}

export default Calculators;