import React from "react";
import LoaderIcon from "assets/images/LoaderIcon";

import "styles/loader.scss";

const Loader = (props) => {
  return (
    <div className="main-loader main-loader_full-wrapper">
      <div className="loader-logo">
        <LoaderIcon />
      </div>
      <div className="loader">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </div>
  );
};

export default Loader;
