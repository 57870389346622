
let direction = 'ltr';
let langcode = 'ar';
 if (typeof window !== "undefined" && document !== undefined) {
  direction = document.documentElement.dir || 'ltr';
  langcode = document.documentElement.lang
 } else {
  direction = 'ltr';
  }
const left = direction === 'ltr' ? 'left' : 'right';
const right = direction === 'ltr' ? 'right' : 'left';
const Globals = {
  direction: direction,
  left: left,
  right: right,
  marginLeft: `margin-${left}`,
  marginRight: `margin-${right}`,
  paddingLeft: `padding-${left}`,
  paddingRight: `padding-${right}`,
  localeCode: langcode,
}
export default Globals;


