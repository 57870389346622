import React from "react";
import { isMobileOnly } from "react-device-detect";
import Button from '@material-ui/core/Button';
import DOMPurify from "isomorphic-dompurify";
import { useTranslation } from 'react-i18next';
import LangLink from "components/lang/LangLink";
import OffPlan from "assets/images/project-listing/off-plan/off-plan-prjcts.png"

require("styles/components/home/projects.scss");

const Projects = (props) => {
  const isMobile = isMobileOnly;
  const data  = props.block.data;
  const { t } = useTranslation(['translation']);

  return (
    <>
    <section className="section-home-projects-new">
      <div className="container">
        <div className="home-projects-new__wrapper">
          <div className="home-projects-title">
            <h2>{t("PROJECTS")}</h2>
          </div>
          <div className="home-projects__wrapper">
            {
              data.rows.map((item, pos) => {
                return (
                  <a key={pos} href={item.field_link[0].uri + '&project_type=' + item.field_link[0].title} className="home-projects__single">
                    <div className="home-projects__left">
                      <h3 className="title" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item?.info?.[0]?.value) }}></h3>
                    </div>
                    <div className="home-projects__right">
                      <img src={isMobile ? item?.field_mobile?.[0]?.url : item?.field_image?.[0]?.url} alt={isMobile ? item.field_mobile?.[0]?.alt : item.field_image[0].alt}/>
                    </div>
                  </a>
                )
              })
            }
          </div>
        </div>
      </div>
    </section>
    </>
  );
}

export default Projects;