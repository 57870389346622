if (typeof window === 'undefined') {
    require('localstorage-polyfill');
}

const storage = {
    set: (key, val) => {
        const str = JSON.stringify(val);
        localStorage.setItem(key, str);
    },

    get: (key) => {
        return JSON.parse(localStorage.getItem(key));
    },

    remove: (key) => {
        localStorage.removeItem(key);
    },

    clear: () => {
        localStorage.clear();
    }
};

export default storage;