import React, { useState } from "react";
import { useField, useFormikContext } from "formik";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FormLabel } from "@material-ui/core";

const RadioButton = ({ name, label, values, labels, id, className, switchStat, ...otherProps }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const configInput = {
    name,
    id,
    ...field,
  };

  const configFormControl = {
    ...field,
    ...otherProps,
    className
  };

  if (meta && meta.touched && meta.error) {
    configFormControl.error = true;
  }

  const [fieldVal, setFieldVal] = useState(field?.value);
  const handleFieldVal = (event, newFieldVal) => {
    setFieldVal(newFieldVal);
    setFieldValue(name, newFieldVal);
    if (switchStat !== undefined) {
      switchStat(newFieldVal);
    }
  };

  const PropValues = values.split(",");
  const PropLabels = labels.split(",");

  const list = [];
  PropValues.reduce((acc, current, index) => {
    list.push({
      value: current,
      label: PropLabels[index],
    });
    return null;
  }, []);

  return (
    <>
        <FormControl component="fieldset" {...configFormControl}>
            <FormLabel component="div">{label} </FormLabel>
            <RadioGroup aria-label="gender" name={name} value={fieldVal} onChange={handleFieldVal}>
            {list.map((listt) => (
                <FormControlLabel key={listt.value} value={listt.value} control={<Radio />} label={listt.label} />
            ))}
            </RadioGroup>
        </FormControl>
        <Input {...configInput} type="hidden" />
        {meta.error !== undefined && meta.touched !== false && (
          <FormHelperText error>{meta.error}</FormHelperText>
        )}
    </>
  );
};

export default RadioButton;
