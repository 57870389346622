import DOMPurify from 'isomorphic-dompurify';
import React, { useState,useEffect } from 'react';
import {
  useLocation,
} from "react-router-dom";
import commercialProperty from 'assets/images/products/commercial-property-new.png';
import homeFinancing from 'assets/images/products/home-financing.png';
import axios from "axios";
import elgibility from 'assets/images/products/elgibility.svg';
import faq from 'assets/images/products/faq.svg';
import howToApply from 'assets/images/products/how-to-apply.svg';
import LangLink from 'components/lang/LangLink';
import { useTranslation } from "react-i18next";
import cookies from 'js-cookie'
//tabs start
import ArrowIcon from '@material-ui/icons/ChevronRight';
import Paper from '@material-ui/core/Paper';
import { Check, ExpandMore } from "@material-ui/icons";
import { mdiChevronDown } from '@mdi/js';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getData } from "services/masterData";
//tabs end
require("styles/components/common/facelift.scss");

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}
//tabs functions ends


require("styles/components/common/typography.scss");

const BasicContent = (props) => {
  const data = props.data;
  const layout = props.full_layout;
  const theme = useTheme();
  const renderHTML = rawHTML => React.createElement("div", { dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(rawHTML) }, className: "content-wrapper" });
  let applynowurl = "/application/stage-01/basic-information#1";
  const location = useLocation();
  const pagename = location.pathname.split(/[/]+/);


  // for tab function starts
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const [nodeType, setNodeType] = useState(data?.entity?.type?.[0]?.target_id);
  const [applyUrl, setApplyUrl] = useState("");
  // for tab function ends
  const [isActive, setActive] = useState(false);

  const [isElgibility, setElgibility] = useState(false);
  const [isApply, setApply] = useState(false);
  const [isFaq, setFaq] = useState(false);
  const currentLanguageCode = cookies.get('i18next') || 'en';
  const [faqs, setFAQs] = useState([]);


  const toggleClass = (n) => {
    setValue(n);
    if (!isActive) {
      setActive(!isActive);
    }
  };

  useEffect(() => {
    getData("faqs").then((data) => {
      setFAQs(data);
    });
    setApplyUrl(data?.entity?.field_url?.[0]?.value);
  }, [])


  const { t } = useTranslation(["translation", "products"]);

  if (pagename[2] === 'corporate') {
    if(applyUrl === undefined) {
      applynowurl = "/corporate-register";
    }
    else {
      applynowurl = data?.entity?.field_url?.[0]?.value;
    }
  }
  return (
    <>
      {/* Product description starts */}
      {nodeType == 'products' &&
        <>
          <section className='product-description-block'>
            <div className="container">
              {renderHTML(data?.entity?.body?.[0]?.value)}
            </div>
          </section>


          {/* Product description ends */}
          {/* Description bottom banner starts */}
          <section className='description-bottom-bar'>
            <div className='container'>
              <div className='bar-wrapper'>
                <div className='icon-with-item'>
                  <div className='single' onClick={(e) => {
                    toggleClass(0);
                  }}>
                    <div className='icon'>
                      <img src={elgibility} alt="Elgibility" />
                    </div>
                    <div className='icon-text'>
                      <span>{t("translation:Eligibility")}</span>
                    </div>
                  </div>
                  {/* <div className='single' onClick={(e) => {
                    toggleClass(1);
                  }}>
                    <div className='icon'>
                      <img src={howToApply} alt="Elgibility" />
                    </div>
                    <div className='icon-text'>
                      <span>{t("translation:How to Apply")}</span>
                    </div>
                  </div> */}
                  <div className='single' onClick={(e) => {
                    toggleClass(1);
                  }}>
                    <div className='icon'>
                      <img src={faq} alt="Elgibility" />
                    </div>
                    <div className='icon-text'>
                      <span>{t("translation:FAQ")}</span>
                    </div>
                  </div>
                </div>
                <div className='apply-online'>
                  {
                    applyUrl === undefined &&
                    <LangLink to={applynowurl}>{t("translation:Apply Online")}</LangLink>
                  }
                  {
                    applyUrl !== undefined &&
                    <a href={applynowurl}>{t("translation:Apply Online")}</a>
                  }
                </div>
              </div>
            </div>
          </section>
          {/* Description bottom banner ends */}

          <section id="tab-data-item" className={isActive ? 'section-active' : null}>
            <div className="product-details-tab page-with-right-full-bg">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-sm-3 col-page-side-menu">
                    <Tabs
                      orientation="vertical"
                      // variant="scrollable"
                      value={value}
                      onChange={handleChange}
                    >
                      <Tab icon={<ArrowIcon />} label={t("translation:Eligibility")} {...a11yProps(0)} />
                      {/* <Tab label={t("translation:How to Apply")} {...a11yProps(1)} icon={<ArrowIcon />} /> */}
                      <Tab label={t("translation:FAQ")} {...a11yProps(1)} icon={<ArrowIcon />} />
                    </Tabs>

                  </div>
                  <div className="col-12 col-sm-9 col-content page-with-right-full-bg__parent">
                    <div className='page-with-right-full-bg__content'>
                      <div className='right-block-content'>
                        <TabPanel value={value} index={0}>
                          <h1 className="product-details-eligibilty">{t("translation:Terms and Conditions")}</h1>
                          {renderHTML(data?.entity?.field_eligibility?.[0]?.value)}
                        </TabPanel>
                        {/* <TabPanel value={value} index={1}>
                          <h1 className="product-details-eligibilty">{t("translation:How to Apply")}</h1>
                          <div className='content-wrapper'>
                          { renderHTML(t("products:how_to_apply_content"))}
                          </div>
                        </TabPanel> */}
                        <TabPanel value={value} index={1}>
                          {/* fff */}

                          <h1 className="product-details-eligibilty">{t("translation:FAQ")}</h1>
                          
                            {faqs?.map((item, pos) => {

                              return (
                                <div key={pos} className="row align-items-center FAQView">
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >

                                    <Typography>{item.title}</Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Typography>
                                      {renderHTML(item.body)}
                                    </Typography>
                                  </AccordionDetails>

                                </Accordion>
                                </div>
                              )
                            }
                            )}
                          

                        </TabPanel>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </section>
        </>
      }
    </>

  );
}


export default BasicContent;