import React, { useEffect, useState, useContext } from 'react';
import { Link, NavLink, withRouter, useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import { CmsContext } from 'context/cms/CmsContext';
import { isMobile, isDesktop, isTablet } from "react-device-detect";
import i18next from 'i18next'
import cookies from 'js-cookie'
import classNames from 'classnames';

import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CallIcon from '@material-ui/icons/Call';
import PersonIcon from '@material-ui/icons/Person';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
// import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';

import LogoWhite from 'assets/images/LogoWhite';
import LogoDark from 'assets/images/LogoDark';
import config from 'config';

import LangNavLink from 'components/lang/LangNavLink';
import LangLink from 'components/lang/LangLink';
import { langSwitchUrl, langUrl } from 'services/lang';
import { AuthenticationContext } from 'context/Authentication';
import useIsServer from 'hooks/useIsServer';

require('styles/common/navbar.scss');

const languages = [
  {
    code: 'en',
    name: 'EN',
    dir: 'ltr',
  },
  {
    code: 'ar',
    name: 'العربية',
    dir: 'rtl',
  },
]
var curindex = 0;

const Navbar = (props) => {
  console.log('NAVBAR', props);
  const { cms, setCms } = useContext(CmsContext);
  const history = useHistory();
  let applynowurl = "/application/stage-01/basic-information#1";
  const location = useLocation();
  const pagename = location.pathname.split(/[/]+/);
  
  if(pagename[2] === 'corporate') {
    applynowurl = "/corporate-register";
  }
  const { i18n, t } = useTranslation();

  const {user: users} = useContext(AuthenticationContext);
  const currentLanguageCode = cookies.get('i18next') || 'ar'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  const [user, setUser] = useState(null);
  useEffect(()=> {
    setUser(users);
  }, [users])
  //themecolor toggle
  const languageClick = (e, selected) =>{
    // i18next.changeLanguage(selected);
    setCms(prevState => ({
      ...prevState,
      direction:selected === "ar" ? "rtl" : "ltr",
      language:selected ? selected : "ar",
    }));

    console.log(langUrl('some'), 'lang url');
    const toUrl = langSwitchUrl(selected, location.pathname, location.search, location.hash);
    // history.push(toUrl);
    // window.location.reload();
    window.location.href = window.location.origin + toUrl;
    // history.go(0)
    e.preventDefault();
  };

  const redirectTo = (link) => {
    const newUrl = link.replace('cms/web/', '');
    const newLangUrl = '/'+ i18n.language + newUrl;
    // alert(newLangUrl);
    window.location.href = newLangUrl;
  };

   //themecolor toggle
   const themeColorClick = (selected) =>{
    setCms(prevState => ({
      ...prevState,
      theme:selected
    }))
  }
  //fontsize toggle
  const fontSizeClick = (val) =>{
    setCms(prevState => ({
      ...prevState,
      fontSize: val
    }))
  }

  useEffect(() => {
    document.getElementsByTagName("HTML")[0].setAttribute("font-size", cms?.fontSize);
    document.getElementsByTagName("HTML")[0].setAttribute("class", cms?.theme + (!MobileIsActive ? " menu-show" : ""));
    // Run on component unmount, remove menu-show class from html dom.
    return () => {
      document.getElementsByTagName("HTML")[0].setAttribute("class", cms?.theme);
    }
  });

  //visual starts here

  //submenu toggle
  const [subMenuState, setsubMenuState] = useState();
  const toggleSubmenu = (index) => {
    console.log("toggleSubmenu")
    if(curindex===index){
      // console.log("close");
      setsubMenuState();
      curindex = 0;
    }else{
      // console.log("not close")
      setsubMenuState(index);
      curindex = index;
    }
  };

  //dropdown mobile toggle
  const [MobileIsActive, mobilesetActive] = useState("false");
  const mobileToggle = () => {
    mobilesetActive(!MobileIsActive);
  };

  //visual ends here

  const [isMenuActive, setMenuActive] = useState();

  const toggleMenuClass = (index) => {
    console.log("toggleMenuActive")
    if(curindex===index){
      setMenuActive();
      curindex = 0;
    }else{
      setMenuActive(index);
      curindex = index;
    }
  };


  useEffect(() => {
    mobilesetActive(true)
  }, [location.pathname]);

  const links = props?.block?.data?.links;
  // return (<></>);
  return(
    <nav className={
      props?.location?.pathname.includes("/application")
        ? "Navbar d-none"
        : props?.location?.pathname.includes("/dashboard")
        ? "Navbar d-none"
        : props?.location?.pathname.includes("/takaful")
        ? "Navbar d-none"
        : props?.location?.pathname.includes("/login")
        ? "Navbar d-none"
        : "Navbar"
    }>
      <div className="container">
        <LangLink to='/' className='brand-logo afsds' onClick={() => toggleSubmenu()}>
        <LogoDark/>
        </LangLink>
        <div className="mobile-icons">
          <IconButton aria-label="call" className="btn">
            <a href="tel:8001181111">
              <CallIcon />
            </a>
          </IconButton>
          <IconButton aria-label="user" className="btn">
            { user && user?.role === "bidaya_frontend_user" && 
              <LangLink to='/dashboard' onClick={() => toggleSubmenu()}>
                <PersonIcon />
              </LangLink>
            } 
            { user && user?.role === "bidaya_takaful_user" && 
              <LangLink to='/takaful/overview' onClick={() => toggleSubmenu()}>
                <PersonIcon />
              </LangLink>
            }
            { user && ( user?.role === "bidaya_redf_user" || user?.role === 'bidaya_redf_supervisor' ) && 
              <LangLink to='/redf/dashboard' onClick={() => toggleSubmenu()}>
                <PersonIcon />
              </LangLink>
            }
            {user === null &&
              <LangLink to='/login' onClick={() => toggleSubmenu()}>
                <PersonIcon />
              </LangLink>
            }
          </IconButton> 
          <IconButton aria-label="menu" onClick={mobileToggle} className={`btn menu-toggle ${MobileIsActive ? "" : "active"}`}>
            <MenuIcon className="open" />
            <CloseIcon className="close" />
          </IconButton>
        </div>  
        <div className={`menus-wr ${MobileIsActive ? "" : "active"}`}>
          <div className="main-menu">
            <ul>
              {
                links && Object.keys(links).map((i, pos) => {
                  return (!links[i]?.has_children ? 
                    
                    links[i].link.enabled &&                    
                    <li key={pos}>
                      <LangLink to={links[i]?.link?.url}  onClick={() => toggleSubmenu()} > {links[i]?.link?.title} </LangLink>
                    </li>
                    : 
                      links[i].link.enabled &&
                    <li className={`${links[i].link.description === 'mega-menu' ? 'mega-menu' : 'normal-menu-toggle'}`} key={pos}>
                      <button className={`submenu-toggle ${subMenuState === pos ? "active" : "active"}`} onClick={() => {toggleSubmenu(pos); redirectTo(links[i]?.link?.url); }}>{links[i]?.link?.title}
                      </button>
                      <span className={`mobile-plus ${isMenuActive === pos ? "menuactive" : ""}`} onClick={(e) => { toggleMenuClass(pos); }}>
                        <span className='mob-wrap'></span>
                      </span>
                      <ul className={subMenuState === pos ? "sub-menu active" : "sub-menu active"}>
                        {Object.keys(links[i]?.subtree).map((k, kpos) => {
                          return (links[i]?.subtree[k]?.link?.enabled && <li key={kpos}>
                            <LangLink to={links[i]?.subtree[k]?.link?.url}>{links[i]?.subtree[k]?.link?.title}</LangLink>
                            {Object.keys(links[i]?.subtree[k]?.subtree).map((c, cpos) => {
                              return (links[i]?.subtree[k]?.subtree[c].link?.enabled && <div key={cpos}>
                                <LangLink to={links[i]?.subtree[k]?.subtree[c]?.link?.url}>- {links[i]?.subtree[k]?.subtree[c]?.link?.title}</LangLink>

                              </div>)
                            })}
                          </li>)
                        })}

                      </ul>
                    </li>
                  )
                })
              }
              {/* <li>
                <LangNavLink to="/calculators" onClick={() => toggleSubmenu()}>{t('Calculators')}</LangNavLink>
              </li>
              <li>
                <LangNavLink to='/products' onClick={() => toggleSubmenu()}>{t('Products')}</LangNavLink>
              </li>
              <li className="mega-menu">
                <button className={`submenu-toggle ${subMenuState === 1 ? "active" : ""}`} onClick={() => toggleSubmenu(1)}>{t('About_Bidaya')}</button>
                <ul className={subMenuState === 1 ? "sub-menu active" : "sub-menu"}>
                  <li>
                    <LangNavLink to='/why-bidaya' onClick={() => toggleSubmenu()}>{t('why_bidaya')}</LangNavLink>
                  </li>
                  <li>
                    <LangNavLink to='/about-bidaya/who-we-are' onClick={() => toggleSubmenu()}>{t('who_we_are')}</LangNavLink>
                  </li>
                  <li>
                    <LangNavLink to="/about-bidaya/board-of-directors" onClick={() => toggleSubmenu()}>{t("board_dir")}</LangNavLink>
                  </li>
                  <li>
                    <LangNavLink to="/about-bidaya/executive-management" onClick={() => toggleSubmenu()} >{t("exe_man")}</LangNavLink>
                  </li>
                  <li>
                    <LangNavLink to="/about-bidaya/media" onClick={() => toggleSubmenu()} >{t("media")}</LangNavLink>
                  </li>
   
                  <li>
                    <LangNavLink to="/about-bidaya/sharia-committee" onClick={() => toggleSubmenu()}>{t("shar_com")}</LangNavLink>
                  </li>
                  <li>
                    <LangNavLink to="/about-bidaya/financial-reports" onClick={() => toggleSubmenu()}>{t("fin_rep")}</LangNavLink>
                  </li>
                </ul>
              </li>
              <li>
                <button className={`submenu-toggle ${subMenuState === 2 ? "active" : ""}`} onClick={() => toggleSubmenu(2)} >
                  {t('Contact_us')}
                </button>
                <ul className={subMenuState === 2 ? "sub-menu active" : "sub-menu"}>
                  <li>
                    <LangNavLink to='/contact-us/contact-us' onClick={() => toggleSubmenu()}>{t('contact_us')}</LangNavLink>
                  </li>
                  <li>
                    <LangNavLink to='/contact-us/customer-care' onClick={() => toggleSubmenu()}>{t('customer_care')}</LangNavLink>
                  </li>
                  <li>
                    <LangNavLink to="/contact-us/faq" onClick={() => toggleSubmenu()}>{t('faq')}</LangNavLink>
                  </li>
                  <li>
                    <LangNavLink to="/contact-us/careers" onClick={() => toggleSubmenu()}>{t('careers')}</LangNavLink>
                  </li>
                  <li>
                    <LangNavLink to="/contact-us/complaint" onClick={() => toggleSubmenu()}>{t('COMPLAINT')}</LangNavLink>
                  </li>
                </ul>
              </li> */}
            </ul>
          </div>
          <div className="secondary-menu">
            <ul>
              <li>
                <a href="tel:8001181111">
                  <CallIcon />
                  <span className="text en">8001181111</span>
                </a>
              </li>
              <li className='accessibility-menu-toggle'>
                <button className={`submenu-toggle ${subMenuState === 7 ? "active" : ""}`} onClick={() => toggleSubmenu(7)}>
                  <VisibilityIcon />
                  <span className="text">{t('Accessibility')}</span>
                </button>
                <ul className={subMenuState === 7 ? "sub-menu active" : "sub-menu"}>
                  <li className="acc acc-fonts">
                    <p className="label">{t("text_size")}</p>
                    <ul className="options">
                        <li>
                          <a className={cms?.fontSize === "small" ? "active" : ""} onClick={() => fontSizeClick("small")}>
                            <span className="text">{t("small")}</span>
                          </a>
                        </li>
                        <li>
                          <a className={cms?.fontSize === "medium" ? "active" : ""} onClick={() => fontSizeClick("medium")}>
                            <span className="text">{t("medium")}</span>
                          </a>
                        </li>
                        <li>
                          <a className={cms?.fontSize === "large" ? "active" : ""} onClick={() => fontSizeClick("large")}>
                            <span className="text">{t("large")}</span>
                          </a>
                        </li>
                      </ul>
                  </li>
                  <li className="acc acc-themes">
                    <p className="label d-none">{t("theme_color")}</p>
                    <ul className="options">
                        <li>
                          <a className={cms?.theme === "theme-light" ? "active" : ""} onClick={() => {themeColorClick("theme-light");}}>
                            <span className="text">{t("theme_color_light")}</span>
                          </a>
                        </li>
                        <li>
                          <a className={cms?.theme === "theme-dark" ? "active" : ""} onClick={() => {themeColorClick("theme-dark");}}>
                            <span className="text">{t("theme_color_dark")}</span>
                          </a>
                        </li>
                      </ul>
                  </li>
                </ul>
              </li>
              <li className="menu-group">
                <ul>
                  {languages.map(({ code, name}) => (
                  <li key={code} className={classNames('lang', { "d-none": i18n.language === code})}>
                    <a href="#" 
                      className={classNames('lang-item', { disabled: i18n.language === code},{ en: name === 'EN' },{ ar: name !== 'EN' })}
                      onClick={(e) => { languageClick(e, code) }} 
                      style={{ opacity: i18n.language === code ? 0.5 : 1, }} >
                        {name}
                    </a>
                  </li>
                  ))}
                  <li className="login">
                    { user && user?.role === "bidaya_frontend_user" && 
                        <LangLink to='/dashboard' onClick={() => toggleSubmenu()}>
                          <PersonIcon />
                          <span className="text">{t('DASHBOARD')}</span>
                        </LangLink>
                      } 
                      { user && user?.role === "bidaya_takaful_user" && 
                        <LangLink to='/takaful/overview' onClick={() => toggleSubmenu()}>
                          <PersonIcon />
                          <span className="text">{t('DASHBOARD')}</span>
                        </LangLink>
                      }
                      { user && ( user?.role === "bidaya_redf_user" || user?.role === 'bidaya_redf_supervisor' ) && 
                        <LangLink to='/redf/dashboard' onClick={() => toggleSubmenu()}>
                          <PersonIcon />
                          <span className="text">{t('DASHBOARD')}</span>
                        </LangLink>
                      }
                      {user === null &&
                        <LangLink to='/login' onClick={() => toggleSubmenu()}>
                          <PersonIcon />
                          <span className="text">{t('Login')}</span>
                        </LangLink>
                      }
                  </li>
                </ul>
              </li>
              <li>
              { user === null &&
                <LangLink to={applynowurl} className="link-appli">
                  <Button className="nav-btn" variant="contained" color="primary" size="small">
                    {t('Apply_Online')}
                  </Button>
                </LangLink>
              }
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
};

export default withRouter(Navbar);