import axios from "axios";
import storage from "services/storage";
import { langUrl } from "./lang";
import i18n from "i18n";

let unauthorizedRefreshTokenInterceptor;

let pathArr = [];
if (typeof window !== "undefined") {
  pathArr = window.location.pathname.split("/");
} else {
  pathArr[1] = i18n?.language === "en" ? "en" : "ar";
}

export const getUser = () => {
  let currUser = storage.get("bidaya_auth_user");
  let userRole = storage.get('user_role');
  if (!currUser) {
    // if no user in localStorage then the user must enter their credentials to proceed
    return Promise.resolve(null);
  }

  // get the expiry time of the current access token and measure whether it expired or not
  let currDate = new Date();
  let duration = currUser["expires_in"] * 1000;
  let diff = currDate.getTime() - currUser.lastRefresh;

  if (diff >= duration) {
    // access token expired need to refresh token
    return refreshToken()
      .then(response => {
        currUser.refresh_token = response.data.refresh_token;
        currUser.access_token = response.data.access_token;
        currUser.lastRefresh = new Date().getTime();

        storage.set("bidaya_auth_user", currUser);
        axios.defaults.headers.common["Authorization"] = "Bearer " + currUser.access_token;
         let data = {
            "__misc": {
                "step": "check_dashboard"
            }
          };
        return accountApi(data).catch(error => {
          if(userRole === 'bidaya_takaful_user') {
            logoutTakaful(true);
          } else if(userRole === 'bidaya_redf_user' || userRole === 'bidaya_redf_supervisor') {
            logoutRedf(true)
          } else if(userRole === 'bidaya_campaign_user') {
            logoutCampaign(true);
          } else if(userRole === "bidaya_appraisal_user") {
            logoutAppraisal(true)
          } else {
          logout();
          }
          throw error;
        });
      })
      .catch(error => {
        if(userRole === 'bidaya_takaful_user') {
          logoutTakaful(true);
        } else if(userRole === 'bidaya_redf_user' || userRole === 'bidaya_redf_supervisor') {
          logoutRedf(true)
        } else if(userRole === 'bidaya_campaign_user') {
          logoutCampaign(true);
        } else if(userRole === "bidaya_appraisal_user") {
          logoutAppraisal(true)
        } else {
        logout();
        }
      });
  } else {
    // Do not need refresh
    let currtoken = storage.get("bidaya_auth_user");
     if(currtoken &&  currtoken.access_token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + currtoken.access_token;
     }
    
     addUnauthorizedRefreshTokenInterceptor();

    let data = {
      "__misc": {
          "step": "check_dashboard"
      }
    };
    return accountApi(data).catch(error => {
      if(userRole === 'bidaya_takaful_user') {
        logoutTakaful(true);
      } else if(userRole === 'bidaya_redf_user' || userRole === 'bidaya_redf_supervisor') {
        logoutRedf(true)
      } else if(userRole === 'bidaya_campaign_user') {
        logoutCampaign(true);
      } else if(userRole === "bidaya_appraisal_user") {
        logoutAppraisal(true)
      } else {
      logout();
      }
      throw error;
    });
  }
};

export const login = (email, password) => {

  let data = {
    "__misc": {
      "step": "loginemail"
    },
    "loginemail": {
      "username": email,
      "password": password
    }
  }

  delete axios.defaults.headers.common["Authorization"];
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/login/request?_format=json`, data)
      .then(response => {
        if(response?.data?.access_token) {
          response.data.lastRefresh = new Date().getTime();
          storage.set("bidaya_auth_user", response.data);
          getUser()
            .then(response => {
              resolve(response);
            })
            .catch(error => {
              reject(error);
            });
        }
        else {
          reject(response);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const loginNationalID = (nationalID, password) => {
  let data = {
    "__misc": {
      "step": "login"
    },
    "login": {
      "national_id": nationalID,
      "password": password
    }
  }
  delete axios.defaults.headers.common["Authorization"];
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/login/request?_format=json`, data)
      .then(async response => {
        if(response?.data?.access_token) {
        response.data.lastRefresh = new Date().getTime();
        storage.set("bidaya_auth_user", response.data);
        getUser()
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
        } else {
          reject(response);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const loginVerify = (username, password, id_type) => {
  let data = {
    "__misc": {
      "step": "loginotp"
    },
    "loginotp": {
      "username": username,
      "password": password,
      "id_type": id_type
    }
  }
  delete axios.defaults.headers.common["Authorization"];
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/login/request?_format=json&language=${
        pathArr[1] === "en" ? "en" : "ar"
      }`, data)
      .then(async response => {
        if(response?.data?.status) {
          resolve(response);
        } else {
          reject(response);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const loginOTP = (username, otp, id_type, pwd) => {
  let data = {
    "__misc": {
      "step": "loginotp_verify"
    },
    "loginotp_verify": {
      "username": username,
      "otp": otp,
      "id_type": id_type,
      "password": pwd
    }
  }
  delete axios.defaults.headers.common["Authorization"];
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/login/request?_format=json`, data)
      .then(async response => {
        if(response?.data?.access_token) {
        response.data.lastRefresh = new Date().getTime();
        //console.log(response.data);
        storage.set("bidaya_auth_user", response.data);
        getUser()
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
        } else {
          reject(response);
        }
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const refreshToken = () => {
  let currUser = storage.get("bidaya_auth_user");
  let data = {
    "__misc": {
      "step": "refresh"
    },
    "refresh": {
      "refresh_token": currUser.refresh_token,
    }
  }

  delete axios.defaults.headers.common["Authorization"];
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/login/request?_format=json`, data)
      .then(async response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const logout = () => {
  storage.remove("bidaya_auth_user");
  // Remove refresh token interceptor when logout.
  removeUnauthorizedRefreshTokenInterceptor();
  delete axios.defaults.headers.common["Authorization"];
  if(localStorage.getItem('device') == "nativeapp")
  {
    window.location = langUrl("login");
  }
  else {
    window.location = langUrl("");
  }  
  return Promise.resolve();
};

export const logoutTakaful = (refresh=false) => {
  storage.remove("bidaya_auth_user");
  // Remove refresh token interceptor when logout.
  removeUnauthorizedRefreshTokenInterceptor();
  delete axios.defaults.headers.common["Authorization"];
  if(refresh) {
    storage.remove('user_role');
    window.location = langUrl("takaful/login");
  }
  return Promise.resolve();
}

export const logoutRedf = (refresh=false) => {
  storage.remove("bidaya_auth_user");
  // Remove refresh token interceptor when logout.
  removeUnauthorizedRefreshTokenInterceptor();
  delete axios.defaults.headers.common["Authorization"];
  if(refresh) {
    storage.remove('user_role');
    window.location = langUrl("redf/login");
  }
  return Promise.resolve();
}

export const logoutCampaign = (refresh=false) => {
  storage.remove("bidaya_auth_user");
  // Remove refresh token interceptor when logout.
  removeUnauthorizedRefreshTokenInterceptor();
  delete axios.defaults.headers.common["Authorization"];
  if(refresh) {
    storage.remove('user_role');
    window.location = langUrl("campaign-report/login");
  }
  return Promise.resolve();
}

export const logoutAppraisal = (refresh=false) => {
  storage.remove("bidaya_auth_user");
  removeUnauthorizedRefreshTokenInterceptor();
  delete axios.defaults.headers.common["Authorization"];
  if(refresh) {
    storage.remove('user_role');
    window.location = langUrl("appraisal/login")
  }
  return Promise.resolve();
}

export const accountApi = (requestData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/account/request?_format=json&language=${
        pathArr[1] === "en" ? "en" : "ar"
      }`, JSON.stringify(requestData))
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}
export const loginAPI = (requestData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/login/request?_format=json&language=${
        pathArr[1] === "en" ? "en" : "ar"
      }`, JSON.stringify(requestData))
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export const nafathAPI = (requestData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/nafath/request?_format=json&language=${
        pathArr[1] === "en" ? "en" : "ar"
      }`, JSON.stringify(requestData))
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export const forgotPasswordApi = (requestData) => {
  const headers = {
    'Content-Type': 'application/json'
  }
  return new Promise((resolve, reject) => {
    axios
      .post(`/user/lost-password?_format=json&language=${
        pathArr[1] === "en" ? "en" : "ar"
      }`, JSON.stringify(requestData), {
        headers: headers
      })
      .then(async response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export const resetPasswordApi = (requestData) => {
  const headers = {
    'Content-Type': 'application/json'
  }
  return new Promise((resolve, reject) => {
    axios
      .post(`/user/lost-password-reset?_format=json`, JSON.stringify(requestData), {
        headers: headers
      })
      .then(async response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export let removeUnauthorizedRefreshTokenInterceptor = () => {
  axios.interceptors.response.eject(unauthorizedRefreshTokenInterceptor);
}

export let addUnauthorizedRefreshTokenInterceptor = () => {
  let currUser = storage.get("bidaya_auth_user");
  let userRole = storage.get("user_role");
  unauthorizedRefreshTokenInterceptor = axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      const urlArr = error.config.url.split('?');
      if (error?.response?.status === 401 && urlArr?.[0] !== "/api/login/request") {
        // 401 is Unauthorized error
        // which means that this request failed
        // what we need to do is send a refresh request then resend the same request
        // that failed but with the new access point.
        // We can do this automatically using axios interceptors
        return refreshToken()
          .then(response => {
            currUser.refresh_token = response.data.refresh_token;
            currUser.access_token = response.data.access_token;
            currUser.lastRefresh = new Date().getTime();
            storage.set("bidaya_auth_user", currUser);

            // Set default headers to have authorization the access token as authorization for future requests
            axios.defaults.headers.common["Authorization"] = "Bearer " + response.data.access_token;

            // Get the original that failed due to 401 and resend it
            // with the new access token
            const config = error.config;
            config.headers.Authorization = "Bearer " + response.data.access_token;

            // Resending original request
            return new Promise((resolve, reject) => {
              axios
                .request(config)
                .then(response => {
                  resolve(response);
                })
                .catch(error => {
                  reject(error);
                });
            });
          })
          .catch(error => {
            // if refresh token failed logout
            Promise.reject(error);
            if(userRole === 'bidaya_takaful_user') {
              logoutTakaful(true);
            } else if(userRole === 'bidaya_redf_user' || userRole === 'bidaya_redf_supervisor') {
              logoutRedf(true)
            } else if(userRole === 'bidaya_campaign_user') {
              logoutCampaign(true);
            } else if(userRole === "bidaya_appraisal_user") {
              logoutAppraisal(true)
            } else {
            logout();
            }
          });
      }
      //logout();
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  );
}

export const TakafulRequest = (requestData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/takaful/request?_format=json`, JSON.stringify(requestData))
      .then(async response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export const AppraisalRequest = (requestData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/appraisal/request?_format=json`, JSON.stringify(requestData))
      .then(async response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export const SearchDashboardApi = (requestData,data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/account/request?_format=json${requestData}&language=${
        pathArr[1] === "en" ? "en" : "ar"
      }`,JSON.stringify(data))
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export const CampaignList = (query="") => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/api/campaign/request?_format=json${query}&language=${
          pathArr[1] === "en" ? "en" : "ar"
        }`
      )
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const campaignApi = (requestData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`/api/campaign/request?_format=json&language=${
        pathArr[1] === "en" ? "en" : "ar"
      }`, JSON.stringify(requestData))
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};