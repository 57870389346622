import { createTheme } from '@material-ui/core/styles';
import Globals from 'context/cms/globals';

const theme = createTheme({
  direction: "ltr",
  breakpoints:{
    values:{
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1680
    }
  },
  palette: {
    type: "light",
    background: {
      default: "#fff",
      whiteshade: "#F5F6F8",
    },
    primary: {
      main: "#00A7E1",
      contrastText: "#fff",
    },
    secondary: {
      main: "#E81F76",
      contrastText: "#fff",
    },
    whitecustom: {
      main: "#fff",
      contrastText: "#fff",
    },
    contrastThreshold:3,
  },
  gradient:{
    en:"linear-gradient(90deg, #E21A74 0%, #497ABE 52%, #1997D5 68%, #00A7E1 100%)",
    ar:"linear-gradient(90deg, #00A7E1 0%, #1997D5 32%, #497ABE 48%, #E21A74 100%)",
  },
  typography: {
    fontFamily: 'inherit',
  },
  shape: {
    borderRadius: 5
  },
});

theme.props = {
  MuiButton: {
    disableElevation: true,
  },
};

theme.overrides = {
  MuiButton: {
    root: {
      borderRadius: 5,
      textTransform: 'none',
      
    },
    containedPrimary: {
      letterSpacing: '0.78px',
      // '&:hover': {
      //   backgroundColor: theme.palette.secondary.main,
      //   color: theme.palette.primary.dark,
      // },
      '&:hover': {
        color: theme.palette.primary.contrastText,
      },
    },
    containedSecondary: {
      letterSpacing: '0.78px',
      // fontWeight: 700,
      '&:hover': {
        color: theme.palette.secondary.contrastText,
      },
    },
    containedSizeSmall:{
      padding: '8px 22px'
    },
    contained:{
      padding: '8px 40px'
    }
  },
  MuiTable: {
    root:{
      border:"1px solid #6F7271"
    }
  },
  MuiPaper:{
    root:{
      color:"#3C3C3C"
    }
  }
};

export default theme;