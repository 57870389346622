import React,{ useContext } from "react";

require("styles/components/common/page-title.scss");


const PageTitle = ({ title, subtitle, ...otherProps }) => {

  return (
    <header className="page-title">
      <div className="container">
        <div className="row">
          <div className="col-12 col-title">
            <h2 className="sub-title">
              {subtitle}
            </h2>
            <h1 className="title">
              {title}
            </h1>
          </div>
        </div>
      </div>
    </header>
  );
}

export default PageTitle;