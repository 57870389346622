import React, { lazy,useContext,useState, useRouter } from "react";
import { Link, withRouter, useRouteMatch,  Redirect } from "react-router-dom";
import { useTranslation } from 'react-i18next'

import { Formik, Form } from "formik";
import * as Yup from "yup";

import ButtonSubmit from 'application/Components/FormsUI/Button';
import Textfield from 'application/Components/FormsUI/Textfield';
import { Button, IconButton, InputAdornment } from "@material-ui/core";

import { langUrl } from 'services/lang';
import { AuthenticationContext } from "context/Authentication";
import { useDialogBox } from "context/DialogBoxService";
import logo from "assets/images/bidaya-logo.svg";
import LogoDark from "assets/images/LogoDark";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import LangLink from "components/lang/LangLink";
import storage from "services/storage";
import Loader from "dashboard/components/loader";

const LinearIndeterminate = lazy(() =>
  import("application/Components/FormsUI/LinearProgressBar"),
);

require('styles/common/navbar.scss');

const INITIAL_FORM_STATE = {
  password: '',
  email: '',
};

const FORM_VALIDATION = (t) => {
  return Yup.object().shape({
    email: Yup.string()
    .required(t('validation:FIELD_REQUIRED_CUST', {field_label: t('common:EMAIL_ADDRESS')}))
    .email(t("validation:EMAIL_INVALID")),
    password: Yup.string()
      .required(t("validation:PWD_REQUIRED")),
  });
}

const RedfLogin = (props) => {
 
  let { url } = useRouteMatch();
  const {history} = props;

  const { t } = useTranslation(["dashboard", "common", "validation", "translation"]);
  const [isLoading, setIsLoading] = useState(false);
  const { user, userlogin, normallogin, getUserDet } = useContext(AuthenticationContext);
  const showDialogBox = useDialogBox();
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  let path = langUrl(`redf/dashboard`);
  if (user && props?.location?.state?.from) {
    path = props?.location?.state?.from;
  }
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (values) => {

    setIsLoading(true);
    normallogin(values.email, '', values.password, 'email', '', 'password')
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        
        showDialogBox({
          variant: "info",
          catchOnCancel: true,
          title: "",
          description: t("translation:invalid_credentials"),
        })
          .then(() => console.log("error"))
          .catch(() => console.log("error"));
        setIsLoading(false);
        return false;
      })
  }
  const bidaya_auth_user = storage.get("bidaya_auth_user");
  const user_role = storage.get("user_role");
  if (bidaya_auth_user && (user_role === "bidaya_redf_user" || user_role === 'bidaya_redf_supervisor' )) {
    return (
      <>
      { user && (user_role === "bidaya_redf_user" || user_role === 'bidaya_redf_supervisor' ) ?  <Redirect to={path} /> : null}
        <Loader />
      </>
    );
  }

  return (
    <>
      { user && (user_role === "bidaya_redf_user" || user_role === 'bidaya_redf_supervisor' ) ?  <Redirect to={path} /> : null}
      <div className="takaful-wr">  
      <nav className="Navbar">
        <div className="container justify-content-center">
        <LangLink to='/' className='brand-logo afsds' onClick={() => toggleSubmenu()}>
        <LogoDark/>
        </LangLink>    
        </div>
        { isLoading && <LinearIndeterminate></LinearIndeterminate> }
      </nav>
      <div className="step-wr login-wr">
        <div className="container">
          <div className="row align-items-center flex-column">
            <div className="col-12 col-sm-4">
              <h1 className="title">
              {t("LOGIN")}
              </h1>
            </div>
            <div className="col-12 col-sm-4">
              <Formik
                initialValues={{
                  ...INITIAL_FORM_STATE
                }}
                validationSchema={FORM_VALIDATION(t)}
                onSubmit={handleSubmit}
              >
                <Form>
                  <div className="row row-narrow">

                    <div className="col-12">
                      <div className="field-wr">
                        <Textfield name="email" label={t("common:EMAIL_ADDRESS")} />
                      </div>
                      <div className="field-wr">
                      <Textfield name="password" type="password" label={t("common:ENTER_PWD")}  
                        type={showPassword ? "text" : "password" }
                        InputProps={{ maxLength: 16, endAdornment: <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment> }}/>
                      </div>
                    </div>
                    
                  </div>

                  <div className="row row-btn-submit">
                    <div className="col-12 d-flex justify-content-center">
                      <div className="btn-wr">
                        <ButtonSubmit variant="contained" color="primary" size="large" className="btn-submit">
                        {t("dashboard:PROCEED")}
                        </ButtonSubmit>
                      </div>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default RedfLogin;





