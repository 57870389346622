import React, { useEffect } from "react";
import { TextField } from "@material-ui/core";
import { useField, useFormikContext } from "formik";
import Autocomplete from "@material-ui/lab/Autocomplete";
import InputLabel from "@material-ui/core/InputLabel";
import { useTranslation } from 'react-i18next';

const AutoSelect = ({
  name,
  label,
  options = [],
  setSelectValue,
  required = false,
  ...otherProps
}) => {
  const { setFieldValue, submitCount } = useFormikContext();
  const { t,i18n } = useTranslation(["validation"]);
  const [field, meta] = useField(name);
  meta.touched = meta.touched || submitCount > 0;

  useEffect(() => {
    setFieldValue(name, field?.value);
  }, [field?.value]);

  useEffect(() => {
    if (setSelectValue !== undefined && field?.value) {
      setSelectValue(field?.value);
    }
  }, [field?.value]);

  const handleChange = (evt, value) => {
    setFieldValue(name, value?.field_generic_id);
    if (setSelectValue !== undefined) {
      setSelectValue(value?.field_generic_id);
    }
  };

  const configSelect = {
    // ...field,
    ...otherProps,
    fullWidth: true,
    onChange: handleChange,
  };

  const configText = {
    fullWidth: true,
  };

  if (meta && meta.touched && meta.error) {
    configText.error = true;
    configText.helperText = meta.error;
  }
  let defaultValue = {};
  if (options?.length > 0 && Array.isArray(options)) {
    defaultValue = options.find((el) => el.field_generic_id === field?.value);
    if (defaultValue === undefined) {
      defaultValue = "";
    }
  }
  
  return (
    <>
      {/* <InputLabel required={required} htmlFor={label} shrink>
        {label}
      </InputLabel> */}
      <Autocomplete
        id={name}
        autoHighlight
        disableClearable
        disablePortal
        value={defaultValue}
        noOptionsText={t('validation:NO_OPTIONS')}
        options={options?.length > 0 && Array.isArray(options) ? options : []}
        getOptionLabel={(option) => (option.name ? option.name : "")}
        {...configSelect}
        renderInput={(params) => (
          <TextField {...configText} name={name} placeholder={label} {...params} inputProps={{
            ...params.inputProps,
            autoComplete: 'off', // To disable autofill/suggestions in browser - https://material-ui.com/components/autocomplete/#autocomplete-autofill
          }}/>
        )}
      />
    </>
  );
};

export default AutoSelect;
