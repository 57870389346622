import React from 'react';
import { Button } from '@material-ui/core';
import { useFormikContext } from 'formik';

const ButtonSubmit = ({
  children,
  className,
  ...otherProps
}) => {
  const { submitForm } = useFormikContext();

  const handleSubmit = () => {
    submitForm();
  }
  
  const configButton = {
    className: className !== undefined ? "btn-submit "+className : "btn-submit",
    variant: 'contained',
    color: 'primary',
    size: 'large',
    ...otherProps,
    onClick: handleSubmit
  }

  return (
    <Button
      {...configButton}
    >
      {children}
    </Button>
  );
};

export default ButtonSubmit;
