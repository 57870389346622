
var dataLayer = [];
if(typeof window !== 'undefined') {
 dataLayer = window.dataLayer = window.dataLayer || [];
}
const analytics = {
    
    trackPage: (values) => {
        dataLayer.push(values);
    },

    trackEvent: (event, values) => {
        dataLayer.push({
            event: event,
            ...values
        });
    }
}

export default analytics;