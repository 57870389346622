

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';

import { isMobile, isMobileOnly } from "react-device-detect";
import { elementScrollIntoView } from "seamless-scroll-polyfill";

//carousel
import SlickHeroHome from 'cms/blocks/carousel/SlickHeroHome';
//forms
import Enquiries from 'cms/components/Forms/enquires';
import Applications from 'cms/components/Forms/applications';

import globalUseStyles from 'theme/GlobalStyles';
import { Helmet } from 'react-helmet';
require("styles/components/banner/banner-home.scss");
require("styles/components/common/facelift.scss");

const HomeHeader = (props) => {

  const { t } = useTranslation();
  const gClasses = globalUseStyles()
  const [expanded, setExpanded] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if(isMobileOnly){
      if(isExpanded){
        setTimeout(() => {
          elementScrollIntoView(event.target, { behavior: "smooth", block: "start" });
        }, 380);
      }
    }
  };
  const closeForm = () => {
    setExpanded(false);
  }

  const [isTapped, setIsTapped] = useState(false);

  // const handleTouchStart = () => {
    // if (!isTapped) {
    //   setIsTapped(true);
    // }else{
    //   setIsTapped(false);
    // }
  // };

  // const handleTouchEnd = () => {
  //   if (isTapped) {
  //     setIsTapped(false);
  //   }
  // };
  // const handleDoubleClick = () => {
  //   alert('onDoubleClick event handled!');
  // };

  // const className = isTapped ? 'tapped' : '';

  const [taps, setTaps] = useState(0);

  const handleTouchStart = () => {
    setTaps(taps + 1);
  };

  const handleTouchEnd = () => {
    if (taps === 1) {
      // Single tap detected
    } else if (taps === 2) {
      // Double tap detected
      
      if (!isTapped) {
        setIsTapped(true);
        document.getElementById("bannerToutchHandle").classList.add("banner-zoom");
      }else{
        setIsTapped(false);
        document.getElementById("bannerToutchHandle").classList.remove("banner-zoom");
      }
    }
    setTimeout(() => {
      setTaps(0);
    }, 300);
  };

  return (
    <>
    <header className="header intro intro-home" >
      <div id="bannerToutchHandle" className='banner-wrap-handle' onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
        <SlickHeroHome data={props.block.data} />
      </div>
      {/*  !isMobileOnly &&
      
        <div className="container container-forms">
          <div className="row justify-content-end">
            <div className="col-12 col-sm-5 col-forms">
            <div className='bannerCTAs home-banner-cta'>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} elevation={0} className={gClasses.accordform}>
                <AccordionSummary aria-controls="applications-content" id="applications-header" className={gClasses.accordLabelSecondary}>
                { t("BANNER_HOME_CHECK_APPLICATION_STATUS") }
                </AccordionSummary>
                <AccordionDetails>
                  <span className="form-close" onClick={closeForm}>&times;</span>
                  <Applications onChange={handleChange('panel2')}/>
                </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} elevation={0} className={gClasses.accordform} square={false}>
                    <AccordionSummary aria-controls="enquiries-content" id="enquiries-header" className={gClasses.accordLabelPrimary}>
                    { t("BANNER_HOME_FOR_ENQUIRIES") }
                    </AccordionSummary>
                    <AccordionDetails>
                    <span className="form-close" onClick={closeForm}>&times;</span>
                    <Enquiries onChange={handleChange('panel1')}/>
                    </AccordionDetails>
                </Accordion>
            </div>
            </div>
          </div>
        </div>  
  */}
      
    </header>
    </>
  );
}

export default HomeHeader;