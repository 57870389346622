import React from "react";
import { Dialog } from "@material-ui/core";
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
require('styles/components/dashboard/dashboard-dialog.scss');


const EMPTY_FUNC = () => {};
const DialogContext = React.createContext([
  EMPTY_FUNC,
  EMPTY_FUNC
]);
export const useDialog = () => React.useContext(DialogContext);

function DialogContainer(props) {
  const { children, open, onClose, onKill, closeIcon } = props;
  return (
    <Dialog open={open} onClose={onClose} className="dialog-common" maxWidth={props.dialogSize !== undefined ? props.dialogSize : "sm"} fullWidth>
       <DialogContent>
          { 
            closeIcon && 
              <> 
                <div className="btn-close-wr" >
                  <IconButton aria-label="close" onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                </div> 
              </> 
          }
          <div className="row dialog-content">
            <div className="col-12">
              {children}
            </div>
          </div>
        </DialogContent>

        <DialogActions className="justify-content-center">
          {/* {btnYes !== undefined && (
            <Button color="primary" variant="contained" onClick={() => {
                if (onSubmit) {
                  onSubmit();
                }
                close();
              }}
            >
              {btnYes}
            </Button>
          )}
          {btnNo !== undefined && (
            <Button color="secondary" variant="contained" onClick={close}>
              {btnNo}
            </Button>
          )} */}
        </DialogActions>
           
    </Dialog>
  );
}

export default function DialogProvider({ children }) {
  const [dialogs, setDialogs] = React.useState([]);
  const createDialog = (option) => {
    const dialog = { ...option, open: true };
    setDialogs((dialogs) => [...dialogs, dialog]);
  };
  const closeDialog = () => {
    setDialogs((dialogs) => {
      const latestDialog = dialogs.pop();
      if (!latestDialog) return dialogs;
      if (latestDialog.onClose) latestDialog.onClose();
      return [...dialogs].concat({ ...latestDialog, open: false });
    });
  };
  const contextValue = React.useRef([createDialog, closeDialog]);

  return (
    <DialogContext.Provider value={contextValue.current}>
      {children}
      {dialogs.map((dialog, i) => {
        const { onClose, closeIcon, ...dialogParams } = dialog;
        const handleKill = () => {
          if (dialog.onClose) dialog.onClose();
          setDialogs((dialogs) => dialogs.slice(0, dialogs.length - 1));
        };

        return (
          <DialogContainer
            key={i}
            onClose={closeDialog}
            onKill={handleKill}
            closeIcon={closeIcon === false ? false : true}
            {...dialogParams}
          />
        );
      })}
    </DialogContext.Provider>
  );
}
