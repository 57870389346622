import axios from "axios";
import i18n from "i18n";

let pathArr = [];
if (typeof window !== "undefined") {
  pathArr = window.location.pathname.split("/");
} else {
  pathArr[1] = i18n?.language === "en" ? "en" : "ar";
}
export const getMaster = (key, one = true) => {
  const url = one ? `/api/mdata/${key}?_format=json&language=${pathArr[1] === "en" ? "en" : "ar"}`
  : `/api/mdata/${key}?_format=json`;
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then(async (response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getFilterMaster = (key, value) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/api/mdata/${key}/${value}?_format=json&language=${
          pathArr[1] === "en" ? "en" : "ar"
        }`
      )
      .then(async (response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSectorSubsectorFromCompany = (companyID) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `/api/mdata/?_format=json&op=company_sector&params=${companyID}&language=${
          pathArr[1] === "en" ? "en" : "ar"
        }`
      )
      .then(async (response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getData = (key, one = true) => {
  const url = one
    ? `/api/${key}?_format=json&language=${
        pathArr[1] === "en" ? "en" : "ar"
      }`
    : `/api/${key}?_format=json`;
  return new Promise((resolve, reject) => {
    axios
      .get(url)
      .then(async (response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};