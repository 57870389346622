import axios from "axios";
import i18n from "i18n";

let pathArr = [];
if (typeof window !== "undefined") {
  pathArr = window.location.pathname.split("/");
} else {
  pathArr[1] = i18n?.language === "en" ? "en" : "ar";
}

const calculator = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `/api/calculator/request?_format=json&language=${
          pathArr[1] === "en" ? "en" : "ar"
        }`,
        data
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export default calculator;
