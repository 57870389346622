import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';

import cookies from 'js-cookie';

import { withTheme } from './theme/theme';
import { Paper } from '@material-ui/core';
import axios from 'axios';

import Routes from "./Routes";
import config from "./config"

import { getToken } from "services/session";
import storage from "services/storage";
import ScrollToTop from "ScrollToTop";
import Loader from 'dashboard/components/loader';
import LoaderBlank from "components/uihelper/LoaderBlank";
import i18n from "i18n";


let pathArr = [];
if (typeof window !== "undefined") {
  pathArr = window.location.pathname.split('/');
} else {
  pathArr[1] = i18n?.language === 'en' ? 'en' : 'ar';
}
// Request interceptor for API calls
axios.interceptors.request.use(
  config => {
    if (config.method === 'post') {
      if (config.url.indexOf('/api') === 0)
        config.headers['Content-Type'] = 'application/json';

      config.headers['X-CSRF-Token'] = storage.get('XCSRF');
    }

    if (config.url.indexOf('_format') > -1) {
      //config.url = config.url + '&language=' + (pathArr[1] === 'en' ? 'en' : 'ar');
    }
    // console.info('i18n', i18n.language);
    // console.info('interceptor', config);
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

const SsrCompatibleSuspense = process.browser ? Suspense : props => props.children;

const App = () => {

  useEffect(() => {
    //Get XCSRF token
    getToken().then((response) => {
      storage.set('XCSRF', response);
      cookies.set('token', response);
    });
    return () => {
      window.addEventListener("beforeunload", (e) => {
        e.preventDefault();
        cookies.remove("token");
      });
      cookies.remove("token");
    }
  }, []);

  return (
    <FpjsProvider
      loadOptions={{
        apiKey: config.fingerprintAPI,
        region: "ap"
      }}
    >
      <Paper elevation={0} >
        <Switch>
          <ScrollToTop >
            <SsrCompatibleSuspense fallback={<LoaderBlank />} >
              <Route path="/" exact>
                <Redirect to="/ar" />
              </Route>
              <Route path="/:lang">
                <Routes />
              </Route>
            </SsrCompatibleSuspense>
          </ScrollToTop>
        </Switch>
      </Paper>
    </FpjsProvider>
  );
}

export default withTheme(App);