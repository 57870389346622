import React, { lazy,useContext,useState,useEffect } from "react";
import { Link, withRouter, useRouteMatch, Redirect } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import axios from "axios";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import i18n from "i18n";

import ButtonSubmit from 'application/Components/FormsUI/Button';
import Textfield from 'application/Components/FormsUI/Textfield';
import { Button, IconButton, InputAdornment } from "@material-ui/core";
import { isMobile, isDesktop } from "react-device-detect";

import { langUrl } from 'services/lang';
import { AuthenticationContext } from "context/Authentication";
import { useDialogBox } from "context/DialogBoxService";
import logo from "assets/images/bidaya-logo.svg";
import LogoWhite from 'assets/images/LogoWhite';
import LogoDark from 'assets/images/LogoDark';
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import LangLink from "components/lang/LangLink";
import storage from "services/storage";
import Loader from "dashboard/components/loader";
import FormLabel from '@material-ui/core/FormLabel';

const LinearIndeterminate = lazy(() =>
  import("application/Components/FormsUI/LinearProgressBar"),
);

require('styles/common/navbar.scss');

const INITIAL_FORM_STATE = {
  password: '',
  email: '',
  passwordType: 'password',
  otpfield: '',
  otpStatus: '',
  username: '',
};

const FORM_VALIDATION = (t) => {
  return Yup.object().shape({
    email: Yup.string()
    .required(t("validation:EMAIL_REQUIRED"))
    .email(t("validation:EMAIL_INVALID")),
    password: Yup.string()
      .required(t("validation:PWD_REQUIRED")),
  });
}

const TakafulLogin = (props) => {
 
  let { url } = useRouteMatch();
  const { t } = useTranslation(["dashboard", "common", "validation", "translation"]);
  const [isLoading, setIsLoading] = useState(false);
  const { user, userlogin, otplogin, getUserDet } = useContext(AuthenticationContext);
  const showDialogBox = useDialogBox();
  const [showPassword, setShowPassword] = useState(false);
  const [loginType, setLoginType] = useState('email');
  const [passwordType, setPasswordType] = useState('password');
  const [otpStatus, setotpStatus] = useState('');
  const [mainusername, setMainusername] = useState('');
  const [pwd, setPwd] = useState('');
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getErrorMessage = (loginType, error) => {
    let msg = "";
    if (error?.response?.status === 429) {
      msg = t('validation:TOO_MANY_REQUESTS');
    }
    else if (error?.data?.messages === 'USER_NOT_FOUND') {
      msg = t('validation:USER_NOT_FOUND');
    }
    else if(error?.data?.messages === 'OTP_NOT_FOUND')
    {
      msg = t('validation:OTP_NOT_FOUND');
    }
    else if(loginType === 'nationalId') { 
      msg = t("translation:invalid_credentials_national_id"); 
    }
    else {
      msg = t("translation:invalid_credentials");
    }
    return msg;
  }

  const getOTPMessage = (error) => {
    let msg = "";
    let errorMessages = {
      429: t('validation:TOO_MANY_REQUESTS'),
      'USER_NOT_FOUND': t('validation:USER_NOT_FOUND'),
      'OTP_SUCCESS': t('validation:OTP_SUCCESS'),
      'OTP_ERROR': t('validation:OTP_ERROR'),
      'OTP_LIMIT': t('validation:OTP_LIMIT'),
      'OTP_EXPIRED': t('validation:OTP_EXPIRED'),
      'OTP_NOT_FOUND': t('validation:OTP_NOT_FOUND')
    }
    msg = errorMessages[error] ?? '';
    return msg;
  }

  const handleSubmit = (values) => {
    userlogin(values.email, '', values.password, 'email', values.otpfield, passwordType)
    .then((res) => {
      const uname = values.email;
      setMainusername(uname);
      setPwd(values.password);
      setIsLoading(false);
      setPasswordType('otp');
      setotpStatus('sent');
      reset();
    })
    .catch((error) => {        
      showDialogBox({
        variant: "info",
        catchOnCancel: true,
        title: "",
        description: getErrorMessage('email', error),
      })
        .then(() => console.log("error"))
        .catch(() => console.log("error"));
      setIsLoading(false);
      return false;
    })
  }

  const handleOTPResend = () => {
    setIsLoading(true);
    let data = {
      "__misc": {
        "step": "otp_request"
      },
      "otp_request": {
        "username": mainusername,
        "id_type": 'email',
        "type": 'main'
      }
    }
    return new Promise((resolve, reject) => {
      axios
        .post(`/api/otp/request?_format=json&language=${
          pathArr[1] === "en" ? "en" : "ar"
        }`, data)
        .then((response) => {
          resolve(response?.data);
          setIsLoading(false);
          if(response.data.status == true) {
            setotpStatus('sent');
            reset();
          }
          else {
            showDialogBox({
              variant: "info",
              catchOnCancel: true,
              title: "",
              description: getOTPMessage(response.data.messages),
            })
          }
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          reject(error);
          showDialogBox({
            variant: "info",
            catchOnCancel: true,
            title: "",
            description: getOTPMessage(error?.response?.status),
          })
        });
    });
  }

  const handleOTP = () => {
    setIsLoading(true);
    const otpvalue = document.getElementById("otp_field").value;
    otplogin(mainusername, otpvalue, loginType, pwd)
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {    
        console.log(loginType);
        console.log(error);
        showDialogBox({
          variant: "info",
          catchOnCancel: true,
          title: "",
          description: getErrorMessage(loginType, error),
        })
          .then(() => console.log("error"))
          .catch(() => console.log("error"));
        setIsLoading(false);
        return false;
      })
  }

  const [seconds, setSeconds] = useState(179);
  const [isActive, setIsActive] = useState(false);
  const formattedTime = secondsToMinutesSeconds(seconds);

  function reset() {
    setSeconds(179);
    setIsActive(true);
  }

  function secondsToMinutesSeconds(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
    return `${minutes}:${formattedSeconds}`;
  }

  useEffect(() => {
    let interval = null;
    if (isActive) {
      interval = setInterval(() => {
        setSeconds(seconds => seconds - 1);
      }, 1000);
      if(seconds === 0)
      {
        clearInterval(interval);
      }
    } 
    else if (!isActive && seconds === 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  let path = langUrl(`takaful/overview`);
  if (user && props?.location?.state?.from) {
    path = props?.location?.state?.from;
  }

  const bidaya_auth_user = storage.get("bidaya_auth_user");
  const user_role = storage.get("user_role");
  if (bidaya_auth_user && user_role === "bidaya_takaful_user") {
    return (
      <>
      { user && user?.role === "bidaya_takaful_user" ?  <Redirect to={path} /> : null}
        <Loader />
      </>
    );
  }  

  return (
    <>
      {user && user?.role === "bidaya_takaful_user" ?  <Redirect to={path} /> : null}
      <div className="takaful-wr">  
      {/* <Redirect to={`${url}/login`}/> */}
      <nav className="Navbar">
        <div className="container justify-content-center">
          <LangLink to="" className="brand-logo">
          <LogoDark/>
          </LangLink>    
        </div>
        { isLoading && <LinearIndeterminate></LinearIndeterminate> }
      </nav>
      <div className="step-wr login-wr mt-5">
        <div className="container">
          <div className="row align-items-center flex-column">
            <div className="col-12 col-sm-4">
              <h1 className="title">
              {t("LOGIN")}
              </h1>
            </div>
            <div className="col-12 col-sm-4">
              <Formik
                initialValues={{
                  ...INITIAL_FORM_STATE
                }}
                validationSchema={FORM_VALIDATION(t)}
                onSubmit={handleSubmit}
              >
                <Form>
                
                  <div className="row row-narrow">

                    <div className="col-12">
                    { 
                        passwordType === 'password' && 
                        <>
                      <div className="field-wr">
                        <Textfield name="email" label={t("common:EMAIL_ADDRESS")} />
                      </div>
                      <div className="field-wr">
                      <Textfield name="password" label={t("common:ENTER_PWD")}  
                        type={showPassword ? "text" : "password" }
                        InputProps={{ maxLength: 16, endAdornment: <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment> }}/>
                      </div>
                      </>                        
                      }
                      { 
                        passwordType === 'otp' && otpStatus === 'sent' && 
                        <>
                        <FormLabel component="legend">{t("dashboard:OTP_TITLE")}</FormLabel>
                        <div className="field-wr">
                        <Textfield id="otp_field" name="otpfield" label={t("common:ENTER_OTP")}  
                        type="text"  inputProps={{ maxLength: 6 }}  onKeyPress={(e) => e.key === 'Enter' && handleOTPResend()} />
                        </div>
                        </>
                      }
                    </div>
                    
                  </div>
                  
                  <div className="row row-btn-submit">
                    <div className="col-12 d-flex justify-content-center">
                      <div className="btn-wr">
                      { 
                        passwordType === 'password' && 
                        <>
                        <ButtonSubmit variant="contained" color="primary" size="large" className="btn-submit" disabled={isLoading}>
                        {t("PROCEED")}
                        </ButtonSubmit>
                        <Button  fullWidth size="medium" className="text-underline" onClick={(e) => OnDialogClick(e, 'printStmtAcct')}>
                          <u>{t("FORGOT_PWD")}</u>
                        </Button>
                        </>                        
                      }
                      { 
                          passwordType === 'otp' && otpStatus === 'sent' && 
                            <div>
                              {
                                seconds !== 0 && 
                                <div className="pb-2 text-center">{formattedTime}</div>
                              }
                            <div>
                              {
                                seconds === 0 && 
                              <Button variant="text" disabled={isLoading} className="text-underline" onClick={(e) => handleOTPResend()}>
                                <u>{t("common:RESEND_OTP")}</u>
                              </Button>
                              }
                            </div>
                            <Button variant="contained" color="primary" size="large" className="btn-submit" disabled={isLoading}  onClick={(e) => handleOTP()}>
                            {t("PROCEED")}
                            </Button>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default TakafulLogin;





