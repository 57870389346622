import React, { useState } from 'react';
import { useTranslation } from 'react-i18next'
import { isMobile, isMobileOnly } from "react-device-detect";
import buyAndLeaseBack from 'assets/images/products/buy-and-lease-back.png';
import mobileBannerImage from 'assets/images/products/mobile-banner.png';

import { elementScrollIntoView } from "seamless-scroll-polyfill";


import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';

import Enquiries from 'cms/components/Forms/enquires';
import Applications from 'cms/components/Forms/applications';

import globalUseStyles from 'theme/GlobalStyles';

import message from 'assets/images/products/message.svg';
import call from 'assets/images/products/call.svg';
import socialMedia from 'assets/images/products/social.svg';
import questionMark from 'assets/images/products/question-mark.svg'

import config from "~/config";

require("styles/components/banner/banner-product.scss");

const ProductBanner = (props) => {
  const isMobile = isMobileOnly;
  const data = props.data;

  const { t } = useTranslation(["translation"]);
  const gClasses = globalUseStyles()
  const [expanded, setExpanded] = useState(false);
  const [visible, setVisible] = useState(false);
  const [nodeType, setNodeType] = useState(data?.entity?.type?.[0]?.target_id);
  const [visibleE, setVisibleE] = useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if(isMobileOnly){
      if(isExpanded){
        setTimeout(() => {
          elementScrollIntoView(event.target, { behavior: "smooth", block: "start" });
        }, 380);
      }
    }
  };

  const handleClick = () => {
    setVisibleE(false);
    // if(visible) {
    //   setVisible(false);
    // }
    // else {
      setVisible(true);
    // }
  };

  const handleClickE = () => {
    setVisible(false);
    // if(visibleE) {
    //   setVisibleE(false);
    // }
    // else {
      setVisibleE(true);
    // }
  };

  const handleSocial = (socialtype) => () => {
    let socialurl;
    let currentUrl = window.location.href;

    if(socialtype == 'facebook') {
      socialurl = "https://www.facebook.com/sharer.php?u="+currentUrl;
    }
    else if (socialtype == 'twitter') {
      socialurl = "https://twitter.com/intent/tweet?url="+currentUrl;
    }
    else if (socialtype == 'whatsapp') {
      socialurl = "https://web.whatsapp.com/send?text="+currentUrl
    }
    else {
      socialurl = "https://www.linkedin.com/shareArticle?url="+currentUrl;
    }
    window.open(socialurl, 'sharer', 'toolbar=0,status=0,width=550,height=450');
  };

  const closeForm = () => {
    setVisible(false);
  }
  const closeFormE = () => {
    setVisibleE(false);
  }
  
  return (
    <>
    {/* Banner Desktop view starts */}
    <header className="header intro intro-product product-page-gradient-bannner">
      <div className='prod-main-banner'>
        <img src={(isMobile) ? data?.entity?.field_mobile_image['0']?.url : data.entity.field_image['0'].url } alt="Banner" />
      </div>
    </header>
    {/* Banner Desktop view starts */}
    {/* Banner mobile view starts */}
    {  isMobileOnly &&
      <>
      {/* <header className='product-header-mobile-view'>
      <img src={data?.entity?.field_mobile_image['0']?.url} alt="bidaya banner" />
      </header> */}
      </>
    }
    {/* Banner mobile view ends */}

    </>
  );
}

export default ProductBanner;