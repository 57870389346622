import React, { useEffect, useState } from "react";
import Slider from "@material-ui/core/Slider";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";

import { useField, useFormikContext } from "formik";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import { getPercentAmount } from "application/utility";
import { InputAdornment } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import TextfieldReadOnly from "application/Components/FormsUI/Textfield/ReadOnly";
import NumberSlider from "application/Components/FormsUI/Textfield/NumberSlider";
import NumberOnly from "application/Components/FormsUI/Textfield/NumberOnly";
import { number } from "prop-types";

const InputSlider = ({
  name,
  label,
  currency,
  min,
  max,
  color,
  inputSize,
  handleSlider,
  setSliderVal,
  step,
  onRight,
  onNum,
  ...otherProps
}) => {
  const { t,i18n } = useTranslation();
  inputSize = i18n.language === 'ar' ? parseInt(inputSize) + 2 : inputSize
  const inputFieldClass = "col-" + inputSize + " col-input";

  //formik
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(name);

  const configFormControl = {
    // ...field,
    ...otherProps,
  };
  if (meta && meta.touched && meta.error) {
    configFormControl.error = true;
  }
  //formik end
  const [value, setValue] = useState(field?.value);
  useEffect(()=> {
    setValue(parseInt(field?.value))
  },[field?.value]);

  const handleSliderChange = (event, newValue) => {
    setValue(parseInt(newValue));
    if (handleSlider !== undefined) {
      handleSlider(getPercentAmount(newValue, 60));
    }
    if(setSliderVal !== undefined) {
      setSliderVal(value);
    }
    // setFieldValue(name, newValue);
  };
  const handleSliderChangeCommited = (event, newValue) => {
    console.info('newValue', name + newValue);
    setValue(parseInt(newValue));
    setFieldValue(name, newValue);
    if (handleSlider !== undefined) {
      handleSlider(getPercentAmount(newValue, 60));
    }
    if(setSliderVal !== undefined) {
      setSliderVal(value);
    }

  };

  const handleInputChange = (e, value) => {
    value = Number(e.target.value.replace(/[^0-9]+/g,""));
    setValue(parseInt(value));
    setFieldValue(name, value);
    setFieldTouched(name);
    if (handleSlider !== undefined) {
      handleSlider(getPercentAmount(value, 60));
    }
    if(setSliderVal !== undefined) {
      setSliderVal(value);
    }
  };

  const handleBlur = (e, value) => {    
    value = Number(e.target.value.replace(/[^0-9]+/g,""));
    setFieldTouched(name);
    if (value < parseInt(min)) {
      setValue(parseInt(value));
      setFieldValue(name, parseInt(value));
      if (handleSlider !== undefined) {
        handleSlider(getPercentAmount(parseInt(min), 60));
      }
      if(setSliderVal !== undefined) {
        setSliderVal(parseInt(min));
      }
    } else if (value >= parseInt(max)) {
      setValue(parseInt(value));
      setFieldValue(name, parseInt(value));
      if (handleSlider !== undefined) {
        handleSlider(getPercentAmount(parseInt(max), 60));
      }
      if(setSliderVal !== undefined) {
        setSliderVal(parseInt(max));
      }
    } else {
      setValue(parseInt(value));
      setFieldValue(name, value);
      if (handleSlider !== undefined) {
        handleSlider(getPercentAmount(value, 60));
      }if(setSliderVal !== undefined) {
        setSliderVal(value);
      }
    }
  };

  return (
    <div className="slide-input-wr">
      <FormControl {...configFormControl}>
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <div className="row row-narrow align-items-center">
          <div className="col col-slider">
            <Slider
              value={typeof value === "number" ? value : 0}
              defaultValue={0}
              onChange={handleSliderChange}
              onChangeCommitted={handleSliderChangeCommited}
              aria-labelledby="input-slider"
              step={step}
              min={parseInt(min)}
              max={parseInt(max)}
              color={color}
              disabled={otherProps?.disabled ? true : false}
            />
          </div>
          {!onRight && 
                <div className="col-1 currency">{currency}</div>
            }
          <div className={inputFieldClass}>
            {!onNum &&
              <NumberSlider
              name={name}
              currency={currency}
              inputProps={{ maxLength: 10 }}
              onChange={handleInputChange}
              onBlur={handleBlur}
              onRight={onRight}
            />
            }
            {onNum &&
              <NumberOnly
                name={name}
                currency={currency}
                inputProps={{ maxLength: 2 }}
                onChange={handleInputChange}
                onBlur={handleBlur}
                onRight={onRight}
                hideErrorText={onNum}
              />
            }
          </div>
        </div>
        
        {meta.error !== undefined && meta.touched !== false && (
          <FormHelperText>{meta.error}</FormHelperText>
        )}


      </FormControl>
    </div>
  );
};

export default InputSlider;
