import React from "react";
import { useTranslation } from "react-i18next";
import LogoWhite from "assets/images/LogoWhite";
import LangLink from "components/lang/LangLink";

require("styles/common/navbar.scss");

const UnderMaintenance = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <nav className="Navbar mb-2">
        <div className="container">
          <LangLink to="/" className="brand-logo">
            <LogoWhite />
          </LangLink>
        </div>
      </nav>
      <section>
      <div className="container pt-10">
        <h2 className="mb-2">
          { t('UNDER_MAINTENANCE') }
        </h2>

          <h6 >  
          { t('404_MESSAGE_2') }
          </h6>

          <div className="col-12 col-sm-6 col-link">
              <ul >
                <li >
                  <LangLink to='/'>
                  {t("HOME_FINANCING")}
                  </LangLink>
                </li>
                <li >
                  <LangLink to='/contact-us/contact-us'>
                    {t("contact_us")}
                  </LangLink>
                </li>
                <li >
                  <LangLink to='/contact-us/faq'>
                  {t("faq")}
                  </LangLink>
                </li>
              </ul>
            </div>
      </div>
      </section>
    </>
  );
}

export default UnderMaintenance;
