import App from './App';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { hydrate } from 'react-dom';
import { CmsProvider } from 'context/cms/CmsContext';
import { loadableReady } from "@loadable/component";
import 'i18n';

// Load all components needed before rendering
loadableReady().then(() => {
  hydrate(
    <BrowserRouter>
      <CmsProvider><App /></CmsProvider>
    </BrowserRouter>,
    document.getElementById('root') 
  );
});

if (module.hot) {
  module.hot.accept();
}
