import { makeStyles } from "@material-ui/core";
import { getRequestDataProp } from "context/application/ApplicationReducer";
import moment from "moment";

export const checkErrorTab = (errors, requiredData, submitCount) => {
  //console.log(errors);

  let count = 0;
  Object.keys(errors).forEach((item) => {
    if (requiredData.includes(item)) {
      count++;
    }
  });
  return submitCount > 0 ? count : 0;
};

export const useStyles = makeStyles((theme) => ({
  setError: {
    color: "red",
  },
}));

export const storeDataAsync = (values) => {
  //store value by key value
  Object.keys(values).forEach((index) => {
    if (typeof values[index] === "object") {
      localStorage.setItem(index, JSON.stringify(values[index]));
    } else {
      localStorage.setItem(index, values[index]);
    }
  });
};

export const removeDataAsync = () => {
  //remove data from storage
  localStorage.clear();
};

export const setLocalStorage = (key, val) => {
  const str = JSON.stringify(val);
  localStorage.setItem(key, str);
  return true;
};

export const getLocalStorage = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

export const removeItemLocalStorage = (key) => {
  localStorage.removeItem(key);
  return true;
};

export const clearLocalStorage = () => {
  localStorage.clear();
  return true;
};

export const setDateFormat = (value) => {
  let date = JSON.parse(JSON.stringify(value));
  return moment(date).locale("en").format("YYYY-MM-DD");
};

export const getKeyBaseValues = (data = [], key, lower = false) => {
  if (lower) {
    const newData = data.map((response) => response[key].toLowerCase());
    return newData.join();
  } else {
    const newData = data.map((response) => response[key]);
    return newData.join();
  }
};

export const calculateAge = (dob) => {
  const birthDate = new Date(dob);
  const difference = Date.now() - birthDate.getTime();
  const age = new Date(difference);
  console.log("age", Math.abs(age.getUTCFullYear() - 1970));
  return Math.abs(age.getUTCFullYear() - 1970);
};

export const getPercentAmount = (amount, percentToGet) => {
  return (percentToGet / 100) * amount;
};

export const getNumberFormat = (val) => {
  if (isNaN(parseInt(val))) {
    return 0;
  }
  return parseInt(val);
};

export const calculatePercent = (total,remain) => {
  const amount = (remain/total);
  if(amount === 0) {
    return amount
  }
  return Math.round(amount * 100);
}

export const monthlyObligationInitVal = (contextState) => {
  const coapplicant = contextState?.requestData?.request_info?.coapplicant ? contextState.requestData.request_info.coapplicant : false;
  const inVal =  contextState?.requestData?.request_finance?.monthly_obligations?.more_expense ? contextState?.requestData?.request_finance?.monthly_obligations?.more_expense : true;
  const inCoMoreExpVal =  contextState?.requestData?.request_finance?.co_monthly_obligations?.more_expense ? true : false;
  
  const INITIAL_FORM_STATE = {
    total_expenses:
      contextState?.requestData?.request_finance?.monthly_obligations
        ?.total_expenses ? contextState?.requestData?.request_finance?.monthly_obligations
        ?.total_expenses : "",
    simah_total_obligations:
      contextState?.requestData?.request_finance?.monthly_obligations
        ?.simah_total_obligations ? contextState?.requestData?.request_finance?.monthly_obligations
        ?.simah_total_obligations : "",
    personal_outstanding:
      contextState?.requestData?.request_finance?.monthly_obligations
        ?.personal_loan?.outstanding_amount ? contextState?.requestData?.request_finance?.monthly_obligations
        ?.personal_loan?.outstanding_amount : "",
    personal_tenor:
      contextState?.requestData?.request_finance?.monthly_obligations
        ?.personal_loan?.outstanding_tenor ? contextState?.requestData?.request_finance?.monthly_obligations
        ?.personal_loan?.outstanding_tenor : "",
    personal_monthly_installmennt:
      contextState?.requestData?.request_finance?.monthly_obligations
        ?.personal_loan?.monthly_installment ?  contextState?.requestData?.request_finance?.monthly_obligations
        ?.personal_loan?.monthly_installment : "",
    carloan_outstanding:
      contextState?.requestData?.request_finance?.monthly_obligations
        ?.car_loan?.outstanding_amount ? contextState?.requestData?.request_finance?.monthly_obligations
        ?.car_loan?.outstanding_amount : "",
    carloan_tenor:
      contextState?.requestData?.request_finance?.monthly_obligations
        ?.car_loan?.outstanding_tenor ? contextState?.requestData?.request_finance?.monthly_obligations
        ?.car_loan?.outstanding_tenor : "",
    carloan_monthly_installmennt:
      contextState?.requestData?.request_finance?.monthly_obligations
        ?.car_loan?.monthly_installment ?  contextState?.requestData?.request_finance?.monthly_obligations
        ?.car_loan?.monthly_installment : "",
    mortgage_outstanding:
      contextState?.requestData?.request_finance?.monthly_obligations?.mortgage
        ?.outstanding_amount ? contextState?.requestData?.request_finance?.monthly_obligations?.mortgage
        ?.outstanding_amount : "",
    mortgage_tenor:
      contextState?.requestData?.request_finance?.monthly_obligations?.mortgage
        ?.outstanding_tenor ? contextState?.requestData?.request_finance?.monthly_obligations?.mortgage
        ?.outstanding_tenor : "",
    mortgage_monthly_installmennt:
      contextState?.requestData?.request_finance?.monthly_obligations?.mortgage
        ?.monthly_installment ? contextState?.requestData?.request_finance?.monthly_obligations?.mortgage
        ?.monthly_installment : "",
    credit_card_outstanding:
      contextState?.requestData?.request_finance?.monthly_obligations
        ?.credit_card?.outstanding_amount ? contextState?.requestData?.request_finance?.monthly_obligations
        ?.credit_card?.outstanding_amount : "",
    credit_card_limit:
      contextState?.requestData?.request_finance?.monthly_obligations
        ?.credit_card?.outstanding_tenor ? contextState?.requestData?.request_finance?.monthly_obligations
        ?.credit_card?.outstanding_tenor  : "",
    credit_card_monthly_installment:
      contextState?.requestData?.request_finance?.monthly_obligations
        ?.credit_card?.monthly_installment ? contextState?.requestData?.request_finance?.monthly_obligations
        ?.credit_card?.monthly_installment  : 0,
    staff_loan_outstanding:
      contextState?.requestData?.request_finance?.monthly_obligations
        ?.staff_loan?.outstanding_amount ? contextState?.requestData?.request_finance?.monthly_obligations
        ?.staff_loan?.outstanding_amount  : "",
    staff_loan_tenor:
      contextState?.requestData?.request_finance?.monthly_obligations
        ?.staff_loan?.outstanding_tenor ? contextState?.requestData?.request_finance?.monthly_obligations
        ?.staff_loan?.outstanding_tenor : "",
    staff_loan_monthly_installmennt:
      contextState?.requestData?.request_finance?.monthly_obligations
        ?.staff_loan?.monthly_installment ? contextState?.requestData?.request_finance?.monthly_obligations
        ?.staff_loan?.monthly_installment : "",
    commitment_outstanding:
      contextState?.requestData?.request_finance?.monthly_obligations
        ?.commitments?.outstanding_amount ? contextState?.requestData?.request_finance?.monthly_obligations
        ?.commitments?.outstanding_amount : "",
    commitment_tenor:
      contextState?.requestData?.request_finance?.monthly_obligations
        ?.commitments?.outstanding_tenor ? contextState?.requestData?.request_finance?.monthly_obligations
        ?.commitments?.outstanding_tenor : "",
    commitment_monthly_installmennt:
      contextState?.requestData?.request_finance?.monthly_obligations
        ?.commitments?.monthly_installment ? contextState?.requestData?.request_finance?.monthly_obligations
        ?.commitments?.monthly_installment : "",
    more_expense: inVal,
    transportation_expenditure:
      contextState?.requestData?.request_finance?.monthly_obligations
        ?.transport_communication ? contextState?.requestData?.request_finance?.monthly_obligations
        ?.transport_communication : "",
    expenditure_lease:
      contextState?.requestData?.request_finance?.monthly_obligations
        ?.lease_rent ? contextState?.requestData?.request_finance?.monthly_obligations
        ?.lease_rent : "",
    health:
      contextState?.requestData?.request_finance?.monthly_obligations?.health ?  contextState?.requestData?.request_finance?.monthly_obligations?.health :
      "",
    miscellaneous_goods:
      contextState?.requestData?.request_finance?.monthly_obligations?.misc ? contextState?.requestData?.request_finance?.monthly_obligations?.misc :
      "",
    insurance_expenditures:
      contextState?.requestData?.request_finance?.monthly_obligations
        ?.insurance ? contextState?.requestData?.request_finance?.monthly_obligations
        ?.insurance : "",
    food:
      contextState?.requestData?.request_finance?.monthly_obligations?.food ? contextState?.requestData?.request_finance?.monthly_obligations?.food :
      "",
    future:
      contextState?.requestData?.request_finance?.monthly_obligations?.future ? contextState?.requestData?.request_finance?.monthly_obligations?.future :
      "",
    labor_wage:
      contextState?.requestData?.request_finance?.monthly_obligations
        ?.labor_wage ? contextState?.requestData?.request_finance?.monthly_obligations
        ?.labor_wage : "",
    education:
      contextState?.requestData?.request_finance?.monthly_obligations
        ?.education ? contextState?.requestData?.request_finance?.monthly_obligations
        ?.education : "",
    communication:
      contextState?.requestData?.request_finance?.monthly_obligations?.communication ? contextState?.requestData?.request_finance?.monthly_obligations?.communication :
      "",
    clothing:
      contextState?.requestData?.request_finance?.monthly_obligations
        ?.cloth_footwear ? contextState?.requestData?.request_finance?.monthly_obligations
        ?.cloth_footwear: "",
    other:
      contextState?.requestData?.request_finance?.monthly_obligations
        ?.other ? contextState?.requestData?.request_finance?.monthly_obligations
        ?.other: "",
    monthly_remittances:
      contextState?.requestData?.request_finance?.monthly_obligations
        ?.monthly_remittances ? contextState?.requestData?.request_finance?.monthly_obligations
        ?.monthly_remittances: "",
    coapplicant: coapplicant,
    co_total_expenses: getRequestDataProp(contextState?.requestData?.request_finance, 'co_monthly_obligations', 'total_expenses'),
    co_simah_total_obligations: getRequestDataProp(contextState?.requestData?.request_finance, 'co_monthly_obligations', 'simah_total_obligations'),
    co_personal_outstanding: getRequestDataProp(contextState?.requestData?.request_finance?.co_monthly_obligations, 'personal_loan', 'outstanding_amount'),
    co_personal_tenor: getRequestDataProp(contextState?.requestData?.request_finance?.co_monthly_obligations, 'personal_loan', 'outstanding_tenor'),
    co_personal_monthly_installmennt: getRequestDataProp(contextState?.requestData?.request_finance?.co_monthly_obligations, 'personal_loan', 'monthly_installment'),
    co_carloan_outstanding: getRequestDataProp(contextState?.requestData?.request_finance?.co_monthly_obligations, 'car_loan', 'outstanding_amount'),
    co_carloan_tenor: getRequestDataProp(contextState?.requestData?.request_finance?.co_monthly_obligations, 'car_loan', 'outstanding_tenor'),
    co_carloan_monthly_installmennt: getRequestDataProp(contextState?.requestData?.request_finance?.co_monthly_obligations, 'car_loan', 'monthly_installment'),
   
    co_mortgage_outstanding: getRequestDataProp(contextState?.requestData?.request_finance?.co_monthly_obligations, 'mortgage', 'outstanding_amount'),
    co_mortgage_tenor: getRequestDataProp(contextState?.requestData?.request_finance?.co_monthly_obligations, 'mortgage', 'outstanding_tenor'),
    co_mortgage_monthly_installmennt: getRequestDataProp(contextState?.requestData?.request_finance?.co_monthly_obligations, 'mortgage', 'monthly_installment'),
    co_credit_card: contextState?.requestData?.request_finance?.co_monthly_obligations?.credit_card?.outstanding_amount ? contextState?.requestData?.request_finance?.co_monthly_obligations?.credit_card?.outstanding_amount : "",
    co_credit_card_outstanding: contextState?.requestData?.request_finance?.co_monthly_obligations?.credit_card?.outstanding_amount ? contextState?.requestData?.request_finance?.co_monthly_obligations?.credit_card?.outstanding_amount : "",
    co_credit_card_limit: contextState?.requestData?.request_finance?.co_monthly_obligations?.credit_card?.outstanding_tenor ? contextState?.requestData?.request_finance?.co_monthly_obligations?.credit_card?.outstanding_tenor : "",
    co_credit_card_monthly_installment: contextState?.requestData?.request_finance?.co_monthly_obligations?.credit_card?.monthly_installment ? contextState?.requestData?.request_finance?.co_monthly_obligations?.credit_card?.monthly_installment : "",
    co_staff_loan_outstanding: contextState?.requestData?.request_finance?.co_monthly_obligations?.staff_loan?.outstanding_amount ? contextState?.requestData?.request_finance?.co_monthly_obligations?.staff_loan?.outstanding_amount : "",
    co_staff_loan_tenor: contextState?.requestData?.request_finance?.co_monthly_obligations?.staff_loan?.outstanding_tenor ? contextState?.requestData?.request_finance?.co_monthly_obligations?.staff_loan?.outstanding_tenor : "",
    co_staff_loan_monthly_installmennt: contextState?.requestData?.request_finance?.co_monthly_obligations?.staff_loan?.monthly_installment ? contextState?.requestData?.request_finance?.co_monthly_obligations?.staff_loan?.monthly_installment :  "",
    co_commitment_outstanding: contextState?.requestData?.request_finance?.co_monthly_obligations?.commitments?.outstanding_amount ? contextState?.requestData?.request_finance?.co_monthly_obligations?.commitments?.outstanding_amount :  "",
    co_commitment_tenor: contextState?.requestData?.request_finance?.co_monthly_obligations?.commitments?.outstanding_tenor ? contextState?.requestData?.request_finance?.co_monthly_obligations?.commitments?.outstanding_tenor : "",
    co_commitment_monthly_installmennt: contextState?.requestData?.request_finance?.co_monthly_obligations?.commitments?.monthly_installment ? contextState?.requestData?.request_finance?.co_monthly_obligations?.commitments?.monthly_installment : "",
    co_more_expense: inCoMoreExpVal,
    co_transportation_expenditure: getRequestDataProp(contextState?.requestData?.request_finance, 'co_monthly_obligations', 'transport_communication'),
    co_expenditure_lease: getRequestDataProp(contextState?.requestData?.request_finance, 'co_monthly_obligations', 'lease_rent'),
    co_health: getRequestDataProp(contextState?.requestData?.request_finance, 'co_monthly_obligations', 'health'),
    co_miscellaneous_goods: getRequestDataProp(contextState?.requestData?.request_finance, 'co_monthly_obligations', 'misc'),
    co_insurance_expenditures: getRequestDataProp(contextState?.requestData?.request_finance, 'co_monthly_obligations', 'insurance'),
    co_food: getRequestDataProp(contextState?.requestData?.request_finance, 'co_monthly_obligations', 'food'),
    co_income_other: getRequestDataProp(contextState?.requestData?.request_finance, 'co_monthly_obligations', 'other'),
    co_labor_wage: getRequestDataProp(contextState?.requestData?.request_finance, 'co_monthly_obligations', 'labor_wage'),
    co_education: getRequestDataProp(contextState?.requestData?.request_finance, 'co_monthly_obligations', 'education'),
    co_communication: getRequestDataProp(contextState?.requestData?.request_finance, 'co_monthly_obligations', 'communication'),
    co_clothing: getRequestDataProp(contextState?.requestData?.request_finance, 'co_monthly_obligations', 'cloth_footwear'),
    co_other: getRequestDataProp(contextState?.requestData?.request_finance, 'co_monthly_obligations', 'other'),
    co_monthly_remittances: getRequestDataProp(contextState?.requestData?.request_finance, 'co_monthly_obligations', 'monthly_remittances'),
    mortgage: contextState?.requestData?.request_finance?.monthly_obligations?.other_mortgage ? contextState?.requestData?.request_finance?.monthly_obligations?.other_mortgage : [],
    personal: contextState?.requestData?.request_finance?.monthly_obligations?.other_personal_loan ? contextState?.requestData?.request_finance?.monthly_obligations?.other_personal_loan : [],
    carloan: contextState?.requestData?.request_finance?.monthly_obligations?.other_carloan ? contextState?.requestData?.request_finance?.monthly_obligations?.other_carloan : [],
    credit_card: contextState?.requestData?.request_finance?.monthly_obligations?.other_credit_card ? contextState?.requestData?.request_finance?.monthly_obligations?.other_credit_card : [],
    co_mortgage: coapplicant && contextState?.requestData?.request_finance?.co_monthly_obligations?.other_mortgage ? contextState?.requestData?.request_finance?.co_monthly_obligations?.other_mortgage : [],
    co_personal: coapplicant && contextState?.requestData?.request_finance?.co_monthly_obligations?.other_personal_loan ? contextState?.requestData?.request_finance?.co_monthly_obligations?.other_personal_loan : [],
    co_carloan: coapplicant && contextState?.requestData?.request_finance?.co_monthly_obligations?.other_carloan ? contextState?.requestData?.request_finance?.co_monthly_obligations?.other_carloan : [],
    co_credit_card: coapplicant && contextState?.requestData?.request_finance?.co_monthly_obligations?.other_credit_card ? contextState?.requestData?.request_finance?.co_monthly_obligations?.other_credit_card : [],
  };
  return INITIAL_FORM_STATE;
}

// Clear empty object property from Object.
export const clearEmptyObject = (o) => {
  for (var k in o) {
    if (!o[k] || typeof o[k] !== "object") {
      continue // If null or not an object, skip to the next iteration
    }

    // The property is an object
    if (Object.keys(o[k]).length === 0) {
      delete o[k]; // The object had no properties, so delete that property
    }
  }
  return o;
}

export const stringTrim = (string,n) => {
  var str = string.substring(0, n);
  return str + "...";
}

export const getDeNumberFormat = (val) => {
  if (isNaN(parseFloat(val))) {
    return 0;
  }
  return parseFloat(val);
};


export const setCookie =(name, value, days) => {
  let expires = "";
  if (days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
};