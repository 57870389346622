import React from 'react'

const LogoDark = ({ width = 74, height = 71 }) => {
  return (
     
    <svg viewBox="0 0 485.38 416.56"
    xmlns="http://www.w3.org/2000/svg" width={width} height={height} className="logo"> <defs>
    
  </defs>
  <g id="Layer_1-2">
    <path fill="#ea1d76" d="m.94,288.29h18.31c4.48,0,7.65,1.14,9.75,3.23,1.41,1.43,2.29,3.31,2.29,5.76v.09c0,4.14-2.35,6.58-5.51,7.96,4.33,1.44,7.09,3.88,7.09,8.73v.1c0,6.13-4.99,9.86-13.52,9.86H.94v-35.72Zm15.87,14.23c3.02,0,4.69-1.01,4.69-3.1v-.1c0-1.95-1.53-3.08-4.49-3.08h-6.42v6.27h6.22Zm1.42,13.53c3.01,0,4.74-1.18,4.74-3.26v-.12c0-1.93-1.51-3.21-4.84-3.21h-7.55v6.59h7.65Z" />
    <path fill="#ea1d76" d="m37.63,288.29h9.94v35.72h-9.94v-35.72Z" />
    <path fill="#ea1d76" d="m53.83,288.29h13.76c12.75,0,20.14,7.35,20.14,17.66v.11c0,10.29-7.49,17.96-20.34,17.96h-13.56v-35.72Zm13.92,26.93c5.91,0,9.84-3.26,9.84-9.02v-.1c0-5.71-3.93-9.04-9.84-9.04h-4.03v18.16h4.03Z" />
    <path fill="#ea1d76" d="m101.04,288.05h9.54l15.2,35.96h-10.62l-2.58-6.38h-13.78l-2.55,6.38h-10.41l15.2-35.96Zm8.68,21.88l-3.98-10.15-4.03,10.15h8.01Z" />
    <path fill="#ea1d76" d="m131.87,310.65l-13.57-22.36h11.27l7.35,13.01,7.4-13.01h11.06l-13.57,22.19v13.53h-9.95v-13.36Z" />
    <path fill="#ea1d76" d="m162.81,288.05h9.55l15.19,35.96h-10.61l-2.6-6.38h-13.76l-2.57,6.38h-10.39l15.19-35.96Zm8.68,21.88l-3.99-10.15-4.03,10.15h8.02Z" />
    <path fill="#00a8e1" d="m241.19,261.67c0,10.97-8.88,19.86-19.85,19.86s-19.85-8.89-19.85-19.86,8.89-19.84,19.85-19.84,19.85,8.88,19.85,19.84Z" />
    <path fill="#00a8e1" d="m241.19,304.16c0,10.96-8.88,19.85-19.85,19.85s-19.85-8.89-19.85-19.85,8.89-19.85,19.85-19.85,19.85,8.89,19.85,19.85Z" />
    <path fill="#00a8e1" d="m485.38,396.71c0,10.96-8.88,19.85-19.85,19.85s-19.85-8.89-19.85-19.85,8.89-19.85,19.85-19.85,19.85,8.89,19.85,19.85Z" />
    <path fill="#00a8e1" d="m213.33,19.84c0,10.96-8.89,19.85-19.85,19.85s-19.83-8.89-19.83-19.85S182.52,0,193.47,0s19.85,8.89,19.85,19.84Z" />
    <path fill="#00a8e1" d="m213.33,62.32c0,10.96-8.89,19.84-19.85,19.84s-19.83-8.88-19.83-19.84,8.87-19.84,19.83-19.84,19.85,8.88,19.85,19.84Z" />
    <path fill="#ea1d76" d="m279.29,140.52h.02v-58.35h-65.16v56.54c0,10.84-4.41,20.66-11.52,27.77.57-4.33.86-8.74.86-13.21,0-55.83-45.42-101.27-101.26-101.27S.94,97.43.94,153.26s45.43,101.31,101.29,101.31c28.45,0,54.2-11.81,72.61-30.78v19.39c4,0,7.95-.23,11.83-.67,23.1-2.62,43.94-12.79,59.97-28,19.69-18.67,32.14-44.9,32.64-74Zm-177.07,55.34c-23.48,0-42.58-19.11-42.58-42.58s19.1-42.57,42.58-42.57,42.56,19.1,42.56,42.57-19.09,42.58-42.56,42.58Z" />
    <path fill="#ea1d76" d="m471.74,255.82h-64.65l-.07,62.48c-.79,22.58-18.6,39.56-39.49,39.56-5,0-9.78-1.01-14.19-2.84,4.39-11.52,6.83-23.99,6.83-37.03,0-14.79-3.1-28.86-8.67-41.63-3.19-7.31-7.17-14.19-11.87-20.51h19.61V41.21h-65.41v179.49c-11.82-4.65-24.69-7.21-38.14-7.21l-.95.02v59.26c.32-.02.64-.02.95-.03.46-.01.91-.03,1.37-.03,7.02,0,13.65,1.72,19.49,4.75,13.69,7.09,23.07,21.38,23.07,37.82s-9.38,30.74-23.06,37.83c-5.85,3.03-12.48,4.75-19.5,4.75-9.81,0-18.85-3.34-26.05-8.94-.15-.11-.29-.23-.44-.35l-36.21,46.19.07.06c17.25,13.62,39.01,21.75,62.64,21.75,21,0,40.52-6.43,56.71-17.42,16.2,10.99,35.73,17.42,56.73,17.42,12.87,0,25.18-2.42,36.51-6.82,37.36-14.5,64.04-50.57,64.72-92.84v-61.09Z" />
    <path fill="#717271" d="m17.56,396.04c-1.42,0-2.65-.36-3.65-.97v.13c0,3.91-2.75,6.56-6.53,6.63-.39,0-.58.03-.87,0-3.75-.07-6.5-2.75-6.5-6.66v-5.88h4.04v5.79c0,1.94.91,2.97,2.94,2.97s2.84-1.04,2.84-2.97v-19.92h4.07v14.39c0,1.78,1.52,2.78,3.65,2.78h.49v3.72h-.49Z" />
    <path fill="#717271" d="m17.07,396.04v-3.72h6.05v3.72h-6.05Z" />
    <path fill="#717271" d="m22.21,396.04v-3.72h6.05v3.72h-6.05Z" />
    <path fill="#717271" d="m27.35,396.04v-3.72h6.05v3.72h-6.05Z" />
    <path fill="#717271" d="m32.49,396.04v-3.72h6.05v3.72h-6.05Z" />
    <path fill="#717271" d="m37.63,396.04v-3.72h6.05v3.72h-6.05Z" />
    <path fill="#717271" d="m42.77,396.04v-3.72h6.05v3.72h-6.05Z" />
    <path fill="#717271" d="m47.91,396.04v-3.72h6.05v3.72h-6.05Z" />
    <path fill="#717271" d="m53.05,396.04v-3.72h6.05v3.72h-6.05Z" />
    <path fill="#717271" d="m58.19,396.04v-3.72h6.05v3.72h-6.05Z" />
    <path fill="#717271" d="m61.94,402.58v-4.04h3.62v4.04h-3.62Zm1.88-6.53h-.32v-3.72h.32c2.72-.06,3.59-1.03,3.59-2.91v-8.47h4.04v8.67c0,3.95-2.68,6.4-7.63,6.43Zm3.33,6.53v-4.04h3.59v4.04h-3.59Z" />
    <path fill="#717271" d="m87.74,396.04c-.49,3.59-3.3,5.88-6.6,6.08l-.71.06v-3.75c1.65-.03,2.94-1.07,3.07-2.39h-2.42c-2.85,0-6.5-1.65-6.5-7.11,0-5.14,2.36-7.99,6.5-7.99h6.69v11.38h3.85v3.72h-3.88Zm-4.01-11.48h-2.59c-2.2,0-2.52,2.72-2.52,4.37,0,1.81.84,3.39,2.52,3.39h2.59v-7.76Z" />
    <path fill="#717271" d="m90.61,396.04v-3.72h6.05v3.72h-6.05Z" />
    <path fill="#717271" d="m95.75,396.04v-3.72h6.05v3.72h-6.05Z" />
    <path fill="#717271" d="m100.89,396.04v-3.72h6.05v3.72h-6.05Z" />
    <path fill="#717271" d="m122.69,395.17c-1.1,2.3-3.14,3.62-5.98,3.62-2.75-.03-4.85-1.29-5.95-3.46-1,.48-2.23.71-3.56.71h-1.16v-3.72c1.58-.1,3.49-.06,3.65-2,.26-4.69,2.68-7.7,7.02-7.7s6.89,3.46,6.89,7.7c0,1.36.97,2,2.97,2h1.16v3.72c-1.68,0-3.52-.1-5.04-.87Zm-5.98-9.12c-1.65,0-2.75,1.13-2.75,4.56s1.1,4.46,2.75,4.46,2.75-1.16,2.75-4.37c0-3.52-1.1-4.66-2.75-4.66Z" />
    <path fill="#717271" d="m126.76,396.04v-3.72h6.05v3.72h-6.05Z" />
    <path fill="#717271" d="m131.9,396.04v-3.72h6.05v3.72h-6.05Z" />
    <path fill="#717271" d="m137.04,396.04v-3.72h6.05v3.72h-6.05Z" />
    <path fill="#717271" d="m141.85,378.13v-4.04h3.3v4.04h-3.3Zm11.16,17.91c-2,0-3.62-.58-4.88-1.58-1.16,1.07-2.84,1.58-4.79,1.58h-1.16v-3.72h1.16c1.68,0,2.62-.91,2.62-2.33v-9.05h4.07v9.15c0,1.36.97,2.23,2.97,2.23h1.16v3.72h-1.16Zm-6.3-17.91v-4.04h3.3v4.04h-3.3Z" />
    <path fill="#717271" d="m153.2,396.04v-3.72h6.05v3.72h-6.05Z" />
    <path fill="#717271" d="m158.34,396.04v-3.72h6.05v3.72h-6.05Z" />
    <path fill="#717271" d="m163.48,396.04v-3.72h6.05v3.72h-6.05Z" />
    <path fill="#717271" d="m179.45,396.04c-2,0-3.62-.58-4.88-1.58-1.16,1.07-2.84,1.58-4.79,1.58h-1.16v-3.72h1.16c1.68,0,2.62-.91,2.62-2.33v-14.84h4.07v14.94c0,1.36.97,2.23,2.97,2.23h1.16v3.72h-1.16Z" />
    <path fill="#717271" d="m179.65,396.04v-3.72c2.78,0,4.07-1.03,4.07-2.94v-14.23h4.04v14.45c0,3.98-3.1,6.43-8.12,6.43Z" />
    <path fill= "#717271" stroke="#717271" stroke-miterlimit="10" stroke-width="0.99" d="m16.48,335.74H6.45v5.52h8.33v4.25H6.45v9.71H1.44v-24.02h15.04v4.54Z" />
    <path fill= "#717271" stroke="#717271" stroke-miterlimit="10" stroke-width="0.99" d="m32.88,355.22h-5v-24.02h5v24.02Z" />
    <path fill= "#717271" stroke="#717271" stroke-miterlimit="10" stroke-width="0.99" d="m65.27,355.64h-.13l-14.18-13.83v13.4h-5v-24.45h.13l14.19,13.83v-13.4h5v24.45Z" />
    <path fill= "#717271" stroke="#717271" stroke-miterlimit="10" stroke-width="0.99" d="m99.46,355.22h-5.49l-1.57-3.73h-9.22l-1.57,3.73h-5.49l11.6-24.45h.13l11.6,24.45Zm-14.71-7.62h6.08l-3.04-7.19-3.04,7.19Z" />
    <path fill= "#717271" stroke="#717271" stroke-miterlimit="10" stroke-width="0.99" d="m129.62,355.64h-.13l-14.18-13.83v13.4h-5v-24.45h.13l14.19,13.83v-13.4h5v24.45Z" />
    <path fill= "#717271" stroke="#717271" stroke-miterlimit="10" stroke-width="0.99" d="m162.31,334.36l-3.14,3.63c-1.27-1.5-3.1-2.68-5.56-2.68-4.41,0-7.29,3.3-7.29,7.88s2.88,7.91,7.29,7.91c2.48,0,4.35-1.21,5.62-2.75l3.11,3.63c-2.39,2.61-5.23,3.66-8.73,3.66-7.22,0-12.32-5.33-12.32-12.45s5.1-12.42,12.32-12.42c3.79,0,6.37,1.21,8.69,3.6Z" />
    <path fill= "#717271" stroke="#717271" stroke-miterlimit="10" stroke-width="0.99" d="m188.81,335.74h-10.52v5.2h9.12v4.22h-9.12v5.52h10.79v4.54h-15.79v-24.02h15.53v4.54Z" />
  </g>undefined</svg>

  );
}

export default LogoDark;