import React from 'react'

const LogoWhite = ({ width = 74, height = 70 }) => {
  return (
    <svg 
      viewBox="0 0 74 70"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className="logo"
      >
      <defs>
        <polygon id="path-1" points="0.361755882 0.170701754 6.96131471 0.170701754 6.96131471 6.73338596 0.361755882 6.73338596"></polygon>
        <polygon id="path-3" points="0 69.3859649 73.4888824 69.3859649 73.4888824 0.170701754 0 0.170701754"></polygon>
      </defs>
      <g id="home" stroke="none" fill="none" fillRule="evenodd">
          <g id="1.HomePage_v.1.1_Desktop" transform="translate(-20.000000, -10.000000)">
              <g id="bidaya-logo" transform="translate(20.000000, 10.443333)">
                  <path d="M35.0654912,43.4313772 C35.0654912,45.2440088 33.5886971,46.7146228 31.7641676,46.7146228 C29.9421088,46.7146228 28.4634618,45.2440088 28.4634618,43.4313772 C28.4634618,41.6205877 29.9421088,40.1512018 31.7641676,40.1512018 C33.5886971,40.1512018 35.0654912,41.6205877 35.0654912,43.4313772" id="Fill-1" fill="#FFFFFF"></path>
                  <path d="M35.0654912,50.4548947 C35.0654912,52.2662982 33.5886971,53.7369123 31.7641676,53.7369123 C29.9421088,53.7369123 28.4634618,52.2662982 28.4634618,50.4548947 C28.4634618,48.6434912 29.9421088,47.1728772 31.7641676,47.1728772 C33.5886971,47.1728772 35.0654912,48.6434912 35.0654912,50.4548947" id="Fill-3" fill="#FFFFFF"></path>
                  <g id="Group-7" transform="translate(23.470588, 0.000000)">
                      <mask id="mask-2" fill="white">
                          <use href="#path-1"></use>
                      </mask>
                      <g id="Clip-6"></g>
                      <path d="M6.96131471,3.45136842 C6.96131471,5.26277193 5.48266765,6.73338596 3.65999118,6.73338596 C1.83731471,6.73338596 0.361755882,5.26277193 0.361755882,3.45136842 C0.361755882,1.63996491 1.83731471,0.170578947 3.65999118,0.170578947 C5.48266765,0.170578947 6.96131471,1.63996491 6.96131471,3.45136842" id="Fill-5" fill="#FFFFFF" mask="url(#mask-2)"></path>
                  </g>
                  <path d="M30.4319029,10.4742105 C30.4319029,12.285614 28.9532559,13.755 27.1305794,13.755 C25.3079029,13.755 23.8323441,12.285614 23.8323441,10.4742105 C23.8323441,8.66280702 25.3079029,7.19526316 27.1305794,7.19526316 C28.9532559,7.19526316 30.4319029,8.66280702 30.4319029,10.4742105" id="Fill-8" fill="#FFFFFF"></path>
                  <path d="M73.4886971,65.2602632 C72.9334324,64.8328947 72.2367265,64.574386 71.4757853,64.574386 C69.6537265,64.574386 68.1769324,66.0431579 68.1769324,67.8551754 C68.1769324,68.4084211 68.3152853,68.9278947 68.5567853,69.3853509 L73.4886971,69.3853509 L73.4886971,65.2602632 Z" id="Fill-10" fill="#FFFFFF"></path>
                  <path d="M4.01396471,51.7313509 C4.39875882,51.7313509 4.62049412,51.5809123 4.62049412,51.3162632 L4.62049412,51.3015263 C4.62049412,51.0552982 4.42717059,50.8919649 4.00037647,50.8919649 L3.03499412,50.8919649 L3.03499412,51.7313509 L4.01396471,51.7313509 Z M3.83175882,50.0095965 C4.21902353,50.0095965 4.43211176,49.8818772 4.43211176,49.615386 L4.43211176,49.6024912 C4.43211176,49.3550351 4.23508235,49.2119649 3.8577,49.2119649 L3.03499412,49.2119649 L3.03499412,50.0095965 L3.83175882,50.0095965 Z M1.80155294,48.1994211 L4.14490588,48.1994211 C4.71931765,48.1994211 5.12387647,48.3431053 5.39255294,48.6095965 C5.57228824,48.7913509 5.68593529,49.0308246 5.68593529,49.3421404 L5.68593529,49.3550351 C5.68593529,49.8818772 5.38575882,50.1913509 4.97996471,50.3669649 C5.53399412,50.5505614 5.88728824,50.8600351 5.88728824,51.4771404 L5.88728824,51.488807 C5.88728824,52.2692456 5.24925882,52.7426667 4.15725882,52.7426667 L1.80155294,52.7426667 L1.80155294,48.1994211 Z" id="Fill-12" fill="#FFFFFF"></path>
                  <mask id="mask-4" fill="white">
                      <use href="#path-3"></use>
                  </mask>
                  <g id="Clip-15"></g>
                  <polygon id="Fill-14" fill="#FFFFFF" mask="url(#mask-4)" points="6.49579412 52.7437719 7.76752941 52.7437719 7.76752941 48.1999123 6.49579412 48.1999123"></polygon>
                  <path d="M10.3499735,51.6257368 C11.1053559,51.6257368 11.6087382,51.2118772 11.6087382,50.4787193 L11.6087382,50.4658246 C11.6087382,49.7394211 11.1053559,49.3157368 10.3499735,49.3157368 L9.83362059,49.3157368 L9.83362059,51.6257368 L10.3499735,51.6257368 Z M8.56867941,48.1994211 L10.3302088,48.1994211 C11.9620324,48.1994211 12.9070324,49.1345965 12.9070324,50.4455614 L12.9070324,50.4584561 C12.9070324,51.768807 11.9490618,52.7426667 10.30365,52.7426667 L8.56867941,52.7426667 L8.56867941,48.1994211 Z" id="Fill-16" fill="#FFFFFF" mask="url(#mask-4)"></path>
                  <path d="M15.7196735,50.9515263 L15.2101147,49.6614386 L14.6949971,50.9515263 L15.7196735,50.9515263 Z M14.6097618,48.1681053 L15.83085,48.1681053 L17.7752029,52.7432807 L16.4176147,52.7432807 L16.0871735,51.9315263 L14.3231735,51.9315263 L13.9964382,52.7432807 L12.6641735,52.7432807 L14.6097618,48.1681053 Z" id="Fill-17" fill="#FFFFFF" mask="url(#mask-4)"></path>
                  <polygon id="Fill-18" fill="#FFFFFF" mask="url(#mask-4)" points="18.5560941 51.0438772 16.8186529 48.1996667 18.2608588 48.1996667 19.2021529 49.8544912 20.1496235 48.1996667 21.5652706 48.1996667 19.8290647 51.022386 19.8290647 52.7435263 18.5560941 52.7435263"></polygon>
                  <path d="M23.62605,50.9515263 L23.1152559,49.6614386 L22.5995206,50.9515263 L23.62605,50.9515263 Z M22.5142853,48.1681053 L23.7372265,48.1681053 L25.6797265,52.7432807 L24.3227559,52.7432807 L23.9904618,51.9315263 L22.22955,51.9315263 L21.9015794,52.7432807 L20.5724029,52.7432807 L22.5142853,48.1681053 Z" id="Fill-19" fill="#FFFFFF" mask="url(#mask-4)"></path>
                  <path d="M62.6533147,42.4630439 L62.6533147,52.8967281 C62.6533147,56.482693 59.7225794,59.3969035 56.1173735,59.3969035 C55.2693441,59.3969035 54.4528147,59.2366404 53.6900206,58.9320789 C54.4367559,57.0089211 54.8499618,54.9224298 54.8499618,52.7395351 C54.8499618,48.8938333 53.5788441,45.3367281 51.4331382,42.4630439 L54.6572559,42.4630439 L54.6572559,6.98164035 L43.8224912,6.98164035 L43.8224912,36.6579561 C41.8565206,35.8879561 39.7151382,35.464886 37.4761676,35.464886 L37.4761676,46.2399737 C41.0807559,46.2399737 44.0145794,49.1554123 44.0145794,52.7395351 C44.0145794,56.3212018 41.0807559,59.2372544 37.4761676,59.2372544 C35.9759029,59.2372544 34.5645794,58.7497105 33.3990794,57.8188333 L26.6333735,66.2323421 C28.4764324,67.699886 30.6073147,68.7652368 32.8691382,69.3860263 L42.0974029,69.3860263 C43.7286088,68.9365526 45.2659324,68.2586579 46.6649029,67.386114 C48.0014912,68.2457632 49.4511088,68.9162895 50.9661971,69.3860263 L61.2963441,69.3860263 C68.2634029,67.2178684 73.3534324,60.7987456 73.4849912,53.1988333 C73.4880794,53.1988333 73.4886971,53.1969912 73.4886971,53.1969912 L73.4886971,42.4630439 L62.6533147,42.4630439 Z" id="Fill-20" fill="#FFFFFF" mask="url(#mask-4)"></path>
                  <path d="M11.9553,32.550614 C8.05053529,32.550614 4.87582941,29.3907895 4.87582941,25.5100877 C4.87582941,21.630614 8.05053529,18.4720175 11.9553,18.4720175 C15.8588294,18.4720175 19.0329176,21.630614 19.0329176,25.5100877 C19.0329176,29.3907895 15.8588294,32.550614 11.9553,32.550614 L11.9553,32.550614 Z M30.5693294,13.755 L30.5693294,23.1012281 C30.5693294,24.8997368 29.8312412,26.5281579 28.6410353,27.7046491 C28.7361529,26.9874561 28.7843294,26.2555263 28.7843294,25.5100877 C28.7843294,16.2842105 21.2348294,8.77701754 11.9553,8.77701754 C7.28341765,8.77701754 3.05191765,10.6823684 0.000123529412,13.7464035 L0.000123529412,37.275 C3.05191765,40.3408772 7.28341765,42.2437719 11.9553,42.2437719 C16.6883294,42.2437719 20.9698588,40.2911404 24.0315353,37.1552632 L24.0315353,40.3746491 C33.5099471,40.3746491 41.2404176,32.7882456 41.4010059,23.4008772 L41.4047118,23.3996491 L41.4047118,13.755 L30.5693294,13.755 Z" id="Fill-21" fill="#FFFFFF" mask="url(#mask-4)"></path>
                  <polygon id="Fill-22" fill="#FFFFFF" mask="url(#mask-4)" points="1.79908235 64.1211667 2.03440588 64.1211667 2.03440588 65.0428333 3.23943529 65.0428333 3.23943529 64.1211667 3.47661176 64.1211667 3.47661176 66.1972193 3.23943529 66.1972193 3.23943529 65.2620439 2.03440588 65.2620439 2.03440588 66.1972193 1.79908235 66.1972193"></polygon>
                  <path d="M5.85844412,65.1640439 L5.85844412,65.1579035 C5.85844412,64.6857105 5.51194412,64.3019386 5.03512059,64.3019386 C4.55706176,64.3019386 4.21612059,64.6814123 4.21612059,65.1523772 L4.21612059,65.1579035 C4.21612059,65.6307105 4.56323824,66.0157105 5.04006176,66.0157105 C5.51873824,66.0157105 5.85844412,65.6368509 5.85844412,65.1640439 M3.97276765,65.1640439 L3.97276765,65.1579035 C3.97276765,64.5862368 4.40388529,64.0851842 5.04006176,64.0851842 C5.67623824,64.0851842 6.10241471,64.5800965 6.10241471,65.1523772 L6.10241471,65.1579035 C6.10241471,65.7307982 5.66882647,66.2324649 5.03512059,66.2324649 C4.40079706,66.2324649 3.97276765,65.7369386 3.97276765,65.1640439" id="Fill-23" fill="#FFFFFF" mask="url(#mask-4)"></path>
                  <polygon id="Fill-24" fill="#FFFFFF" mask="url(#mask-4)" points="6.60067059 64.1211667 6.83784706 64.1211667 7.59940588 65.2534474 8.36034706 64.1211667 8.59875882 64.1211667 8.59875882 66.1972193 8.36281765 66.1972193 8.36281765 64.5129211 7.60311176 65.6261667 7.59014118 65.6261667 6.82858235 64.5178333 6.82858235 66.1972193 6.60067059 66.1972193"></polygon>
                  <polygon id="Fill-25" fill="#FFFFFF" mask="url(#mask-4)" points="9.18947647 64.1211667 10.6977706 64.1211667 10.6977706 64.3348509 9.42541765 64.3348509 9.42541765 65.0428333 10.5643588 65.0428333 10.5643588 65.2565175 9.42541765 65.2565175 9.42541765 65.9810789 10.7125941 65.9810789 10.7125941 66.1972193 9.18947647 66.1972193"></polygon>
                  <polygon id="Fill-26" fill="#FFFFFF" mask="url(#mask-4)" points="12.0810529 64.1211667 13.5831706 64.1211667 13.5831706 64.336693 12.3176118 64.336693 12.3176118 65.0759912 13.4485235 65.0759912 13.4485235 65.2915175 12.3176118 65.2915175 12.3176118 66.1972193 12.0810529 66.1972193"></polygon>
                  <polygon id="Fill-27" fill="#FFFFFF" mask="url(#mask-4)" points="14.0576471 66.1972807 14.2942059 66.1972807 14.2942059 64.1212281 14.0576471 64.1212281"></polygon>
                  <polygon id="Fill-28" fill="#FFFFFF" mask="url(#mask-4)" points="14.9048118 64.1211667 15.1271647 64.1211667 16.4415176 65.7839737 16.4415176 64.1211667 16.6725176 64.1211667 16.6725176 66.1972193 16.4835176 66.1972193 15.1358118 64.495114 15.1358118 66.1972193 14.9048118 66.1972193"></polygon>
                  <path d="M18.6152029,65.4371053 L18.1427029,64.3785088 L17.6658794,65.4371053 L18.6152029,65.4371053 Z M18.0346147,64.1046491 L18.2551147,64.1046491 L19.2069088,66.1972807 L18.9524382,66.1972807 L18.70785,65.6489474 L17.5707618,65.6489474 L17.3237029,66.1972807 L17.0822029,66.1972807 L18.0346147,64.1046491 Z" id="Fill-29" fill="#FFFFFF" mask="url(#mask-4)"></path>
                  <polygon id="Fill-30" fill="#FFFFFF" mask="url(#mask-4)" points="19.6191882 64.1211667 19.8372176 64.1211667 21.1552765 65.7839737 21.1552765 64.1211667 21.3838059 64.1211667 21.3838059 66.1972193 21.1985118 66.1972193 19.8483353 64.495114 19.8483353 66.1972193 19.6191882 66.1972193"></polygon>
                  <path d="M21.8807647,65.1640439 L21.8807647,65.1579035 C21.8807647,64.5715 22.323,64.0851842 22.9381765,64.0851842 C23.3167941,64.0851842 23.5428529,64.2184298 23.7528529,64.413693 L23.5910294,64.5862368 C23.4137647,64.4204474 23.2185882,64.3019386 22.9350882,64.3019386 C22.4712353,64.3019386 22.1278235,64.6746579 22.1278235,65.1523772 L22.1278235,65.1579035 C22.1278235,65.6399211 22.4755588,66.0157105 22.9350882,66.0157105 C23.2216765,66.0157105 23.4100588,65.9039561 23.6089412,65.7179035 L23.7621176,65.867114 C23.5465588,66.0875526 23.3075294,66.2324649 22.9270588,66.2324649 C22.3254706,66.2324649 21.8807647,65.7596579 21.8807647,65.1640439" id="Fill-31" fill="#FFFFFF" mask="url(#mask-4)"></path>
                  <polygon id="Fill-32" fill="#FFFFFF" mask="url(#mask-4)" points="24.1746441 64.1211667 25.6829382 64.1211667 25.6829382 64.3348509 24.4112029 64.3348509 24.4112029 65.0428333 25.5501441 65.0428333 25.5501441 65.2565175 24.4112029 65.2565175 24.4112029 65.9810789 25.6989971 65.9810789 25.6989971 66.1972193 24.1746441 66.1972193"></polygon>
                  <path d="M4.16621471,61.3775965 C4.16621471,61.7208421 4.05071471,62.0125088 3.82033235,62.2544386 C3.59365588,62.4963684 3.31880294,62.6191754 2.99577353,62.6191754 C2.67274412,62.6191754 2.39233235,62.4963684 2.16256765,62.2544386 C1.93156765,62.0125088 1.81730294,61.7208421 1.81730294,61.3720702 C1.81730294,61.1442632 1.81236176,60.844614 1.80680294,60.4688246 L2.10945,60.4688246 C2.10945,61.0337368 2.10945,61.3370702 2.11377353,61.3775965 C2.11377353,61.6367193 2.19839118,61.858386 2.37009706,62.0376842 C2.54365588,62.2188246 2.75118529,62.3121579 2.99577353,62.3121579 C3.23912647,62.3121579 3.44356765,62.2188246 3.61712647,62.034 C3.78883235,61.8485614 3.87468529,61.6287368 3.87036176,61.3677719 L3.84256765,58.7587368 L4.14953824,58.7587368 C4.15756765,60.0144386 4.16250882,60.8882105 4.16621471,61.3775965" id="Fill-33" fill="#FFFFFF" mask="url(#mask-4)"></path>
                  <path d="M5.06223529,59.6983333 L5.39020588,59.6983333 L5.39020588,59.3575439 L5.06223529,59.3575439 L5.06223529,59.6983333 Z M5.38155882,61.249386 C5.38155882,61.6331579 5.27470588,61.9463158 5.06223529,62.1974561 C4.85408824,62.4479825 4.57367647,62.5885965 4.22841176,62.615614 C4.19629412,62.5634211 4.15305882,62.4707018 4.095,62.3423684 C4.38405882,62.3423684 4.61444118,62.2398246 4.79541176,62.0427193 C4.97823529,61.844386 5.07088235,61.5932456 5.07088235,61.2985088 L5.06594118,60.0557018 L5.37167647,60.0557018 L5.38155882,61.249386 Z" id="Fill-34" fill="#FFFFFF" mask="url(#mask-4)"></path>
                  <path d="M6.82685294,61.659193 L6.44452941,61.659193 C6.321,61.659193 6.21414706,61.6149825 6.13076471,61.5314737 C6.03255882,61.4381404 5.97882353,61.3067368 5.97882353,61.1391053 L5.96585294,58.8082281 L6.27220588,58.8082281 L6.28579412,61.099807 C6.28579412,61.2625263 6.35682353,61.3546316 6.49455882,61.3779649 L6.82685294,61.3779649 L6.82685294,61.659193 Z" id="Fill-35" fill="#FFFFFF" mask="url(#mask-4)"></path>
                  <path d="M7.50008824,59.6983333 L7.833,59.6983333 L7.833,59.3575439 L7.50008824,59.3575439 L7.50008824,59.6983333 Z M8.64952941,61.6589474 L8.25423529,61.6589474 C8.12144118,61.6589474 8.01026471,61.6018421 7.91761765,61.4925439 C7.86388235,61.4305263 7.82867647,61.3685088 7.80582353,61.302193 C7.72244118,61.5195614 7.49638235,61.6460526 7.25179412,61.6589474 L6.67120588,61.6589474 L6.67120588,61.3771053 L7.16841176,61.3771053 C7.25179412,61.3771053 7.32714706,61.355 7.39817647,61.3114035 C7.4865,61.249386 7.53158824,61.1707895 7.53158824,61.0688596 C7.54023529,61.1388596 7.54023529,60.7992982 7.52664706,60.0507895 L7.833,60.0507895 C7.833,60.3332456 7.83670588,60.6114035 7.84226471,60.8877193 C7.84658824,61.0424561 7.89044118,61.1658772 7.97876471,61.2585965 C8.05473529,61.337193 8.13873529,61.3771053 8.22211765,61.3771053 L8.64952941,61.3771053 L8.64952941,61.4520175 L8.64952941,61.5895614 L8.64952941,61.6589474 Z" id="Fill-36" fill="#FFFFFF" mask="url(#mask-4)"></path>
                  <path d="M10.2048882,61.2317018 C10.2048882,61.0505614 10.1598,60.9013509 10.0671529,60.7914386 C9.98377059,60.6987193 9.88185882,60.6538947 9.76018235,60.6538947 C9.63727059,60.6538947 9.53535882,60.6987193 9.45877059,60.7914386 C9.37600588,60.8964386 9.33524118,61.0425789 9.33524118,61.2317018 C9.33524118,61.4165263 9.38465294,61.5626667 9.4773,61.6676667 C9.56191765,61.761 9.66382941,61.8101228 9.78365294,61.8101228 C9.90347647,61.8101228 10.0047706,61.761 10.0807412,61.6676667 C10.1647412,61.5626667 10.2048882,61.4165263 10.2048882,61.2317018 L10.2048882,61.2317018 Z M10.9720059,61.6590702 L10.4358882,61.6590702 C10.3154471,61.9286316 10.0980353,62.0612632 9.77994706,62.0612632 C9.47359412,62.0612632 9.25124118,61.9286316 9.11474118,61.6590702 L8.5428,61.6590702 L8.5428,61.3778421 L9.02209412,61.3778421 C9.01715294,61.3293333 9.01159412,61.2808246 9.01159412,61.2317018 C9.01159412,60.7202105 9.28768235,60.4076667 9.74844706,60.4076667 C9.98377059,60.4076667 10.1740059,60.4911754 10.3154471,60.6637193 C10.4488588,60.8227544 10.5155647,61.0124912 10.5155647,61.2317018 C10.5155647,61.2808246 10.5155647,61.3293333 10.5112412,61.3778421 L10.9627412,61.3778421 L10.9720059,61.6590702 Z" id="Fill-37" fill="#FFFFFF" mask="url(#mask-4)"></path>
                  <path d="M11.7827912,61.085807 C11.7827912,61.4376491 11.5703206,61.6593158 11.1799676,61.6593158 L10.8921441,61.6593158 L10.8921441,61.3774737 L11.1762618,61.3774737 C11.3479676,61.3774737 11.4764382,61.2749298 11.4764382,61.0778246 C11.4764382,60.7996667 11.4727324,60.420807 11.4690265,59.9455439 C11.4647029,59.3935263 11.4603794,59.0146667 11.4603794,58.8089649 L11.7661147,58.8089649 C11.7661147,59.0103684 11.7698206,59.388614 11.7741441,59.948614 C11.7790853,60.4257193 11.7827912,60.8045789 11.7827912,61.085807" id="Fill-38" fill="#FFFFFF" mask="url(#mask-4)"></path>
                  <polygon id="Fill-39" fill="#FFFFFF" mask="url(#mask-4)" points="12.6875206 61.659193 12.3811676 61.659193 12.35955 58.8082281 12.6659029 58.8082281"></polygon>
                  <path d="M17.1082059,61.659193 L16.7172353,61.659193 C16.5449118,61.659193 16.4071765,61.5707719 16.3058824,61.3957719 C16.3009412,61.7365614 16.1860588,62.0214737 15.9556765,62.2591053 C15.7246765,62.5016491 15.4547647,62.6201579 15.1342059,62.6201579 C14.8111765,62.6201579 14.5307647,62.4967368 14.3016176,62.2554211 C14.0706176,62.0122632 13.9557353,61.7162982 13.9557353,61.3724386 C13.9557353,61.1391053 13.9514118,60.8394561 13.9464706,60.469193 L14.2485,60.469193 C14.2485,61.0341053 14.2485,61.3374386 14.2522059,61.3779649 C14.2522059,61.6370877 14.3368235,61.8587544 14.5097647,62.0380526 C14.6833235,62.2185789 14.8914706,62.3112982 15.1342059,62.3112982 C15.3787941,62.3112982 15.5826176,62.2185789 15.7561765,62.0343684 C15.9278824,61.8483158 16.0137353,61.6291053 16.0087941,61.3687544 L15.9822353,58.7591053 L16.2879706,58.7591053 C16.2879706,59.2484912 16.2922941,59.9773509 16.3058824,60.9462982 C16.3102059,61.081386 16.3552941,61.1882281 16.4423824,61.2717368 C16.5183529,61.3429649 16.6023529,61.3779649 16.6869706,61.3779649 L17.1082059,61.3779649 L17.1082059,61.4516491 L17.1082059,61.589193 L17.1082059,61.659193 Z" id="Fill-40" fill="#FFFFFF" mask="url(#mask-4)"></path>
                  <path d="M17.6134412,62.3116667 L17.9420294,62.3116667 L17.9420294,61.9714912 L17.6134412,61.9714912 L17.6134412,62.3116667 Z M17.0680588,62.3116667 L17.3960294,62.3116667 L17.3960294,61.9714912 L17.0680588,61.9714912 L17.0680588,62.3116667 Z M18.1100294,61.0854386 C18.1100294,61.4385088 17.8975588,61.6589474 17.5065882,61.6589474 L16.9266176,61.6589474 L16.9266176,61.3771053 L17.5035,61.3771053 C17.6758235,61.3771053 17.8036765,61.2751754 17.8036765,61.0786842 C17.8036765,61.1167544 17.7999706,60.7790351 17.7900882,60.0507895 L18.0964412,60.0507895 C18.1057059,60.7471053 18.1100294,61.0903509 18.1100294,61.0854386 L18.1100294,61.0854386 Z" id="Fill-41" fill="#FFFFFF" mask="url(#mask-4)"></path>
                  <path d="M19.8257912,61.3775965 L19.8257912,60.6419825 C19.8257912,60.3810175 19.6973206,60.249 19.4706441,60.249 C19.3508206,60.249 19.2526147,60.2981228 19.16985,60.3994386 C19.0772029,60.5081228 19.0234676,60.6579474 19.0234676,60.8482982 C19.0234676,61.1442632 19.1871441,61.3775965 19.4484088,61.3775965 L19.8257912,61.3775965 Z M20.7565853,61.6588246 L20.0827324,61.6588246 C19.9456147,62.2071579 19.5422912,62.5669825 18.9783794,62.6161053 C18.9518206,62.5577719 18.9085853,62.4705789 18.8499088,62.3428596 C19.0722618,62.3428596 19.2674382,62.2808421 19.4311147,62.1531228 C19.5861441,62.0290877 19.7010265,61.8663684 19.7677324,61.6588246 L19.36935,61.6588246 C19.1649088,61.6588246 19.00185,61.5839123 18.8733794,61.4297895 C18.7436735,61.2750526 18.6911735,61.0859298 18.6911735,60.8697895 C18.6948794,60.5836491 18.79185,60.3675088 18.9647912,60.2176842 C19.1037618,60.0997895 19.2624971,60.0426842 19.4403794,60.0426842 C19.9011441,60.0426842 20.1321441,60.2747895 20.1321441,60.7371579 L20.1321441,61.3775965 L20.7565853,61.3775965 L20.7565853,61.6588246 Z" id="Fill-42" fill="#FFFFFF" mask="url(#mask-4)"></path>
                  <path d="M22.2960706,61.2317018 C22.2960706,61.0505614 22.2503647,60.9013509 22.1577176,60.7914386 C22.0737176,60.6987193 21.9718059,60.6538947 21.8519824,60.6538947 C21.7278353,60.6538947 21.6259235,60.6987193 21.5499529,60.7914386 C21.4659529,60.8964386 21.4264235,61.0425789 21.4264235,61.2317018 C21.4264235,61.4165263 21.4752176,61.5626667 21.5678647,61.6676667 C21.6518647,61.761 21.7537765,61.8101228 21.8742176,61.8101228 C21.9934235,61.8101228 22.0965706,61.761 22.1713059,61.6676667 C22.2546882,61.5626667 22.2960706,61.4165263 22.2960706,61.2317018 L22.2960706,61.2317018 Z M23.0625706,61.6590702 L22.5252176,61.6590702 C22.4066294,61.9286316 22.1892176,62.0612632 21.8705118,62.0612632 C21.5629235,62.0612632 21.3430412,61.9286316 21.2053059,61.6590702 L20.6321294,61.6590702 L20.6321294,61.3778421 L21.1120412,61.3778421 C21.1071,61.3293333 21.1027765,61.2808246 21.1027765,61.2317018 C21.1027765,60.7202105 21.3776294,60.4076667 21.8390118,60.4076667 C22.0737176,60.4076667 22.2639529,60.4911754 22.4066294,60.6637193 C22.5400412,60.8227544 22.6055118,61.0124912 22.6055118,61.2317018 C22.6055118,61.2808246 22.6055118,61.3293333 22.6011882,61.3778421 L23.0533059,61.3778421 L23.0625706,61.6590702 Z" id="Fill-43" fill="#FFFFFF" mask="url(#mask-4)"></path>
                  <path d="M23.9931176,59.6940351 L24.3254118,59.6940351 L24.3254118,59.3532456 L23.9931176,59.3532456 L23.9931176,59.6940351 Z M23.4526765,59.6940351 L23.7806471,59.6940351 L23.7806471,59.3532456 L23.4526765,59.3532456 L23.4526765,59.6940351 Z M24.8615294,61.6589474 L24.4674706,61.6589474 C24.3359118,61.6589474 24.2235,61.6018421 24.1302353,61.4925439 C24.0771176,61.4305263 24.0425294,61.3685088 24.0202941,61.302193 C23.9362941,61.5195614 23.709,61.6460526 23.4650294,61.6589474 L22.974,61.6589474 L22.974,61.3771053 L23.1290294,61.3771053 C23.2618235,61.372193 23.3458235,61.372193 23.3810294,61.372193 C23.4650294,61.372193 23.5403824,61.3507018 23.6107941,61.3064912 C23.7003529,61.249386 23.7442059,61.1707895 23.7442059,61.0688596 C23.7540882,61.1388596 23.7540882,60.7992982 23.7405,60.0507895 L24.0462353,60.0507895 C24.0462353,60.3332456 24.0505588,60.6114035 24.0555,60.8877193 C24.0592059,61.0424561 24.1042941,61.1658772 24.1926176,61.2585965 C24.2679706,61.337193 24.3525882,61.3771053 24.4365882,61.3771053 L24.8615294,61.3771053 L24.8615294,61.4520175 L24.8615294,61.5895614 L24.8615294,61.6589474 Z" id="Fill-44" fill="#FFFFFF" mask="url(#mask-4)"></path>
                  <path d="M25.6644706,61.085807 C25.6644706,61.4376491 25.452,61.6593158 25.0610294,61.6593158 L24.7738235,61.6593158 L24.7738235,61.3774737 L25.0573235,61.3774737 C25.2296471,61.3774737 25.3581176,61.2749298 25.3581176,61.0778246 C25.3581176,60.7996667 25.3537941,60.420807 25.3494706,59.9455439 C25.3457647,59.3935263 25.3408235,59.0146667 25.3408235,58.8089649 L25.6471765,58.8089649 C25.6471765,59.0103684 25.6508824,59.388614 25.6552059,59.948614 C25.6607647,60.4257193 25.6644706,60.8045789 25.6644706,61.085807" id="Fill-45" fill="#FFFFFF" mask="url(#mask-4)"></path>
              </g>
          </g>
      </g>
    </svg>
  );
}

export default LogoWhite;