import React, { useEffect } from "react";
import { getMaster } from "services/masterData";
import { addMasterData } from "context/appraisal/AppraisalReducer";

const Appraisal = React.createContext();
Appraisal.displayName = "Appraisal";

export const useAppraisal = () => React.useContext(Appraisal);

const masteArray = [
  "title",
  "cities",
  "countries",
  "regions",
  "neighbourhood"
];

export const AppraisalProvider = ({ children, initialState, reducer }) => {
  const [globalState, dispatch] = React.useReducer(reducer, initialState);
  useEffect(() => {
    masteArray.forEach((index) => {
      if (!globalState.masterData.index) {
        getMaster(index).then((data) => {
          dispatch(addMasterData({ [index]: data }));
        });
      }
    });
  }, []);

  return (
    <Appraisal.Provider value={[globalState, dispatch]}>
      {children}
    </Appraisal.Provider>
  );
};
