import React, { useState, useContext, useEffect } from "react";
import {
  Redirect,
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { AuthenticationContext } from "context/Authentication";
import { langUrl } from "services/lang";
import storage from "services/storage";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const [fetched, setFetched] = useState(false);
  const { user, userlogout } = useContext(AuthenticationContext);
  console.info("context", user?.role, user?.role === "bidaya_frontend_user");
  const bidaya_auth_user = storage.get('bidaya_auth_user');
  useEffect(() => {
    if (user?.role !== undefined && user?.role !== "bidaya_frontend_user") {
      console.info("invalida user", user?.role);
      //userlogout();
    }
  }, [user]); 
  const location = useLocation();
  let path = langUrl("login");
  // Storing the previous path when redirecting to login, so that if
  // user is logged in, we can redirect back to that path.
  let fromPath = location.pathname + location.search + location.hash;
  if (location?.state?.from && location?.state?.from !== path) {
    fromPath = location?.state?.from;
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        // logic for authenticated user to access /dashboard part goes here.
        // e.g. check if user is logged-in logic.
        return user && user?.role === "bidaya_frontend_user" ? (
          <Component {...props} />
        ) : user && user?.role === "bidaya_takaful_user" ? (
          <Redirect to={langUrl(`takaful/overview`)} />
        ) : bidaya_auth_user ? (
          <Redirect to={{pathname: path, state: { from: fromPath } }}  />
        ) : (
          <Redirect to={{pathname: path, state: { from: fromPath } }}  />
        )
      }}
    />
  );
};
