import React, { useContext } from "react";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import LangLink from "components/lang/LangLink";
import { AuthenticationContext } from "context/Authentication";

function HeaderNav() {
  const { t } = useTranslation([
    "dashboard",
    "common",
    "validation",
    "translation",
    "appFormStage2",
    "takaful",
  ]);
  const {accountState, appraisalLogout } = useContext(AuthenticationContext);
  return (
    <>
      <div className="page-title">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex justify-content-between">
              <div className="title">
                <b>{t("takaful:Dear")} {accountState?.application_list?.user_info?.firstname ? accountState?.application_list?.user_info?.firstname : t('User')},</b>
                {t("takaful:WELCOME_BACK")}
              </div>
              <div className="text-right">
                <LangLink to="/appraisal/dashboard">{t("DASHBOARD")}</LangLink>
                {"  "} |{" "}
                <Button
                  variant="text"
                  color="secondary"
                  onClick={() => appraisalLogout()}
                >
                  {t("LOGOUT")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeaderNav;
