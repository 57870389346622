//custom classes styles
import { makeStyles } from '@material-ui/core/styles';
import Globals from 'context/cms/globals';

const globalUseStyles = makeStyles((theme) => ({
  tooltipMed:{
    maxWidth:"280px",
    marginBottom:"-5px",
  },
  accordform:{
    marginTop:"0",
    marginBottom:".5rem",
    backgroundColor: theme.palette.background.paper,
    borderRadius:theme.shape.borderRadius,
    "&.Mui-expanded":{
      marginTop:"0",
      marginBottom:"0.625rem",
    },
    "& .MuiButtonBase-root":{
      "&.Mui-expanded":{
        minHeight:"unset",
        "& .MuiAccordionSummary-content":{
          "&.Mui-expanded":{
            margin: "0",
          }
        }
      },
      "& .MuiAccordionSummary-content":{
        margin: "0",
        padding: "12px 0",
      }
    }
  },
  accordLabelPrimary:{
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    "& .MuiButtonBase-root":{
      color: theme.palette.common.white,
    },
    [theme.breakpoints.down('sm')]: {

    }
  },
  accordLabelSecondary:{
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    '& .MuiButtonBase-root':{
      color: theme.palette.common.white,
    }
  },
  // root:{
  //   width: "100%",
  //   height: "100%",
  //   backgroundColor: theme.palette.background.paper
  // },
  // redButton:{
  //   color:'red',
  //   background:'#fff',
  //   border:'1px solid red',
  //   '&:hover':{
  //     background:'red',
  //     color:'#fff'
  //   }
  // },
  // greentButton:{
  //   color:'green',
  //   background:'#fff',
  //   border:'1px solid green',
  //   '&:hover':{
  //     background:'green',
  //     color:'#fff'
  //   }
  // },
  btnBold:{
    fontWeight:700
  },
  // @global is handled by jss-plugin-global.
  '@global': {
    // You should target [class*="MuiButton-root"] instead if you nest themes.
    '.MuiButton-root': {
      // fontSize: '1rem',
    },
    '[dir="rtl"]':{
      '& .MuiFormControl-root .MuiFormLabel-root':{
        left:'unset',
        // right:'2',
        // transformOrigin: 'top right',
        '&.MuiInputLabel-shrink':{
          // transformOrigin: `top ${[Globals.directions == 'rtl' ? 'right' : 'left']}`,
          // transformOrigin: 'top right'
        }
      },
      '& .MuiIconButton-edgeEnd':{
        marginRight:'unset',
        marginLeft: '-12px',
      },
      '& .MuiButton-endIcon':{
        marginRight: '8px',
        marginLeft: '-4px'
      }
    }
    
  },
}));

export default globalUseStyles