import React, { useState, useContext, useEffect } from "react";
import {
  Redirect,
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { AuthenticationContext } from "context/Authentication";
import { langUrl } from "services/lang";
import storage from "services/storage";

export const AppraisalRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const [fetched, setFetched] = useState(false);
  const { user, userlogout } = useContext(AuthenticationContext);
  const bidaya_auth_user = storage.get('bidaya_auth_user');
  const location = useLocation();
  let path = langUrl('appraisal/login');
  // Storing the previous path when redirecting to login, so that if
  // user is logged in, we can redirect back to that path.
  let fromPath = location.pathname + location.search + location.hash;
  if (location?.state?.from && location?.state?.from !== path) {
    fromPath = location?.state?.from;
  }
  useEffect(() => {
    if (user?.role !== undefined && user?.role !== "bidaya_appraisal_user") {
      console.info("invalid redf user", user?.role);
      //userlogout();
    }
  }, [user]);

  return (
    <Route
      {...rest}
      render={(props) => {

        return (user && user?.role === "bidaya_appraisal_user") ? (
          <Component {...props} />
        )  : (
          <Redirect to={{pathname: path, state: { from: fromPath } }}  />
        );
      }}
    />
  );
};
