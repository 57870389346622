import React, { useEffect, useState, lazy, Suspense } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import axios from "axios";
import Components from "./blocks";
import Loader from "components/uihelper/Loader";
import useIsServer from "hooks/useIsServer";
import feRoutes from "shared/feRoutes";
import config from "config";
import Meta from "cms/blocks/common/Meta/index";
import LoaderIcon from "assets/images/LoaderIcon";

const Footer = lazy(() => import("./blocks/common/Footer"));

let path_wo_lang = "";

/**
 * Calls block layout api
 * @param {*} path
 * @returns block layout api data response
 */
export const getLayout = (path, langCode) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${config.api.domain}/block-layout?path=${path}&_format=json&language=${langCode}`
      )
      .then((response) => {

        resolve(response?.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Generates random string
 * @param {*} length
 * @returns random string
 */
const generateUid = (length) => {
  var randomChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var result = "";
  for (var i = 0; i < length; i++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return result;
};

/**
 * Removes specific keyword to get basename
 * @param {*} blockName
 * @returns block basename
 */
const getBasename = (blockName) => {
  let str = blockName.split("_cmsblock_").slice(0, -1).join();

  if (str) {
    blockName = str;
  }
  console.log("basename", blockName);
  return blockName;
};

/**
 * Builds element based on given block or component
 * @param {*} block
 * @returns created element
 */
const createComponent = (block) => {
  let blockName = getBasename(block[0]);
  if (typeof Components[blockName] !== "undefined") {
    return React.createElement(Components[blockName], {
      key: generateUid(10),
      block: block[1],
      full_layout: typeof block[2] !== "undefined" ? block[2] : null,
    });
  }
};


let renderCount = 0;
export const Main = (prop) => {
  const location = useLocation();
  path_wo_lang = getPathWoLang(location.pathname, location?.search);
  console.log("server data static", prop, location?.search);
  renderCount++;
  console.log('render Count' + renderCount);
  const IsServer = useIsServer();

  let bdata = !IsServer ? window.__INITIAL_DATA__ : prop?.data;
  const fetchNewData = React.useRef(bdata ? false : true);
  if (!bdata) {
    bdata = {
      header: {},
      content: {},
      footer: {},
    };
  }

  const [blockData, setBlockData] = React.useState(() => {
    return bdata;
  });

  const pathArr = location.pathname.split("/");

  const lodDumFun =() => {
    return(
      <></>
    );
  }
  const [loadisTrue, setLoadIsTrue] = useState(false);
  const isFirefox = typeof InstallTrigger !== 'undefined';
  useEffect(() => {
    let ms = isFirefox ? 3500 : 3000;
    let timeoutId = setTimeout(() => {
      setLoadIsTrue(true);
    }, ms);
     return () => clearTimeout(timeoutId);
  },[]);

  useEffect(() => {
    if (checkFeRoute(path_wo_lang)) {
      return;
    }
    if (fetchNewData.current === true) {
      //if (blockData && !Object.keys(blockData.header).length) {
      getLayout(path_wo_lang, pathArr[1])
        .then((data) => {
          setBlockData(data);
        })
        .catch((err) => {
          console.log("Server error: " + err);
          return err;
        });
      // }
    } else {
      fetchNewData.current = true;
    }
  }, [path_wo_lang, fetchNewData]);

  const SsrCompatibleSuspense = process.browser
    ? Suspense
    : (props) => props?.children;

    return (
      <>
      <div className={`Main ${!loadisTrue ? 'loader-progress' : ''}`}>
        {/* {loadisTrue} */}
        <SsrCompatibleSuspense fallback={lodDumFun()}>
          {blockData?.content?.bidaya_content && (
            <Meta key="meta" block={blockData} />
          )}
          {Object.keys(blockData).map((region) => {
            return Object.entries(blockData[region]).map((block) => {
              block.push(blockData);
              let blockName = getBasename(block?.[0]);
              return (
                <Components
                  key={generateUid(10)}
                  blockName={blockName}
                  id={generateUid(10)}
                  block={block?.[1]}
                  full_layout={
                    typeof block?.[2] !== "undefined" ? block?.[2] : null
                  }
                />
              );
              // return createComponent(block);
            });
          })}
          {blockData?.content?.bidaya_content && (
          <Footer key="footer" block={blockData} />
          )}
        </SsrCompatibleSuspense>
        
      </div>

      {!loadisTrue && (
        <div className="main-loader">
          <div className="loader-logo">
            <LoaderIcon renderCount={renderCount}/>
          </div>
        </div>
      )}

      </>
    );
};

export const getPathWoLang = (path = "", search = "") => {
  // get current language
  const pathArr = path.split("/");
  const currentLanguageCode = pathArr[1] === "en" ? "en" : "ar";

  // get path without language
  path_wo_lang = path.replace(new RegExp("^/" + currentLanguageCode), "");
  path_wo_lang = path_wo_lang == "" ? "/" : path_wo_lang;
  if (path_wo_lang !== "/:lang") {
    path_wo_lang = path_wo_lang == "" ? "/" : path_wo_lang + search;
  }
  return path_wo_lang;
};

export const checkFeRoute = (path = "") => {
  const feRoute =
    feRoutes.find((route) => {
      let fepath = route.path;
      if (route.match === "exact") {
        return fepath === path;
      } else if (route.match === "startsWith") {
        return path.startsWith(fepath);
      }
    }) || {};
  return Object.keys(feRoute).length === 0 ? false : true;
};
