import DOMPurify from "isomorphic-dompurify";
import React, { useState } from "react";
import { isMobile, isMobileOnly, isTablet } from "react-device-detect";
import { useTranslation } from 'react-i18next'

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//forms
import Enquiries from 'cms/components/Forms/enquires';
import Applications from 'cms/components/Forms/applications';
import globalUseStyles from 'theme/GlobalStyles';
import { elementScrollIntoView } from "seamless-scroll-polyfill";

require("styles/components/home/featured-icons.scss");

const FeaturedIcons = (props) => {
  const { t } = useTranslation();
  const data = props?.block?.data;
  const [expanded, setExpanded] = useState(false);
  const gClasses = globalUseStyles()
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if(isMobileOnly){
      if(isExpanded){
        setTimeout(() => {
          elementScrollIntoView(event.target, { behavior: "smooth", block: "start" });
        }, 380);
      }
    }
  };

  return (
    <>
    <section className="section-featured-icons home-render-2">
      <div className="container">
        <div className="row justify-content-around icons-wrapper-row">
          { 
            data?.field_images.map((item, pos) => {
              return (
                <dl key={pos} className="col-4 col-sm-2">
                  <dt><img src={item.url} alt={item.alt} /></dt>
                  <dd  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data?.field_links?.[pos]?.title) }}></dd>
                </dl>
              );
            })
          }
        </div>
      </div>
    </section>
    </>
  );
}

export default FeaturedIcons;