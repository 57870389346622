import React from 'react';
import ProductBanner from 'cms/blocks/banner/BannerProduct';
import BasicContent from 'cms/blocks/common/BasicContent';

const BannerIntroContent = (props) => {
  const main_content = props.full_layout.content.bidaya_content;
  const full_layout = props.full_layout;

  return (
    <>
    <ProductBanner data={main_content} />
    { 
      main_content?.entity?.body?.length > 0 && (
      <BasicContent data={main_content} full_layout={full_layout} /> 
      ) 
    }
    </>
  );
}

export default BannerIntroContent;