import React from 'react';
import { isMobileOnly } from "react-device-detect";
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch, NavLink } from "react-router-dom";

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

//mobile select start
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//mobile select end

import LangNavLink from 'components/lang/LangNavLink';

import { useTranslation } from 'react-i18next';

require("styles/components/common/page-side-menu.scss");

const PageSideMenu = ({ current, ...otherProps }) => {
  const isMobile = isMobileOnly;
  const { path, url } = useRouteMatch();

  const { t } = useTranslation();
  var items = [
    { label: t("who_we_are"), link: "/about-bidaya/who-we-are" },
    { label: t("board_dir"), link: "/about-bidaya/board-of-directors" },
    { label: t("exe_man"), link: "/about-bidaya/executive-management" },
    { label: t("exe_com"), link: "/about-bidaya/executive-committee" },
    { label: t("audit_com"), link: "/about-bidaya/audit-committee" },
    { label: t("risk_cred"), link: "/about-bidaya/risk-credit-committee" },
    { label: t("nom_rem"), link: "/about-bidaya/nomination-remuneration-committee" },
    { label: t("media"), link: "/about-bidaya/media" },
    { label: t("shar_com"), link: "/about-bidaya/sharia-committee" },
    { label: t("fin_rep"), link: "/about-bidaya/financial-reports" },
    { label: t("soc_res"), link: "/about-bidaya/social-responsibility" },
    { label: t("DEVELOPERS"), link: "/about-bidaya/developers" },
  ];

  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  //dropdown select
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="page-side-menu">
      {!isMobile > 0 &&
        <List component="nav" aria-label="">
          {items.map((item, pos) => {
            return (
              <ListItem
                button
                key={pos + 1}

                onClick={(event) => handleListItemClick(event, pos + 1)}
                component={LangNavLink} to={item.link}
              >
                <ListItemText primary={item.label} />
                <ListItemIcon>
                  <NavigateNextIcon />
                </ListItemIcon>
              </ListItem>
            );
          })}
        </List>
      }

      {isMobile > 0 &&
        <div className="select-cal">
          <Button
            onClick={handleClick}
            variant="contained"
            fullWidth size="large"
            endIcon={<ExpandMoreIcon />}
          >
            {current}
          </Button>
          <Menu
            className="select-opts"
            anchorEl={anchorEl}
            // keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            elevation={0}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            getContentAnchorEl={null}
          >
            {items.map((item, pos) => {
              return (
                <MenuItem key={pos + 1} onClick={handleClose} component={LangNavLink} to={item.link}>
                  <span className="desc">
                    {item.label}
                  </span>
                </MenuItem>
              );
            })}
          </Menu>
        </div>
      }

    </div>
  );
}

export default PageSideMenu;