import React from "react";
import { isMobileOnly } from "react-device-detect";
import { useTranslation } from 'react-i18next';

require("styles/components/home/partners.scss");

const Logos = (props) => {
  const data = props.block.data;
  const isMobile = isMobileOnly;
  const { t,i18n } = useTranslation(["translation"]);
  return (
    <section className="section-partners home-section">
      <div className="container">
      {  isMobileOnly &&
      <div className="home-partner-title">
        <h2>{t('OUR_PARTNERS')}</h2>
      </div>
      }
        <div className="row justify-content-around">
          {data.rows.map((item, pos) => {
              return (
                <div key={pos} className="col-6 col-sm d-flex justify-content-center col-img">
                  <figure className="mb-0">
                    <img src={item.field_image[0].url} alt={item.field_image[0].alt} />
                  </figure>
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
}

export default Logos;