import React, { useState } from "react";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import { InputAdornment } from "@material-ui/core";
import { useField, useFormikContext } from "formik";
// import { InputLabel } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import InputLabel from '@material-ui/core/InputLabel';

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  const arabicNumeral = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩']
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}      
      isNumericString
      customNumerals={arabicNumeral}
    />
  );
}

const NumberOnly = ({
  name,
  currency,
  label,
  areaClass,
  areaClassAR,
  setCurrencyVal,
  required = false,
  hideErrorText = false,
  ...otherProps
}) => {
  const [values, setValues] = useState({
    numberformat: "",
  });
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
    setFieldValue(name, event.target.value);
    if (setCurrencyVal !== undefined) {
      setCurrencyVal(event.target.value);
    }
  };

  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: true,
    onChange: handleChange,
  };

  if (meta && meta.touched && meta.error) {
    configTextfield.error = true;
    configTextfield.helperText = meta.error;
  }

  return (
    <>
      <div className="row row-narrow">
        <div className="col">
          <TextField
            type="tel"
            label={label}
            {...configTextfield}
            required
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment:  (
                currency &&
                <InputAdornment position="end">{currency}</InputAdornment>
              ),
            }}
            // Hide the error if component is alongside another component eg. slider.
            FormHelperTextProps={ hideErrorText ? {style: {display: "none"}} : {} }
          />
        </div>
      </div>
    </>
  );
};

export default NumberOnly;
