import React,{ useContext,useState } from "react";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { isMobileOnly } from "react-device-detect";
import { CmsContext } from "context/cms/CmsContext";

import CallIcon from '@material-ui/icons/Call';

import Fab from '@material-ui/core/Fab';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YoutubeIcon from '@material-ui/icons/YouTube';
import CopyrightIcon from '@material-ui/icons/Copyright';

import LangLink from 'components/lang/LangLink';

import config from 'config';

require('styles/common/footer.scss');

var curindex = 0;
const Footer = (props) => {
  
  //submenu toggle
  const [MenuState, setMenuState] = useState();
  const { t, i18n } = useTranslation(['translation', 'products'])

  const toggleMenu = (index) => {
    console.log("toggleMenu")
    if(curindex===index){
      console.log("close");
      setMenuState();
      curindex = 0;
    }else{
      console.log("not close")
      setMenuState(index);
      curindex = index;
    }
  };

  return (
    <footer className="footer-wr">
      <div className="footer">
        <div className="container">

          <div className="row">

            <div className="col-12 col-sm col-menu">
              <div className={MenuState === 1 ? "group-links active" : "group-links"}>
                <h1 className="title" onClick={() => toggleMenu(1)}>
                  {t("ABOUT_US")}
                </h1>
                <ul className="list-unstyled">
                  <li>
                    <LangLink to='/about-bidaya/who-we-are'>
                      <span className="text">{t("who_we_are")}</span>
                    </LangLink>
                  </li>
                  <li>
                    <LangLink to='/about-bidaya/developers'>
                      <span className="text">{t("DEVELOPERS")}</span>
                    </LangLink>
                  </li>
                   <li>
                    <LangLink to='/projects'>
                      <span className="text">{t("OUR_PROJECTS")}</span>
                    </LangLink>
                  </li> 
                  <li>
                    <LangLink to="/about-bidaya/media">
                      <span className="text">{t("media")}</span>
                    </LangLink>
                  </li>
                  <li>
                    <LangLink to='/about-bidaya/financial-reports'>
                      <span className="text">{t("fin_rep")}</span>
                    </LangLink>
                  </li>
                  <li>
                    <LangLink to='/about-bidaya/social-responsibility'>
                      <span className="text">{t("soc_res")}</span>
                    </LangLink>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-12 col-sm col-menu">
              <div className={MenuState === 2 ? "group-links active" : "group-links"}>
                <h1 className="title" onClick={() => toggleMenu(2)}>
                  {t("PRODUCT")}
                </h1>
                <ul className="list-unstyled">
                <li>
                    <LangLink to='/products/ready-unit'>
                      <span className="text">{t("products:Ready Unit Financing")}</span>
                    </LangLink>
                  </li>
                  <li>
                    <LangLink to='/products/offplan'>
                      <span className="text">{t("products:Offplan unit Financing")}</span>
                    </LangLink>
                  </li>
                  <li>
                    <LangLink to='/products/residential'>
                      <span className="text">{t("products:Residential Land Financing")}</span>
                    </LangLink>
                  </li>
                  <li>
                    <LangLink to='/products/buy-lease'>
                      <span className="text">{t("products:Buy and Lease Back")}</span>
                    </LangLink>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-12 col-sm col-menu">
              <div className={MenuState === 3 ? "group-links active" : "group-links"}>
                <h1 className="title" onClick={() => toggleMenu(3)}>
                  {t("HOME_FINANCING")}
                </h1>
                <ul className="list-unstyled">
                  <li>
                    <LangLink to='/calculators/what-is-the-maximum-i-can-finance'>
                      <span className="text">{t("Calculators")}</span>
                    </LangLink>
                  </li>
                </ul>
              </div>
              <div className={MenuState === 4 ? "group-links active" : "group-links"}>
                <h1 className="title" onClick={() => toggleMenu(4)}>
                  {t("BIDAYA_ONLINE")}
                </h1>
                <ul className="list-unstyled">
                  <li>
                   <LangLink to='/login'>
                      <span className="text">{t('Login')}</span>
                    </LangLink>
                  </li>
                  <li>
                    <LangLink to='/application/stage-01/basic-information#1'>
                      <span className="text">{t("ONLINE_APPLICATION")}</span>
                    </LangLink>
                  </li>
                  <li>
                    <LangLink to='/payment-methods'>
                      <span className="text">{t("PAY_METHODS")}</span>
                    </LangLink>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-12 col-sm col-menu">
              <div className={MenuState === 5 ? "group-links active" : "group-links"}>
                <h1 className="title" onClick={() => toggleMenu(5)}>
                  {t("Contact_us")}
                </h1>
                <ul className="list-unstyled">
                  <li>
                    <LangLink to='/contact-us/customer-care'>
                      <span className="text">{t("customer_care")}</span>
                    </LangLink>
                  </li>
                  <li>
                    <LangLink to='/contact-us/careers'>
                      <span className="text">{t("careers")}</span>
                    </LangLink>
                  </li>
                  <li>
                    <LangLink to='/contact-us/faq'>
                      <span className="text">{t("faq")}</span>
                    </LangLink>
                  </li>
                </ul>
              </div>
            </div>
            
            <div className="col-12 col-sm col-contact">
              <a href="tel:8001181111" className="link-phone">
                <CallIcon />
                <span className="text en">8001181111</span>
              </a>
              <div className="social-wr">
              <Fab aria-label="facebook" target="_blank" href="https://www.facebook.com/BidayaHomeFinance/">
                <FacebookIcon />
              </Fab>
              <Fab aria-label="youtube" target="_blank" href="https://www.youtube.com/channel/UCFh9UCowcUQEijljSNviPcA">
                <YoutubeIcon />
              </Fab>
              <Fab aria-label="twitter" target="_blank" href="https://twitter.com/BidayaKsa">
                <TwitterIcon />
              </Fab>
              <Fab aria-label="linkedin" target="_blank" href="https://www.linkedin.com/company/bidaya-home-finance/">
                <LinkedInIcon />
              </Fab>
              </div>
            </div>
          </div>
          
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-sm-6 col-link">
              <ul >
                <li >
                  <LangLink to='/know-your-rights'>
                  {t("KNOW_YOUR_RIGHTS")}
                  </LangLink>
                </li>
                <li >
                  <LangLink to='/download'>
                    {t("DOWNLOAD_DOC")}
                  </LangLink>
                </li>
                <li >
                  <LangLink to='/privacy-policy'>
                  {t("PRIVACY_POLICY")}
                  </LangLink>
                </li>
                <li >
                  <LangLink to='/terms-and-conditions'>
                    {t("TERMS_COND")}
                  </LangLink>
                </li>
              </ul>
            </div>
            <div className="col-12 col-sm-6 col-copyright">
              <p className="desc">
                <CopyrightIcon/>
                {t("COPYRIGHT", {year: 2021})}
              </p>
            </div>
          </div>
        </div>
        
      </div>
    </footer>
  );
}

export default Footer;