import React from "react";
import { Helmet } from "react-helmet";
import config from "config";

const Meta = (props) => {

  return (
    <Helmet>
        <meta charSet="utf-8" />
        <title>{props?.block?.content.bidaya_content?.entity?.field_meta_tags?.[0]?.value?.title ? props.block.content.bidaya_content.entity.field_meta_tags[0].value.title : ''} </title>
        <link rel="canonical" href={props?.block?.content?.bidaya_content?.entity?.field_meta_tags?.[0]?.value?.canonical_url ? config?.api?.domain + props?.block?.content?.bidaya_content?.entity?.field_meta_tags?.[0]?.value?.canonical_url : ''} />
        <meta name="description" content={props?.block?.content.bidaya_content?.entity?.field_meta_tags?.[0]?.value?.description ? props.block.content.bidaya_content.entity.field_meta_tags[0].value.description : ''} />
        <meta name="keywords" content={props?.block?.content.bidaya_content?.entity?.field_meta_tags?.[0]?.value?.keywords ? props.block.content.bidaya_content.entity.field_meta_tags[0].value.keywords : ''} />
        <meta property="og:title" content={props?.block?.content.bidaya_content?.entity?.field_meta_tags?.[0]?.value?.title ? props.block.content.bidaya_content.entity.field_meta_tags[0].value.title : ''} />
        <meta property="og:site_name" content="Bidaya Finance" />
        <meta property="og:url" content={config?.api?.domain + '/' + props?.block?.content.bidaya_content?.entity?.langcode?.[0]?.value + props?.block?.content.bidaya_content?.entity?.path?.[0]?.alias} />
        <meta property="og:description" content={props?.block?.content.bidaya_content?.entity?.field_meta_tags?.[0]?.value?.description ? props.block.content.bidaya_content.entity.field_meta_tags[0].value.description : ''} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.bidaya.com.sa/sites/default/files/2022-08/home-banner-1_0.png" />
    </Helmet>  
  );
}

export default Meta;