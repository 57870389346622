import React from 'react'
import GifLoader from "assets/images/splashscreen-dot-latest.gif";
const isFirefox = typeof InstallTrigger !== 'undefined';
const LoaderIcon = ({ width = 200, height = 200, renderCount = 0 }) => {
  return (
    <div>
      {!isFirefox && (
        <img src={GifLoader}  />
      )}
      {isFirefox && renderCount > 2 && (
        <img src={GifLoader+'?'+Math.random()}  />
      )}
    </div>
);
}

export default LoaderIcon;