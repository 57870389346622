import i18n from './../i18n';
let pathArr = [];
if(typeof window !== "undefined") {
    pathArr = window.location.pathname.split('/');
} else {
    pathArr[1] = 'ar';
}

// To switch the current url with lang
export const langSwitchUrl = (toLang, pathname, search = '', hash = '') => {
    const pathParts = pathname.split('/').filter(ele => ele !== '');
    pathParts[0] = toLang;

    const url = '/'+ pathParts.join('/') + search + hash;

    return url;
};

export const langUrl = (url) => {
    const langCode = i18n.language ? i18n.language : (pathArr[1] === 'en' ? 'en' : 'ar') 
    return '/'+ langCode +'/'+ url;
};

