import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import DOMPurify from "isomorphic-dompurify";
import { useTranslation } from "react-i18next";
require("styles/components/dashboard/dashboard-dialog.scss");

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export default function DialogBox({
  open,
  title,
  variant,
  description,
  onSubmit,
  onClose,
  onClosed,
  ...props
}) {
  const { t } = useTranslation(["translation"]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className="dialog-common"
      maxWidth={props.dialogSize !== undefined ? props.dialogSize : "sm"}
    >
      <DialogContent>
        <div className="btn-close-wr">
          {variant === "info" && (
            <IconButton aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}
        </div>
        <div className="row dialog-content" style={{maxHeight: "500px",overflowY: "auto"}}>
          <div className="col-12 ">
            {(variant === "info" || variant === "message" || variant === "declaration" || variant === "authorize") && (
              <div
                className="justi m-4"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(description),
                }}
              ></div>
            )}
            <div className="col-12 d-flex justify-content-center mt-4">
              <div className="btn-wr">
              {variant === "info" && (
                <Button variant="contained" color="primary" onClick={onSubmit}>
                  {t("OK")}
                </Button>
                )}
              {variant === "declaration" && (
                <>
                  <Button variant="contained"  className="mr-2" color="primary" onClick={onSubmit}>
                    {t("I_AGREE")}
                  </Button>
                </>
                )}
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
