import React, { useState, useEffect } from "react";
import { isMobile, isMobileOnly, isTablet } from "react-device-detect";
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { EqualHeight, EqualHeightElement } from 'react-equal-height/clean';
import { stringTrim } from 'application/utility';
import LangLink from "components/lang/LangLink";
import { useTranslation } from 'react-i18next';
import DOMPurify from 'isomorphic-dompurify';
require("styles/components/home/products.scss");
require("styles/common/colors.scss");

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//forms
import Enquiries from 'cms/components/Forms/enquires';
import Applications from 'cms/components/Forms/applications';
import globalUseStyles from 'theme/GlobalStyles';
import { elementScrollIntoView } from "seamless-scroll-polyfill";
import cookies from 'js-cookie';

import BuyLease from 'assets/images/icons/buy-and-lease-back.svg'

const Products = ({ block, full_layout, ...props }) => {
  const renderHTML = rawHTML => React.createElement("div", { dangerouslySetInnerHTML: { __html: DOMPurify.sanitize(rawHTML) } });
  const data = block?.data;
  const isMobile = isMobileOnly;
  const trimLength = 90;
  const { t } = useTranslation(['translation']);
  const currentLanguageCode = cookies.get('i18next') || 'en';

  const [expanded, setExpanded] = useState(false);
  const gClasses = globalUseStyles()
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if(isMobileOnly){
      if(isExpanded){
        setTimeout(() => {
          elementScrollIntoView(event.target, { behavior: "smooth", block: "start" });
        }, 380);
      }
    }
  };

  return (
    <>
    {  !isMobile &&
    <section className="home-prducts-list">
      <div className="container">
        {/* Only for product pages starts */}
        <div className="product-list-title">
          <h3>{props?.block?.settings?.label}</h3>
        </div>
        {/* Only for product pages ends */}
        <div className="home-prducts-list__wrapper">
            {
              data.rows.map((item, pos) => {
                return (
                  <div className='home-prducts__single' key={pos}>
                    <div className='home-prducts__logo'>
                      <img src={isMobile ? item.field_thumbnail_mobile_image[0].url :  item.field_thumbnail_image[0].url } alt={item.field_thumbnail_image[0].alt} />
                    </div>
                    <div className='home-prducts__title'>
                      <h3>{ renderHTML(item.body[0].summary,trimLength) }</h3>
                    </div>
                    <div className='disc-more'>
                      {/* <a href='#'>{t("translation:Discover More")}</a> */}
                      { currentLanguageCode == 'en' && 
                      <Button variant="contained" color="secondary" size="medium" component={LangLink} to={item.view_node['#markup']}>
                        {t("translation:Discover More")}
                      </Button>
                     }
                     { currentLanguageCode == 'ar' && 
                              <a href={item.view_node['#markup']} className="btn-secondary">{t("translation:FIND_OUT_MORE")}</a>                              
                            }
                    </div>
                  </div>
                  )
                })
            }
        </div>
        {
            full_layout?.content?.bidaya_content?.entity?.field_file?.[0]?.url && (
            <div className="col-12 col-terms text-center p-3 download-price-list">
                <p>
                  <a 
                    href={
                      full_layout?.content?.bidaya_content?.entity?.field_file?.[0]?.url
                    }
                    target="_blank"
                  >
                    {renderHTML(full_layout?.content?.bidaya_content?.entity?.field_file?.[0]?.description)}
                  </a>
                </p>
              </div>
              )
        }
      </div>  
    </section>               
    }

    {  isMobile &&
    <section className="home-prducts-list home-prducts-list__mobile-view">
      {/* <div className="container container-forms mb-4 enquiries-and-app-btn">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-12 col-forms">
            <div className="bannerCTAs mob-home-banner-cta">
              <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} elevation={0} className={gClasses.accordform} square={false}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="enquiries-content" id="enquiries-header" className={gClasses.accordLabelPrimary + ' home-green-btn'}>
                  { t("BANNER_HOME_FOR_ENQUIRIES") }
                </AccordionSummary>
                <AccordionDetails>
                  <Enquiries onChange={handleChange('panel1')}/>
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} elevation={0} className={gClasses.accordform}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="applications-content" id="applications-header" className={gClasses.accordLabelSecondary + ' home-green-btn'}>
                { t("BANNER_HOME_CHECK_APPLICATION_STATUS") }
                </AccordionSummary>
                <AccordionDetails>
                  <Applications onChange={handleChange('panel2')}/>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      */}
      <div className="container">
      <div className="product-list-title">
        <h3>{props?.block?.settings?.label}</h3>
      </div>
        <div className="home-prducts-list__wrapper">
            {
              data.rows.map((item, pos) => {
                return (
                  <div className='home-prducts__single' key={pos}>
                    <div className='home-prducts__logo'>
                      <img src={isMobile ? item.field_thumbnail_mobile_image[0].url :  item.field_thumbnail_image[0].url } alt={item.field_thumbnail_image[0].alt} />
                    </div>
                    <div className='home-prducts__title'>
                      <h3>{ renderHTML(item.body[0].summary,trimLength) }</h3>
                    </div>
                    <div className='disc-more'>
                      {/* <a href='#'>{t("translation:Discover More")}</a> */}
                      { currentLanguageCode == 'en' && 
                      <Button variant="contained" color="secondary" size="medium" component={LangLink} to={item.view_node['#markup']}>
                        {t("translation:Discover More")}
                      </Button>
                     }
                     { currentLanguageCode == 'ar' && 
                              <a href={item.view_node['#markup']} className="btn-secondary">{t("translation:FIND_OUT_MORE")}</a>                              
                            }
                    </div>
                  </div>
                  )
                })
            }
            
        </div>
        {
            full_layout?.content?.bidaya_content?.entity?.field_file?.[0]?.url && (
            <div className="col-12 col-terms text-center p-3 download-price-list">
                <p>
                  <a 
                    href={
                      full_layout?.content?.bidaya_content?.entity?.field_file?.[0]?.url
                    }
                    target="_blank"
                  >
                    {renderHTML(full_layout?.content?.bidaya_content?.entity?.field_file?.[0]?.description)}
                  </a>
                </p>
              </div>
              )
        }
      </div>  
    </section>               
    }
  </>
  );
}

export default Products;